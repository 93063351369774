import { Card, Spinner } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Legend,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Tooltip);

function LineChart({ name, data, isLoading, reference, forCompare = false }) {
  const LineChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0 // Disable animation to improve performance
    },
    plugins: {
      legend: {
        display: forCompare,
        position: 'top'
      },
      tooltip: {
        enabled: true,
        intersect: false,
        // FOR CUSTOM TOOLTIP
        callbacks: {
          title: function (tooltipItems) {
            const tooltipItem = tooltipItems[0];
            const datasetIndex = tooltipItem.datasetIndex;
            const index = tooltipItem.dataIndex;
            if(!forCompare){
              if (datasetIndex === 0) {
                return data.labels[index];
              } else {
                return data.previousLabels[index];
              }
            }
          },
          label: function (tooltipItem) {
            console.log(tooltipItem);
            const dataset = tooltipItem.dataset;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${dataset.label}: ${value}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3],
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [3, 3],
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30
        }
      }
    }
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    if (data) {
      if (forCompare && Array.isArray(data)) {
        // Get all unique labels from all sites
        const allLabels = [...new Set(data.flatMap(site => site.labels))];
        
        // Create datasets for each site
        const datasets = data.map((site, index) => {
          // Create a values array that matches the allLabels array
          const values = allLabels.map(label => {
            const labelIndex = site.labels.indexOf(label);
            return labelIndex !== -1 ? site.values.current[labelIndex] : 0;
          });

          // Predefined colors for sites
          const colors = [
            { border: 'rgba(156, 106, 222, 0.4)', background: 'rgba(156, 106, 222, 0.4)' },  // Blue
            { border: 'rgba(69, 166, 54, 0.4)', background: 'rgba(69, 166, 54, 0.4)' },  // Green
            { border: 'rgba(229, 28, 0, 0.4)', background: 'rgba(229, 28, 0, 0.4)' },   // Red
            { border: 'rgba(54, 162, 235, 0.4)', background: 'rgba(54, 162, 235, 0.4)' },  // Light Blue
            { border: 'rgba(255, 159, 64, 0.4)', background: 'rgba(255, 159, 64, 0.4)' }  // Orange
          ];

          return {
            label: `Current Period of Site ${index + 1}`,
            data: values,
            borderColor: colors[index % colors.length].border,
            backgroundColor: colors[index % colors.length].background,
            pointStyle: 'circle',
            pointRadius: 4,
            pointBorderWidth: 2,
            fill: true
          };
        });

        setChartData({
          labels: allLabels,
          datasets
        });
      } else {
        // Original single dataset logic
        setChartData({
          labels: data?.labels ?? [],
          datasets: [
            {
              label: 'Current Period',
              data: data?.values?.current,
              borderColor: '#4A3AFF',
              pointStyle: 'circle',
              pointRadius: 4,
              pointBorderWidth: 2,
              backgroundColor: 'rgba(74, 58, 255, 0.2)',
              fill: true
            },
            {
              label: 'Previous Period',
              data: data?.values?.previous,
              borderColor: '#E51C00',
              pointStyle: 'circle',
              pointRadius: 4,
              pointBorderWidth: 2,
              backgroundColor: 'rgba(229, 28, 0, 0.2)',
              fill: true,
              borderDash: [5, 5]
            }
          ]
        });
      }
    }
  }, [data, forCompare]);

  // Remove or modify the gradient effect for comparison mode
  useEffect(() => {
    if (!forCompare && reference.current) {
      const chart = reference.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, '#fff');
      gradient.addColorStop(1, '#fff');

      setChartData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: gradient,
          borderColor: '#4A3AFF',
          pointStyle: 'circle',
          pointRadius: 4,
          pointBorderWidth: 2
        }))
      }));
    }
  }, [reference, forCompare]);

  return (
    <Card>
      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <Spinner size="large" color="teal" />
        </div>
      ) : (
        <div className="">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">{name}</h3>
          </div>
          {!forCompare && (
            <div className="flex items-center gap-10 px-2 py-3">
              <div>
                <p className="text-[12px] text-[#616161] pt-1">Average Fluctuation in Events</p>
                <h3 className="text-[18px] font-semibold ">{data?.percentageChange ?? 0} % </h3>
              </div>
            </div>
          )}
          <div className="bar-chart-container mx-[10px] py-5 pb-0" style={{ height: '273px' }}>
            <Line ref={reference} data={chartData} options={LineChartoptions} />
          </div>
        </div>
      )}
    </Card>
  );
}

export default LineChart;
