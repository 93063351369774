import { api } from './index';

export const reviewEvent = async (event) => {
  const response = await api.put(`/hil-events/${event.id}`,{
    disable_event: true,
    disable_event_reason: "Disabled from Dashboard",
    discard_tags: "inaccurate",
    hil_review: true,
    hil_review_mode: "manual",
    hil_reviewed_on: new Date().getTime()
  });
  
  return response.data;
};
