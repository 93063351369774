/* eslint-disable */
import BarGraph from './BarChart';
import PieChart from '../../components/charts/Pie';
import Severity from './Severity';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import {
  generatePdf,
  getEventItemsBySeverity,
  getEventsBySeverity,
  getEventsOverviewApi,
  getFiltersApi,
  getLineChartApi,
  getOverallStatsApi
} from '../../api/graphs.api';
import {
  ActionList,
  Button,
  DatePicker,
  Icon,
  Label,
  Popover,
  Select,
  Spinner,
  Tooltip
} from '@shopify/polaris';
import LineChart from '../../components/charts/LineChart';
import { downloadReport } from '../../api/reports.api';
import { Modal, notification } from 'antd';
import { Icons } from '../../Icons';
import { format } from 'date-fns';
import { CalendarMinor, InfoMinor, NoteMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { formatDate, formatTimeFromSeconds } from '../../utils';
import { uploadBase64Image } from '../../api/images.api';
import { Select as AntSelect } from 'antd';
import { useSearchParams } from 'react-router-dom';

export const PAGE_SIZE = 6;

/** TIGHTLY COUPLED COMPONENT */
export const StatCard = ({ title, trend, trendNumber, value, inverseTrendBenefit = false, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className="bg-white border shadow-md w-full flex flex-col justify-center items-center rounded-lg pt-6 pb-3 px-6 h-[130px]">
        <Spinner accessibilityLabel="Loading chart" size="large" />
      </div>
    );
  }
  
  // Handle array of values for site comparison
  const isCompareMode = Array.isArray(value);
  
  if (title === 'PPE Compliance' || title === 'Time Since Last Event') {
    return (
      <div className="bg-white border shadow-md w-full flex flex-col justify-start rounded-lg pt-6 pb-3 px-6">
        <h6 className="font-semibold text-lg mb-2">{title}</h6>
        <div className="flex flex-col w-full gap-3 py-2">
          {isCompareMode ? (
            value.map((siteValue, index) => (
              <div key={index} className="flex items-center justify-between border-b pb-3 last:border-b-0 hover:bg-gray-50 px-2 py-1 rounded transition-colors">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full mr-2" style={{ 
                    backgroundColor: index === 0 ? 'rgba(156, 106, 222, 0.7)' : 
                                    index === 1 ? 'rgba(69, 166, 54, 0.7)' : 
                                    index === 2 ? 'rgba(229, 28, 0, 0.7)' : 
                                    index === 3 ? 'rgba(54, 162, 235, 0.7)' :
                                    'rgba(255, 159, 64, 0.7)'
                  }}></div>
                  <span className="text-sm font-medium">Site {index + 1}</span>
                </div>
                <span className="text-lg font-semibold">{siteValue}</span>
              </div>
            ))
          ) : (
            <div>
              <span className="text-3xl lg:max-2xl:text-lg">{value}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
  
  return (
    <div className="bg-white border shadow-md w-full flex flex-col justify-start rounded-lg pt-6 pb-3 px-6">
      <h6 className="font-semibold text-lg mb-2">{title}</h6>
      {isCompareMode ? (
        <div className="flex flex-col w-full gap-3 py-2">
          {value.map((siteValue, index) => (
            <div key={index} className="flex items-center justify-between border-b pb-3 last:border-b-0 hover:bg-gray-50 px-2 py-1 rounded transition-colors">
              <div className="flex items-center">
                <div className="w-3 h-3 rounded-full mr-2" style={{ 
                  backgroundColor: index === 0 ? 'rgba(156, 106, 222, 0.7)' : 
                                  index === 1 ? 'rgba(69, 166, 54, 0.7)' : 
                                  index === 2 ? 'rgba(229, 28, 0, 0.7)' : 
                                  index === 3 ? 'rgba(54, 162, 235, 0.7)' :
                                  'rgba(255, 159, 64, 0.7)'
                }}></div>
                <div>
                  <span className="text-sm font-medium">Site {index + 1}</span>
                  <span className="block text-lg font-semibold">{siteValue}</span>
                </div>
              </div>
              {Array.isArray(trend) && trend[index] === 'POSITIVE' ? (
                <div className="flex items-center justify-end gap-1 bg-gray-100 px-2 py-1 rounded">
                  <span style={{ color: !inverseTrendBenefit ? '#45A637' : '#E51C00' }} className="text-sm">
                    +{Number(Array.isArray(trendNumber) ? trendNumber[index] : 0).toFixed(2)}%
                  </span>
                  <Icons.upwardArrow
                    className="h-3"
                    fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
                  />
                </div>
              ) : Array.isArray(trend) && trend[index] === 'NEGATIVE' ? (
                <div className="flex items-center justify-end gap-1 bg-gray-100 px-2 py-1 rounded">
                  <span style={{ color: !inverseTrendBenefit ? '#E51C01' : '#45A637' }} className="text-sm">
                    -{Number(Array.isArray(trendNumber) ? trendNumber[index] : 0).toFixed(2)}%
                  </span>
                  <Icons.downwardArrow
                    className="h-3"
                    fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-between w-full gap-2 py-5">
          <div className="w-[calc(50%-3px)]">
            <span className="text-2xl lg:max-2xl:text-base">{value}</span>
            {trend === 'POSITIVE' ? (
              <div className="flex items-center justify-start gap-1">
                <span className={`text-[${!inverseTrendBenefit ? '#45A637' : '#E51C00'}] text-sm lg:max-2xl:text-xs`}>
                  +{Number(trendNumber).toFixed(2)}%
                </span>
                <Icons.upwardArrow
                  className="h-3 lg:max-2xl:h-2"
                  fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
                />
              </div>
            ) : trend === 'NEGATIVE' ? (
              <div className="flex items-center justify-start gap-1">
                <span className={`text-[${!inverseTrendBenefit ? '#E51C01' : '#45A637'}] text-sm lg:max-2xl:text-xs`}>
                  -{Number(trendNumber).toFixed(2)}%
                </span>
                <Icons.downwardArrow
                  className="h-3 lg:max-2xl:h-2"
                  fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
                />
              </div>
            ) : null}
          </div>
          {trend === 'POSITIVE' ? (
            <Icons.upwardLineChart
              className="w-[50%]"
              stroke={`${!inverseTrendBenefit ? '#04CE00' : '#FF718B'} `}
            />
          ) : trend === 'NEGATIVE' ? (
            <Icons.downwardLineChart
              className="w-[50%]"
              stroke={`${!inverseTrendBenefit ? '#FF718B' : '#04CE00'} `}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

const SiteComparison = () => {
  const chosenSite = useSelector((state) => state.auth.chosenSite);
  const sitesAvailable = useSelector((state) => state.company.sitesAvailable);
  const areas = useSelector((state) => state.auth.areas || []);

  // Log changes to track when these values update
  useEffect(() => {
    // console.log('chosenSite changed:', chosenSite);
    // console.log('sitesAvailable changed:', sitesAvailable);
    // console.log('areas changed:', areas);
  }, [chosenSite, sitesAvailable, areas]);

  const [api, contextHolder] = notification.useNotification();
  const [severityTypeFilter, setSeverityTypeFilter] = useState(null);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  // refs for building screenshots
  const barChartRef = useRef(null);
  const severityBreakdownRef = useRef(null);
  const lineGraphRef = useRef(null);


  const [searchParams, setSearchParams] = useSearchParams();

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth() - 1,
    year: new Date().getFullYear()
  });
  const [selectedDates, setSelectedDates] = useState({
    start: (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date;
    })(),
    end: new Date()
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleOk = () => {
    refetchStatsForCompare();
    refetchBarChartForCompare();
    refetchLineChartDataForCompare();
    setIsDateModalOpen(false);
  };

  const handleCancel = () => {
    setIsDateModalOpen(false);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    scenario: searchParams.get('scenario') || 'All',
    timeRange: searchParams.get('timeRange') || 'LAST_24_HOURS',
    camera: searchParams.get('camera') || 'All',
    area: searchParams.get('area')?.split(',') || ['All'],
    shift: searchParams.get('shift') || 'All'
  });

  const [timeBetweenIncidents, setTimeBetweenIncidents] = useState(formatTimeFromSeconds(0));

  const {
    data: filters,
    isLoading: areFiltersLoading,
    isError: isFilterError,
    error: filterError
  } = useQuery({
    queryKey: ['site-comparison-filters'],
    queryFn: () => getFiltersApi(),
  });

  useEffect(() => {
    if (filters) {
      setSelectedFilters( (value) => ({
        scenario: value.scenario ? value.scenario : filters.scenarios[0]?.value || 'All',
        timeRange: value.timeRange ? value.timeRange : filters.timeRange[0]?.value || 'LAST_24_HOURS',
        camera: value.camera ? value.camera : filters.cameras[0]?.value || 'All',
        area: value.area ? value.area : ['All'], // Select all areas by default
        shift: value.shift ? value.shift : 'All'
      }));
    }
  }, [filters]);

  // Add shift options using useMemo
  const shiftOptions = useMemo(() => {
    const site = sitesAvailable?.find(site => site.id === chosenSite?.id);
    if (!site?.shifts) return [{ label: 'All', value: 'All' }];
    
    try {
      const shifts = JSON.parse(site.shifts);
      return [
        { label: 'All', value: 'All' },
        ...Object.entries(shifts).map(([id, shift]) => ({
          label: shift.shiftName,
          value: id
        }))
      ];
    } catch (e) {
      return [{ label: 'All', value: 'All' }];
    }
  }, [sitesAvailable, chosenSite]);

  const [selectedSites, setSelectedSites] = useState([chosenSite?.id?.toString() || '', '', '', '', '']);

  const handleSiteSelection = (index, siteId) => {
    const newSelectedSites = [...selectedSites];
    newSelectedSites[index] = siteId;
    setSelectedSites(newSelectedSites);
  };

  const {
    data: statDataForCompare,
    refetch: refetchStatsForCompare,
    isLoading: isStatLoadingForCompare,
    isRefetching: isStatRefetchingForCompare
  } = useQuery({
    queryKey: ['site-comparison-data', selectedFilters, selectedDates, selectedSites],
    queryFn: () =>
      getOverallStatsApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area.length > 0 ? selectedFilters.area : areas.map(area => area.value),
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift,
        site_id_for_comparison: selectedSites.filter(Boolean).map(id => parseInt(id)),
        is_comparing: true
      })
  });

  useEffect(() => {
    if (statDataForCompare && statDataForCompare.length > 0) {
      // Format time between incidents for each site
      setTimeBetweenIncidents(statDataForCompare.map(item => 
        formatTimeFromSeconds(item.averageTimeBetweenEvents?.value || 0)
      ));
    }
  }, [statDataForCompare]);

const {
    data: barChartDataForCompare,
    isLoading: isBarChartLoadingForCompare,
    refetch: refetchBarChartForCompare
} = useQuery({
    queryKey: ['events-overview-for-compare', selectedFilters, selectedDates, selectedSites],
    queryFn: () =>
    getEventsOverviewApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift,
        site_id_for_comparison: selectedSites.filter(Boolean).map(id => parseInt(id)),
        is_comparing: true
    })
  });

  const {
    data: eventsBySeverityForCompare,
    isLoading: areEventsBySeverityLoadingForCompare,
    refetch: refetchEventsBySeverityForCompare
  } = useQuery({
    queryKey: ['severity', selectedFilters, selectedDates, selectedSites],
    queryFn: () =>
      getEventsBySeverity({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift,
        site_id_for_comparison: selectedSites.filter(Boolean).map(id => parseInt(id)),
        is_comparing: true
      })
  });

  const {
    data: lineChartDataForCompare,
    isLoading: isLineChartDataLoadingForCompare,
    refetch: refetchLineChartDataForCompare
  } = useQuery({
    queryKey: ['line-chart', selectedFilters, selectedDates, selectedSites],
    queryFn: () =>
      getLineChartApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift,
        site_id_for_comparison: selectedSites.filter(Boolean).map(id => parseInt(id)),
        is_comparing: true
      })
  });

  // Initialize filters from URL params
  useEffect(() => {
    const camera = searchParams.get('camera');
    const area = searchParams.get('area')?.split(',');
    const scenario = searchParams.get('scenario');
    const timeRange = searchParams.get('timeRange');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const shift = searchParams.get('shift');

    if (camera || area || scenario || timeRange || (startDate && endDate) || shift) {
      setSelectedFilters(prev => ({
        ...prev,
        camera: camera || 'All',
        area: area || ['All'],
        scenario: scenario || 'All',
        timeRange: timeRange || 'LAST_24_HOURS',
        shift: shift || 'All'
      }));
    }

    if (startDate && endDate) {
      setSelectedDates({
        start: new Date(startDate),
        end: new Date(endDate)
      });
    }
  }, []);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (selectedFilters.camera && selectedFilters.camera !== 'All') {
      params.set('camera', selectedFilters.camera);
    } else {
      params.delete('camera');
    }

    if (selectedFilters.area?.length && !selectedFilters.area.includes('All')) {
      params.set('area', selectedFilters.area.join(','));
    } else {
      params.delete('area');
    }

    if (selectedFilters.scenario && selectedFilters.scenario !== 'All') {
      params.set('scenario', selectedFilters.scenario);
    } else {
      params.delete('scenario');
    }

    if (selectedFilters.timeRange && selectedFilters.timeRange !== 'LAST_24_HOURS') {
      params.set('timeRange', selectedFilters.timeRange);
    } else {
      params.delete('timeRange');
    }

    if (selectedFilters.timeRange === 'CUSTOM_DATE_RANGE' && selectedDates?.start && selectedDates?.end) {
      params.set('startDate', selectedDates.start.toISOString());
      params.set('endDate', selectedDates.end.toISOString());
    } else {
      params.delete('startDate');
      params.delete('endDate');
    }

    if (selectedFilters.shift) {
      params.set('shift', selectedFilters.shift);
    } else {
      params.delete('shift');
    }

    setSearchParams(params);
  }, [selectedFilters, selectedDates]);

  if (isFilterError) {
    return <div>{filterError.message}</div>;
  }

  return (
    <div>
      {contextHolder}
      <Modal
        key={selectedFilters.timeRange}
        width={800}
        title="Choose Date Range"
        open={isDateModalOpen}
        footer={[
          <span className="mr-1">
            <Button variant="secondary" className="mx-2" onClick={handleCancel}>
              Cancel
            </Button>
          </span>,
          <span className="ml-1">
            <Button onClick={handleOk} variant="primary">
              Submit
            </Button>
          </span>
        ]}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
          disableDatesAfter={new Date()}
        />
      </Modal>
      <div className="mx-2 mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
        <Select
          label="Scenarios"
          disabled={areFiltersLoading}
          options={filters?.scenarios}
          value={selectedFilters.scenario}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, scenario: value });
          }}
        />
        <div className="flex items-center justify-between w-full">
          <span className="grow">
            <Select
              label="Time Period"
              disabled={areFiltersLoading}
              options={filters?.timeRange}
              value={selectedFilters.timeRange}
              onChange={(value) => {
                if (value === 'CUSTOM_DATE_RANGE') {
                  // open date picker
                  setIsDateModalOpen(true);
                }
                setSelectedFilters({ ...selectedFilters, timeRange: value });
              }}
            />
          </span>
          <span className="ml-2">
            <Tooltip dismissOnMouseOut content="Select Custom Date Range">
              <Button
                variant="primary"
                onClick={() => {
                  setIsDateModalOpen(true);
                  setSelectedFilters({ ...selectedFilters, timeRange: 'CUSTOM_DATE_RANGE' });
                }}
              >
                <Icon source={CalendarMinor} />
              </Button>
            </Tooltip>
          </span>
        </div>
        {/* <Select
          label="Cameras"
          disabled={areFiltersLoading}
          options={filters?.cameras}
          value={selectedFilters.camera}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, camera: value });
          }}
        />
        <Select
          label="Shifts"
          options={shiftOptions}
          value={selectedFilters.shift}
          onChange={(value) => {
            setSelectedFilters(prev => ({ ...prev, shift: value }));
          }}
        />
        <div>
          <Label>Areas</Label>
          <AntSelect
            mode="multiple"
            // remove next line if there is any render issue happening.
            maxTagCount={"responsive"}
            className="ant-select-polaris w-full h-[36px]"
            placeholder="Select areas"
            disabled={areFiltersLoading}
            options={filters?.areas}
            value={selectedFilters.area}
            onChange={(values) => {
              const lastSelected = values[values.length - 1];
              
              if (lastSelected === 'All') {
                // If 'All' was just selected
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: ['All']
                });
              } else if (values.includes('All')) {
                // If 'All' is already selected and we're selecting something else
                const filteredValues = values.filter(v => v !== 'All');
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: filteredValues
                });
              } else if (values.length === 0) {
                // If deselecting everything, default to 'All'
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: ['All']
                });
              } else {
                // Normal selection of specific areas
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: values 
                });
              }
            }}
          />
        </div> */}

        {/* Add this CSS to match Polaris styling */}
        <style>
          {`
            .ant-select-polaris .ant-select-selector {
              border-radius: 4px !important;
              border: 1px solid #8c9196 !important;
              height: 36px !important;
              align-items: center !important;
            }
            
            .ant-select-polaris:hover .ant-select-selector {
              border-color: #2c6ecb !important;
            }

            .ant-select-polaris.ant-select-focused .ant-select-selector {
              border-color: #2c6ecb !important;
              box-shadow: 0 0 0 1px #2c6ecb !important;
            }
            .Polaris-Button {
              max-height: 20px !important;
              margin-top: 1.5rem;
            }
            .pdf_button .Polaris-Button {
              width: 100% !important;
            }
          `}
        </style>
      </div>
      <div className="m-2 p-4 pt-0 bg-gray-50 border rounded-md">
        <div className="flex items-center justify-between mb-1">
          <h2 className="text-lg font-semibold">Sites for Comparison</h2>
          <Tooltip content="You can add up to a maximum of 5 sites to compare data across multiple locations.">
            <Button plain>
              <Icon source={InfoMinor} color="base" />
            </Button>
          </Tooltip>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
          {[0, 1, 2, 3, 4].map((index) => (
            <div key={index}>
              <div className="flex items-center mb-2">
                <div className="w-3 h-3 rounded-full mr-2" style={{ 
                  backgroundColor: index === 0 ? 'rgba(156, 106, 222, 0.7)' : 
                                  index === 1 ? 'rgba(69, 166, 54, 0.7)' : 
                                  index === 2 ? 'rgba(229, 28, 0, 0.7)' : 
                                  index === 3 ? 'rgba(54, 162, 235, 0.7)' :
                                  'rgba(255, 159, 64, 0.7)' 
                }}></div>
                <Label>Site {index + 1}</Label>
              </div>
              <Select
                options={sitesAvailable
                  .filter(site => {
                    // Include the site if it's currently selected in this dropdown
                    // or if it's not selected in any other dropdown
                    return !selectedSites.includes(site.id.toString()) || 
                           selectedSites[index] === site.id.toString();
                  })
                  .map(site => ({ label: site.name, value: site.id.toString() }))}
                value={selectedSites[index] || ''}
                onChange={(value) => handleSiteSelection(index, value)}
                placeholder={`Select site ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <div className="text-xs text-gray-500 mt-2">
          Select at least 2 sites to enable comparison {selectedSites.filter(Boolean).length}/5 sites selected
        </div>
      </div>
      <div className="m-2">
        {selectedFilters.timeRange === 'CUSTOM_DATE_RANGE' && (
          <p className="text-sm mb-2">
            {`Selected Date Range: ${format(new Date(selectedDates.start), 'dd MMMM, yyyy')} to ${format(new Date(selectedDates.end), 'dd MMMM, yyyy')}`}
          </p>
        )}
        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-4`}
        >
          <StatCard
            title={'Time Between Events'}
            trend={statDataForCompare?.map(item => item.averageTimeBetweenEvents?.trend) ?? ['POSITIVE']}
            value={timeBetweenIncidents}
            trendNumber={statDataForCompare?.map(item => item.averageTimeBetweenEvents?.trendValue) ?? [0]}
            isLoading={isStatRefetchingForCompare || isStatLoadingForCompare}
          />
          <StatCard
            title="Total Events"
            trend={statDataForCompare?.map(item => item.totalIncidents?.trend) ?? ['POSITIVE']}
            value={statDataForCompare?.map(item => item.totalIncidents?.value) ?? [0]}
            trendNumber={statDataForCompare?.map(item => item.totalIncidents?.trendValue) ?? [0]}
            inverseTrendBenefit={true}
            isLoading={isStatRefetchingForCompare || isStatLoadingForCompare}
          />
          <StatCard
            title="Reviewed Events"
            trend={statDataForCompare?.map(item => item.reviewedIncidents?.trend) ?? ['POSITIVE']}
            value={statDataForCompare?.map(item => 
              `${item.reviewedIncidents?.value ?? 0} (${(item.reviewedIncidentsPercentage?.value ?? 0).toFixed(2)}%)`
            ) ?? ['0 (0%)']}
            trendNumber={statDataForCompare?.map(item => item.reviewedIncidents?.trendValue) ?? [0]}
            isLoading={isStatRefetchingForCompare || isStatLoadingForCompare}
          />
        </div>
        <BarGraph reference={barChartRef} isLoading={isBarChartLoadingForCompare} data={barChartDataForCompare} timeRange={selectedFilters.timeRange} startDate={selectedDates.start} endDate={selectedDates.end} forCompare={true} hasClickHandler={false} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
          <div>
            <Severity
              reference={severityBreakdownRef}
              title={selectedFilters.scenario}
              isLoading={areEventsBySeverityLoadingForCompare}
              data={eventsBySeverityForCompare}
              severityTypeFilter={severityTypeFilter}
              setSeverityTypeFilter={setSeverityTypeFilter}
            />
          </div>
          <div>
            <LineChart
              reference={lineGraphRef}
              data={lineChartDataForCompare}
              isLoading={isLineChartDataLoadingForCompare}
              name={selectedFilters.scenario}
              forCompare={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteComparison;
