import { Card } from '@shopify/polaris';
import BarChart from './SeverityChart';

const Severity = ({
  isLoading,
  data,
  severityTypeFilter,
  setSeverityTypeFilter,
  reference
}) => {
  return (
    <div>
      <Card>
      <div className="col-span-1 md:col-span-1 xl:col-span-4">
        <div className="">
          <BarChart
            reference={reference}
            isLoading={isLoading}
            data={data}
            severityTypeFilter={severityTypeFilter}
            setSeverityTypeFilter={setSeverityTypeFilter}
            forCompare={true}
          />
        </div>
      </div>
      </Card>
    </div>
  );
};

export default Severity;
