/* eslint-disable */
import React, { useEffect, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  DatePicker,
  Label,
  TextField,
  Popover,
  Spinner,
  Listbox,
  Combobox
} from '@shopify/polaris';
import { Link, useNavigate } from 'react-router-dom';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllUsers } from '../../api/users.api';
import { createCampaign } from '../../api/campaigns.api';
import { notification } from 'antd';

const AddCampaign = () => {
  const { data: users = [], isLoading } = useQuery({
    queryKey: ['campaign-users'],
    queryFn: async () => {
      const users = await getAllUsers();
      const options = users.map((user) => ({ label: user.name, value: user.id.toString() }));
      return options;
    }
  });

  const { mutate: createCampaignApi, isPending: isCreatingCampaign } = useMutation({
    mutationKey: ['create-campaign'],
    mutationFn: createCampaign,
    onSuccess: () => {
      api.success({ message: 'Campaign created successfully! Redirecting to Campaigns page...' });
      setCampaignName('');
      setDescription('');
      setOwnerValue('');
      setTimeout(() => {
        navigateTo('/campaigns');
      }, 1500);
    },
    onError: () => {
      return api.error({ message: 'Something went wrong! Please contact support@visionify.ai' });
    }
  });

  const navigateTo = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  // Separate state variables for start and end dates
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  const [startDateInputValue, setStartDateInputValue] = useState('');
  const [endDateInputValue, setEndDateInputValue] = useState('');

  const [popoverActive, setPopoverActive] = useState(false);
  const [popoverActive2, setPopoverActive2] = useState(false);

  const [campaignName, setCampaignName] = useState('');
  const [description, setDescription] = useState('');

  const handleMonthChange = useCallback((month, year) => {
    setMonth(month);
    setYear(year);
  }, []);

  const togglePopoverActive = () => setPopoverActive((popoverActive) => !popoverActive);

  const handleStartDateChange = (date) => {
    setStartDate(date.start);
    const formattedDate = `${date.start.toLocaleDateString()}`;
    setStartDateInputValue(formattedDate);
    togglePopoverActive(); // Close the calendar after selecting the date
  };

  const togglePopoverActive2 = () => setPopoverActive2((popoverActive2) => !popoverActive2);

  const handleEndDateChange = (date) => {
    setEndDate(date.start);
    const formattedDate = `${date.start.toLocaleDateString()}`;
    setEndDateInputValue(formattedDate);
    togglePopoverActive2(); // Close the calendar after selecting the date
  };

  const [selectedOption, setSelectedOption] = useState();
  const [ownerValue, setOwnerValue] = useState('');
  const [options, setOptions] = useState(users ?? []);

  useEffect(() => {
    setOptions([...users]);
  }, [users]);

  const escapeSpecialRegExCharacters = useCallback(
    (value) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    []
  );

  const updateText = useCallback(
    (value) => {
      setOwnerValue(value);

      if (value === '') {
        setOptions(users);
        return;
      }

      const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), 'i');
      const resultOptions = users.filter((option) => option.label.match(filterRegex));
      setOptions(resultOptions);
    },
    [users, escapeSpecialRegExCharacters]
  );

  const updateSelection = useCallback(
    (selected) => {
      const matchedOption = options.find((option) => {
        return option.value.match(selected);
      });

      setSelectedOption(selected);
      setOwnerValue((matchedOption && matchedOption.label) || '');
    },
    [options]
  );

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOption === value}
              accessibilityLabel={label}>
              {label}
            </Listbox.Option>
          );
        })
      : null;

  if (isLoading) {
    return (
      <div className="h-[calc(100vh-100px)] w-full grid place-items-center">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      <div className="radiousnone main-title">
        {contextHolder}
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title smallbtn flex items-center gap-3">
            <Link to="/campaigns">
              <MobileBackArrowMajor className="w-6" />
            </Link>
            <h3 className="text-black text-lg font-semibold">Add Campaign</h3>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <Box background="bg-surface-secondary" borderRadius="10" border="base" padding="4">
          <div className="max-w-5xl mx-auto py-10">
            <Card padding="0">
              <div className="px-5">
                <div className="basicdetail height-43 space-y-5 pt-5 pb-5">
                  <div className="block sm:flex items-center gap-5 w-full">
                    <div className="w-[200px] pb-1">
                      <Label>Campaign Name</Label>
                    </div>
                    <div className="w-full relative">
                      <TextField
                        type="text"
                        labelHidden
                        placeholder="Enter Campaign Name"
                        onChange={(value) => {
                          if (value.length > 100) return;
                          setCampaignName(value);
                        }}
                        autoComplete="off"
                        value={campaignName}
                      />
                    </div>
                  </div>

                  <div className="block sm:flex items-center gap-5 w-full">
                    <div className="w-[200px] pb-1">
                      <Label>Owner</Label>
                    </div>
                    <div className="w-full relative">
                      <Combobox
                        activator={
                          <Combobox.TextField
                            onChange={updateText}
                            labelHidden
                            value={ownerValue}
                            placeholder="Choose Owner"
                            autoComplete="off"
                          />
                        }>
                        {options.length > 0 ? (
                          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
                        ) : null}
                      </Combobox>
                    </div>
                  </div>
                  <div className="block sm:flex items-center gap-5 w/full">
                    <div className="w-[200px] pb-1">
                      <Label>Start Date</Label>
                    </div>
                    <div className="w-full relative">
                      <div>
                        <span
                          onClick={togglePopoverActive}
                          style={{
                            display: 'block',
                            padding: '8px',
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            backgroundColor: '#f9fafb'
                          }}>
                          <Label>
                            {startDate ? startDate.toLocaleDateString() : 'Select Start Date'}
                          </Label>
                        </span>
                        <Popover
                          active={popoverActive}
                          activator={<div />}
                          onClose={togglePopoverActive}
                          preferInputActivator={false}>
                          <div className="py-3 px-3">
                            <DatePicker
                              month={month}
                              year={year}
                              onChange={handleStartDateChange}
                              onMonthChange={handleMonthChange}
                              disableDatesBefore={new Date(new Date().setDate(new Date().getDate() - 1))}
                              selected={{ start: startDate, end: startDate }}
                            />
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <div className="block sm:flex items-center gap-5 w/full">
                    <div className="w-[200px] pb-1">
                      <Label>End Date</Label>
                    </div>
                    <div className="w-full relative">
                      <div>
                      <span
                          onClick={togglePopoverActive2}
                          style={{
                            display: 'block',
                            padding: '8px',
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            backgroundColor: '#f9fafb'
                          }}>
                          <Label>
                            {endDate ? endDate.toLocaleDateString() : 'Select Start Date'}
                          </Label>
                        </span>
                        <Popover
                          active={popoverActive2}
                          activator={<div />}
                          onClose={togglePopoverActive2}
                          preferInputActivator={false}>
                          <div className="py-3 px-3">
                            <DatePicker
                              month={month}
                              year={year}
                              disableDatesBefore={startDate}
                              onChange={handleEndDateChange}
                              onMonthChange={handleMonthChange}
                              selected={{ start: endDate, end: endDate }}
                            />
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </div>
                  <div className="block sm:flex items-center gap-5 w-full">
                    <div className="w-[200px] pb-1">
                      <Label>Description</Label>
                    </div>
                    <div className="w-full relative">
                      <TextField
                        type="text"
                        labelHidden
                        multiline={4}
                        placeholder="Enter Description"
                        onChange={(value) => {
                          if (value.length > 800) return;
                          setDescription(value);
                        }}
                        autoComplete="off"
                        value={description}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full border-t pt-5 flex justify-end gap-4 pb-5 border-b">
                  <Button onClick={() => navigateTo('/campaigns')} size="large">
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      if (!campaignName) {
                        return api.warning({ message: 'Campaign Name cannot be empty.' });
                      }
                      if (!selectedOption) {
                        return api.warning({
                          message: 'Please choose an owner for this campaign!'
                        });
                      }

                      if (!startDate || !endDate) {
                        return api.warning({
                          message: 'Start Date and End Date are mandatory for campaigns.'
                        });
                      }

                      if (startDate > endDate) {
                        return api.warning({ message: 'End Date cannot be before start date' });
                      }

                      const payload = {
                        campaign_name: campaignName,
                        campaign_desc: description,
                        owner: parseInt(selectedOption),
                        status: 'UPCOMING',
                        start_date: startDate,
                        end_date: endDate
                      };
                      createCampaignApi({ ...payload });
                    }}
                    size="large"
                    disabled={isCreatingCampaign}
                    loading={isCreatingCampaign}
                    variant="primary">
                    Save
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Box>
      </div>
    </>
  );
};

export default AddCampaign;
