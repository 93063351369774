import { Button, Card, Layout, LegacyCard, Spinner } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteAttachment, getCameraViewById, getCameraViewAttachments } from '../../api/views.api';
import backArrow from '../../assets/back-arrow.svg';
import { EditMajor, PlayMajor, FileMinor, DeleteMajor } from '@shopify/polaris-icons';
import EventCard from '../Events/EventCard';
import { formatTimeFromSeconds } from '../../utils';
import { Icons } from '../../Icons';
import { PlayView } from './PlayView';
import { useFullScreenHandle } from 'react-full-screen';
import FileUploadModal from './FileUploadModal';
import ReactPlayer from 'react-player';
import { notification } from 'antd';

export const StatCard = ({ title, trend, trendNumber, value, inverseTrendBenefit = false }) => {
  const isValueNA = value === null || value === undefined;
  const isTrendNA = trendNumber === null || trendNumber === undefined;

  if (title === 'Time Since Last Incident') {
    return (
      <div className="bg-white border shadow-md flex flex-col justify-between  rounded-lg py-4 pl-4 pr-8">
        <h6 className="font-semibold">{title}</h6>
        <div className="flex items-center justify-between">
          <div>
            <span className="text-3xl">{isValueNA ? 'N/A' : value}</span>
            <div className="min-h-[23px]"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border shadow-md flex flex-col justify-between rounded-lg pt-4 pl-4 pr-8">
      <h6 className="font-semibold">{title}</h6>
      <div className="flex items-center justify-between">
        <div>
          <span className="text-3xl">{isValueNA ? 'N/A' : value}</span>

          {!isValueNA && !isTrendNA && trend === 'POSITIVE' && (
            <div className="flex">
              <span className={`text-[${!inverseTrendBenefit ? '#45A637' : '#E51C00'}] mr-2`}>
                +{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.upwardArrow
                className="h-4"
                fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
              />
            </div>
          )}

          {!isValueNA && !isTrendNA && trend === 'NEGATIVE' && (
            <div className="flex">
              <span className={`text-[${!inverseTrendBenefit ? '#E51C01' : '#45A637'}] mr-2`}>
                -{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.downwardArrow
                className="h-4"
                fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
              />
            </div>
          )}
        </div>

        {!isValueNA && !isTrendNA && trend === 'POSITIVE' && (
          <div>
            <Icons.upwardLineChart
              className="w-32 h-32"
              stroke={`${!inverseTrendBenefit ? '#04CE00' : '#FF718B'}`}
            />
          </div>
        )}

        {!isValueNA && !isTrendNA && trend === 'NEGATIVE' && (
          <div>
            <Icons.downwardLineChart
              className="w-32 h-32"
              stroke={`${!inverseTrendBenefit ? '#FF718B' : '#04CE00'}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export const AttachmentCard = ({ attachment, onDelete }) => {
  const handleImageClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div className="cursor-pointer hover:opacity-75">
      <Card>
        <div className="relative">
          <div className="absolute top-2 right-2 z-10">
            <Button
              onClick={() => onDelete(attachment.id)}
              plain
              icon={DeleteMajor}
              tone="critical"
              variant="primary"
            />
          </div>
          <div className="flex justify-center items-center">
            {attachment.attachment_type === 'image' ? (
              <img
                src={attachment.azure_link}
                alt={attachment.attachment_name}
                className="w-[400px] h-[200px] object-contain"
                onClick={() => handleImageClick(attachment.azure_link)}
              />
            ) : attachment.attachment_type === 'video' ? (
              <ReactPlayer url={attachment.azure_link} controls width="400px" height="200px" />
            ) : (
              <a
                href={attachment.azure_link}
                target="_blank"
                rel="noopener noreferrer"
                className="w-[300px] h-[200px] flex items-center justify-center bg-gray-200">
                <p>{attachment.attachment_name}</p>
              </a>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between py-4 px-4">
          <div>
            <p className="text-md font-semibold">{attachment.attachment_name}</p>
          </div>
          {attachment.duration && (
            <p className="text-sm text-gray-500">Duration: {attachment.duration}s</p>
          )}
        </div>
      </Card>
    </div>
  );
};

const ViewStats = () => {
  const { cameraViewId } = useParams();
  const navigate = useNavigate();
  const [timeSince, setTimeSince] = useState('0d 0h 0m 0s');
  const [showPlayView, setShowPlayView] = useState(false);
  const fullScreenHandle = useFullScreenHandle();
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const {
    data: cameraViewData,
    isLoading: isCameraViewLoading,
    isError: isCameraViewError
  } = useQuery({
    queryKey: ['cameraView', cameraViewId],
    queryFn: () => getCameraViewById(cameraViewId)
  });

  const {
    data: cameraViewAttachments,
    isLoading: isAttachmentsLoading,
    isError: isAttachmentsError,
    refetch: refetchAttachments
  } = useQuery({
    queryKey: ['cameraViewAttachments', cameraViewId],
    queryFn: () => getCameraViewAttachments(cameraViewId)
  });

  const { mutate: deleteAttachmentMutation } = useMutation({
    mutationFn: ({ id }) => {
      return deleteAttachment(cameraViewId, id);
    },
    onSuccess: () => {
      api.success({
        message: 'Attachment deleted successfully.',
        placement: 'topRight'
      });
      refetchAttachments();
    },
    onError: () => {
      api.error({
        message: 'Error deleting attachment.',
        placement: 'topRight'
      });
    }
  });

  const handleDeleteAttachment = (id) => {
    deleteAttachmentMutation({ id });
  };

  const statData = cameraViewData?.metrics;
  const events = cameraViewData?.event_details || [];

  const formatTimeSince = (dateString) => {
    if (!dateString) {
      return '0d 0h 0m 0s';
    }
    const now = new Date();
    const date = new Date(dateString);
    const diff = now - date;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / 1000 / 60) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    // Set interval to update the time every second
    const interval = setInterval(() => {
      if (cameraViewData?.metrics?.timeSinceLastIncident?.value) {
        setTimeSince(formatTimeSince(cameraViewData.metrics.timeSinceLastIncident.value));
      }
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [cameraViewData]);

  const handlePlayClick = () => {
    setShowPlayView(true);
    // fullScreenHandle.enter();
  };

  if (isCameraViewLoading || isAttachmentsLoading) {
    return (
      <div className="h-[calc(100vh-100px)] w-full grid place-items-center">
        <Spinner accessibilityLabel="Loading" size="large" />
      </div>
    );
  }

  if (isCameraViewError || isAttachmentsError) {
    return <div>Error loading events</div>;
  }

  return (
    <>
      {contextHolder}
      {showPlayView ? (
        <PlayView
          cameraViewData={cameraViewData}
          cameraViewAttachments={cameraViewAttachments}
          setShowPlayView={setShowPlayView}
          showPlayView={showPlayView}
          fullScreenHandle={fullScreenHandle}
        />
      ) : (
        <div className="radiousnone main-title">
          <Card roundedAbove="xs">
            <div className="title flex items-center justify-between smallbtn">
              <div className="flex items-center gap-3">
                <button onClick={() => navigate('/views')}>
                  <img src={backArrow} alt="Back" />
                </button>
                <div className="title">
                  <h5 className="text-lg font-semibold">Views</h5>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}

      {!showPlayView && (
        <div className="cameralist">
          <div className="px-4 py-4">
            <Layout>
              <Layout.Section>
                <LegacyCard>
                  <div className="flex items-center justify-between flex-wrap w-full gap-5 py-2 px-4">
                    <h4 className="font-semibold text-base">
                      {cameraViewData.name} (Last 30 Days)
                    </h4>
                    <div className="flex items-center">
                      <span className="pr-2">
                        <Button
                          variant="primary"
                          size="large"
                          icon={PlayMajor}
                          onClick={handlePlayClick}>
                          Play
                        </Button>
                      </span>
                      <span className="pr-2">
                        <Button
                          variant="primary"
                          size="large"
                          icon={EditMajor}
                          onClick={() => navigate(`/views/edit/${cameraViewId}`)}>
                          Edit View
                        </Button>
                      </span>
                      <Button
                        variant="primary"
                        size="large"
                        icon={FileMinor}
                        onClick={() => setIsFileUploadModalOpen(true)}>
                        Upload Files
                      </Button>
                      <FileUploadModal
                        cameraViewId={cameraViewId}
                        isOpen={isFileUploadModalOpen}
                        onClose={() => setIsFileUploadModalOpen(false)}
                        onUploadSuccess={refetchAttachments}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className="w-full p-4">
                    <h4 className="font-semibold text-base pb-3">Metrics</h4>
                    <Card>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 px-4 py-4">
                        <StatCard
                          title={'Time Since Last Incident'}
                          trend={'NEUTRAL'}
                          trendNumber="0"
                          value={timeSince}
                        />
                        <StatCard
                          title={'Time Between Incidents'}
                          trend={statData?.averageTimeBetweenEvents?.trend ?? 'POSITIVE'}
                          value={formatTimeFromSeconds(statData?.averageTimeBetweenEvents.value)}
                          trendNumber={statData?.averageTimeBetweenEvents?.trendValue ?? 0}
                        />
                        <StatCard
                          title="Total Incidents"
                          trend={statData?.totalIncidents?.trend ?? 'POSITIVE'}
                          value={statData?.totalIncidents?.value ?? 0}
                          trendNumber={statData?.totalIncidents?.trendValue ?? 0}
                          inverseTrendBenefit={true}
                        />
                        <StatCard
                          title="Reviewed Incidents"
                          trend={statData?.reviewedIncidents?.trend ?? 'POSITIVE'}
                          value={
                            `${statData?.reviewedIncidents?.value ?? 0} (${(statData?.reviewedIncidentsPercentage?.value ?? 0).toFixed(2)}%)` ??
                            '0 (0%)'
                          }
                          trendNumber={statData?.reviewedIncidents?.trendValue ?? 0}
                        />
                      </div>
                    </Card>
                  </div>
                  <div className="px-4 py-4">
                    <h4 className="font-semibold text-base pb-3">Events</h4>
                    {events.length > 0 ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 cameracard">
                        {events.map((event, i) => (
                          <div key={i}>
                            <EventCard key={event.id} event={event} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center h-full">
                        <p className="text-lg">No events found</p>
                      </div>
                    )}
                  </div>
                  {/* Display attachments */}
                  <div className="px-4 py-4">
                    <h4 className="font-semibold text-base pb-3">Attachments</h4>
                    {cameraViewAttachments && cameraViewAttachments.length > 0 ? (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 cameracard">
                        {cameraViewAttachments.map((attachment, index) => (
                          <div key={index}>
                            <AttachmentCard
                              attachment={attachment}
                              onDelete={handleDeleteAttachment}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center h-full">
                        <p className="text-lg">No attachments found</p>
                      </div>
                    )}
                  </div>
                </LegacyCard>
              </Layout.Section>
            </Layout>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewStats;
