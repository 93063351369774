import { Button } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { Image, Layer, Stage, Text } from 'react-konva';
import useImage from 'use-image';
import { getCameraIconDataUrl } from '../utils';
import { Dropdown } from 'antd';

export default function KonvaDraggableCanvas({ icons, setIcons, backgroundImageUrl, cameras }) {
  const [image] = useImage(backgroundImageUrl);
  const [size] = useState({ width: 850, height: 500 });
  const [cameraIcon] = useImage(getCameraIconDataUrl());
  const [history, setHistory] = useState([]);

  // const [showCameraDropdown, setShowCameraDropdown] = useState(false);
  const [filteredCameras, setFilteredCameras] = useState([]);

  useEffect(() => {
    // Filter out cameras that have already been added as icons
    const updatedCameras = cameras.filter(
      (camera) => !icons.some((icon) => icon.name === camera.label)
    );
    setFilteredCameras(updatedCameras);
  }, [icons, cameras]);

  // const addCamera = () => {
  //   setShowCameraDropdown(true);
  // };

  const addIcon = (camera) => {
    console.log('camera', camera);
    const newIcons = [
      ...icons,
      {
        x: Math.random() * size.width,
        y: Math.random() * size.height,
        name: camera.label,
        id: JSON.parse(camera.value).id
      }
    ];
    setHistory([...history, icons]);
    setIcons(newIcons);
    // setSelected(
    //   JSON.stringify({
    //     id: -1,
    //     name: 'NONE'
    //   })
    // );
    // setShowCameraDropdown(false);
  };

  const undo = () => {
    if (history.length === 0) return;
    const previousState = history[history.length - 1];
    setHistory(history.slice(0, -1));
    setIcons(previousState);
  };

  const reset = () => {
    setHistory([]);
    setIcons([]);
  };

  // const [selected, setSelected] = useState(
  //   JSON.stringify({
  //     id: -1,
  //     name: 'NONE'
  //   })
  // );

  const handleSelectChange = ({ key }) => {
    // setSelected(key);
    addIcon(JSON.parse(key));
  };

  const options = [
    ...filteredCameras.map((camera) => ({
      label: camera.label,
      key: JSON.stringify(camera)
    }))
  ];

  const checkBounds = (x, y, width, height) => {
    const minX = 0;
    const maxX = size.width - width;
    const minY = 0;
    const maxY = size.height - height;

    const boundedX = Math.max(minX, Math.min(x, maxX));
    const boundedY = Math.max(minY, Math.min(y, maxY));

    return { x: boundedX, y: boundedY };
  };

  return (
    <div className="">
      <div className="flex w-full justify-between items-center mb-2">
        <div className="flex items-center">
          <span className="text-lg font-semibold mr-4">Site Map</span>
          {/* {showCameraDropdown && (
            <span>
              <Select
                label="Cameras"
                labelHidden
                options={options}
                onChange={handleSelectChange}
                value={selected}
              />
            </span>
          )} */}
        </div>
        <div className="flex items-center">
          <span className="mr-2">
          <Dropdown menu={{ items: options, onClick: handleSelectChange }} trigger={['click']} disabled={options.length === 0}>
            <Button variant="primary" onClick={(e) => e.preventDefault()} disabled={options.length === 0}>Add Camera</Button>
          </Dropdown>
          </span>
          <span className="mr-2">
            <Button onClick={undo} tone="critical" variant="secondary">
              Undo
            </Button>
          </span>
          <span className="mr-2">
            <Button onClick={reset} tone="critical" variant="primary">
              Reset
            </Button>
          </span>
        </div>
      </div>
      <div className="flex justify-center w-full">
        <Stage width={size.width} height={size.height}>
          <Layer>
            <Image image={image} height={size.height} width={size.width} />
            {icons.map((icon, i) => (
              <>
                <Image
                  key={i}
                  x={icon.x}
                  y={icon.y}
                  width={35}
                  height={35}
                  image={cameraIcon}
                  draggable
                  dragBoundFunc={(pos) => {
                    const { x, y } = checkBounds(
                      pos.x,
                      pos.y,
                      35, // width of the icon
                      35 // height of the icon
                    );
                    return { x, y };
                  }}
                  onDragEnd={(e) => {
                    const { x, y } = checkBounds(
                      e.target.x(),
                      e.target.y(),
                      e.target.width(),
                      e.target.height()
                    );
                    const newIcons = icons.slice();
                    newIcons[i] = {
                      ...newIcons[i],
                      x: x,
                      y: y
                    };
                    setHistory([...history, icons]);
                    setIcons(newIcons);
                  }}
                  onDragMove={(e) => {
                    const { x, y } = checkBounds(
                      e.target.x(),
                      e.target.y(),
                      e.target.width(),
                      e.target.height()
                    );
                    const newIcons = icons.slice();
                    newIcons[i] = {
                      ...newIcons[i],
                      x: x,
                      y: y
                    };
                    setIcons(newIcons);
                  }}
                />
                <Text
                  x={icon.x + 17.5 - (icon.name.length * 7) / 2} // Adjust to center based on icon width
                  y={icon.y + 40} // Position the text below the image
                  text={icon.name}
                  fontSize={14}
                  fill="black"
                  align="center"
                  draggable={false} // Set draggable to false so only the icon moves
                />
              </>
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}
