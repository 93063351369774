import { Button, Popover, ActionList } from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import logoWhite from '../assets/logowhite.svg';
import logoutIco from '../assets/logout.svg';
import mdiUserIco from '../assets/mdi_user.svg';
import companyIco from '../assets/company.svg';
import { useSelector } from 'react-redux';
// import { logout } from '../store/slices/authSlice';
import { SquareAvatar } from './Avatar';
import '../styles/Animations.css';
import { resetStoreAndPersistor } from '../store/store';
import NotificationIcon from './Notifications';


const Header = (props) => {
  const { toggleSidebar, isOpen, isAnimating } = props;
  const [active, setActive] = useState(false);
  // const dispatch = useDispatch();
  const { user: userObj } = useSelector((state) => state.auth);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <div className="cus_drop w-[130px]">
      <Button onClick={toggleActive} variant="primary" fullWidth disclosure>
        <div className="flex items-center justify-between w-[110px]">
          {userObj?.user.name ?? 'Anonymous'}
          <SquareAvatar name={userObj?.user.name ?? 'Anonymous'} />
        </div>
      </Button>
    </div>
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const handleLogout = () => {
    // dispatch(logout());
    resetStoreAndPersistor();
  };

  return (
    <div
      className={`z-[999] header radiousnone top-0 w-full ${isAnimating ? (windowWidth < 640 ? (isOpen ? 'content-open pl-0' : 'content-closed pl-20') : isOpen === true ? 'content-open pl-64' : 'content-closed pl-20') : windowWidth < 640 ? (isOpen ? 'content-open pl-0' : 'content-closed pl-20') : isOpen === true ? 'pl-64' : 'pl-20'}`}
    >
      <div className="bg-white py-3 px-3 shadow-md border-b">
        <div className="header-container flex items-center justify-between">
          <div className="flex gap-5">
            <button
              type="button"
              onClick={toggleSidebar}
              className="inline-flex items-center text-sm text-black rounded-lg focus:outline-none"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                ></path>
              </svg>
            </button>
            <img src={logoWhite} alt="logo" className="block sm:hidden" />
          </div>
          <div className="flex items-center gap-5 cus_drop">
            <NotificationIcon />
            <Popover
              fullWidth="true"
              active={active}
              activator={activator}
              autofocusTarget="first-node"
              onClose={toggleActive}
            >
              <div className="z-[1001]">
                <ActionList
                  actionRole="menuitem"
                  items={[
                    { content: 'Settings', url: '/settings', image: mdiUserIco },
                    { content: 'Company', url: '/company-settings', image: companyIco },
                    { content: 'Logout', onAction: handleLogout, image: logoutIco }
                  ]}
                />
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
