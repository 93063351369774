const TIMEZONES = [
  // United Kingdom
  'Europe/London',

  // India
  'Asia/Kolkata',

  // Turkey
  'Europe/Istanbul',

  // Southeast Asia
  'Asia/Bangkok',        // Thailand
  'Asia/Kuala_Lumpur',   // Malaysia
  'Asia/Kuching',        // Malaysia
  'Asia/Manila',         // Philippines
  'Asia/Singapore',      // Singapore

  // Caribbean
  'America/Puerto_Rico',
  'America/Port_of_Spain',
  'America/Aruba',
  'America/Curacao',
  'America/Barbados',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Honolulu',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Moscow',
  'Europe/Lisbon',
  'America/Sao_Paulo',
  'Asia/Dubai',
  'Asia/Tokyo',
  'Asia/Shanghai',
  'Asia/Singapore',
  'Australia/Sydney',
  'Pacific/Auckland',
  'America/Manaus',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Rio_Branco',
  'Asia/Manila'
];

const COUNTRY_PHONE_CODES = [
  '+44',
  '+91',
  '+90',
  '+66',
  '+60',
  '+1',
  '+1',
  '+297',
  '+599',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+1',
  '+33',
  '+49',
  '+7',
  '+351',
  '+55',
  '+971',
  '+81',
  '+86',
  '+65',
  '+61',
  '+64',
  '+55',
  '+64',
  '+55',
  '+55',
  '+55',
  '+55',
  '+63'
];

// Create a lookup map for better performance
const COUNTRY_CODE_TIMEZONE_MAP = new Map(
  TIMEZONES.map((timezone, index) => [COUNTRY_PHONE_CODES[index], timezone])
);

export const getTimezonesForSelect = () => 
  TIMEZONES.map(timezone => ({
    label: timezone.replace(/_/g, ' '),
    value: timezone
  }));

export const getCountryPhoneCodeMap = () => 
  Object.fromEntries(TIMEZONES.map((timezone, index) => [timezone, COUNTRY_PHONE_CODES[index]]));

export function getCountryCodeAndTimezone(phoneNumber, selectedCountryCode, selectedTimezone) {
  // Default return unchanged
  if (!phoneNumber) return {
    countryCode: '+91',
    timezone: 'Asia/Kolkata',
    localNumber: ''
  };

  // Find the country code using more efficient method
  const countryCode = COUNTRY_PHONE_CODES.find(code => phoneNumber.startsWith(code)) || '';
  const localNumber = countryCode ? phoneNumber.substring(countryCode.length) : '';
  
  // Get timezone directly from map instead of looping
  const timezone = selectedTimezone && TIMEZONES.includes(selectedTimezone) ? 
    selectedTimezone : 
    COUNTRY_CODE_TIMEZONE_MAP.get(countryCode) || '';

  return { countryCode, timezone, localNumber };
}