import '../styles/ScenarioCard.css';
import { MediaCard, Tooltip } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccessControl from '../hooks/useAccessControl';
import { ACCESS } from '../data/userRoles';

const ScenarioCard = ({ scenario, navigateToSingleCamFlow }) => {
  const navigateTo = useNavigate();

  // Access Control for Scenario Card
  const { hasAccess: hasApplyScenarioAccess, isLoading: isApplyScenarioLoading } = useAccessControl(ACCESS.APPLY_SCENARIO);

  const allowedScenarios = useSelector((state) => state.company.subscribedScenarios);

  const { search } = useLocation();

  return (
    <div className="card-dimensions">
      <MediaCard
        portrait
        title={scenario.title}
        primaryAction={{
          disabled:!(allowedScenarios.includes(scenario.name)) || (!isApplyScenarioLoading && !hasApplyScenarioAccess),
          accessibilityLabel: 'Get This',
          content: 'Get This',
          onAction: () => {
            const searchObject = Object.fromEntries(new URLSearchParams(search));
            if (Object.keys(searchObject).length > 0) {
              return navigateToSingleCamFlow();
            }
            return navigateTo(`/cameras?scenario=${scenario.name}`);
          }
        }}
        secondaryAction={{
          content: 'Learn More',
          onAction: () => {
            navigateTo(`/scenarios/${scenario.name}`);
          },
          plain: true
        }}
        description={<Tooltip content={scenario.tagline} width="wide">
          <span className="card-description">{scenario.tagline}</span>
        </Tooltip>}
        >
        <div className="card-holder">
          <img className="card-image" alt="" src={scenario.icon} />
        </div>
      </MediaCard>
    </div>
  );
};

export default ScenarioCard;
