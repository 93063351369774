/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import backArrow from '../assets/back-arrow.svg';
import Calendar from '../assets/calendar.svg';
import { resetEventSlice, setChosenDate, setChosenDateEvents, setChosenDateIdx, setChosenEventIdx } from '../store/slices/eventSlice';
import { useCallback, useEffect, useState } from 'react';
import { reviewEvent } from '../api/hil.api';
import Spacer from './Spacer';
import {
  Button,
  Card,
  EmptyState,
  Icon,
  Layout,
  LegacyCard,
  Listbox,
  Pagination,
  Select,
  Spinner,
  Text,
  TextField
} from '@shopify/polaris';
import ReactPlayer from 'react-player';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addTagToEvent,
  getEventById,
  removeTagFromEvent,
  updateEventById
} from '../api/events.api';
import { dateToWordsTz, translateEvent } from '../utils';
import { PlusCircle } from 'lucide-react';
import { createTag, getTags } from '../api/tags.api';
import ComboBoxWithMultipleOptions from './ComboBoxWithMultipleActions';
import { Modal, notification } from 'antd';
import { ThumbsDownMajor, ThumbsUpMajor } from '@shopify/polaris-icons';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MultiSwitch from './MultiSwitch';
import { addEventToCampaign, getAllCampaigns } from '../api/campaigns.api';
import { getAppliedScenarioByAppliedScenarioId } from '../api/scenarios.api';
import useAccessControl from '../hooks/useAccessControl';
import { ACCESS } from '../data/userRoles';

const eventSeverity = {
  critical: 'bg-red-900',
  high: 'bg-red-600',
  warning: 'bg-orange-500',
  moderate: 'bg-yellow-400',
  low: 'bg-green-400',
  info: 'bg-blue-400'
};

const SeverityBadge = ({ severity }) => {
  severity = severity.toLowerCase();
  const severityClass = eventSeverity[severity] || 'bg-gray-400'; // Default to gray if severity is not recognized
  return (
    <span
      className={`inline-flex items-center rounded-md ${severityClass} px-2 py-1 text-sm text-white`}
    >
      {severity.toLowerCase().charAt(0).toUpperCase() + severity.slice(1)}
    </span>
  );
};

const Header = ({
  eventId,
  refetch,
  disableNavigation,
  navigateBack,
  review,
  isReviewSaved,
  handleSave,
  pages,
  currentPage,
  setCurrentPage,
  fromViewMore
}) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();




  const { events, chosenDateEvents } = useSelector((state) => state.event);
  const [showDidYouSaveModal, setShowDidYouSaveModal] = useState(false);
  const [goToEventOrder, setGoToEventOrder] = useState(''); // ['', 'PREV', 'NEXT']
  const [searchParams, setSearchParams] = useSearchParams();
  const [chosenEventIndex, setChosenEventIndex] = useState(null);



  const handleOk = () => {
    handleSave();
    setShowDidYouSaveModal(false);
    if (goToEventOrder === 'PREV') return goToPrevEvent();
    if (goToEventOrder === 'NEXT') return goToNextEvent();
  };

  const handleCancel = () => {
    setShowDidYouSaveModal(false);
  };

  const hasReviewBeenSaved = (eventOrder) => {
    if (review === '') return true;
    if (isReviewSaved) return true;
    setShowDidYouSaveModal(true);
    setGoToEventOrder(eventOrder);
    return false;
  };

  const clearEventIdx = useCallback(() => {
    if (navigateBack) return navigateTo('/events');
    if (!fromViewMore) {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDate(null));
      dispatch(setChosenDateEvents([]));
      // dispatch(resetEventSlice());
    }
    dispatch(setChosenEventIdx(null));
    if (refetch) refetch();
    return;



  }, []);

  const hasNext = () => {
    const eventIdx = chosenDateEvents.findIndex((event) => event.id === eventId);
    if (eventIdx === -1) return false;
    if (eventIdx === chosenDateEvents.length - 1) {
      if (currentPage >= pages) return false;
    }
    return true;
  };


  const hasPrev = () => {
    const eventIdx = chosenDateEvents.findIndex((event) => event.id === eventId);
    if (eventIdx === -1) return false;
    if (eventIdx === 0) {
      if (currentPage === 1) return false;
    }
    return true;

  };

  const onPrevious = () => {
    if (!hasReviewBeenSaved('PREV')) return;
    return goToPrevEvent();
  };

  const onNext = () => {
    if (!hasReviewBeenSaved('NEXT')) return;
    return goToNextEvent();
  };

  const goToPrevEvent = async () => {
    const params = new URLSearchParams(searchParams);

    const eventIdx = chosenDateEvents.findIndex((event) => event.id === eventId);
    if (eventIdx === -1) return;

    if (eventIdx === 0) {
      if (currentPage === 1) return;
      const newPage = currentPage > 1 ? currentPage - 1 : currentPage;
      setCurrentPage(newPage);
      params.set('page', newPage.toString());
      setSearchParams(params);

      setChosenEventIndex(chosenDateEvents.length - 1);
    } else {
      // const nextEvent = chosenDateEvents[eventIdx + 1];
      setChosenEventIndex(eventIdx - 1);
    }
    setGoToEventOrder('');
  };



  const goToNextEvent = async () => {
    const params = new URLSearchParams(searchParams);

    const eventIdx = chosenDateEvents.findIndex((event) => event.id === eventId);
    if (eventIdx === -1) return;

    if (eventIdx === chosenDateEvents.length - 1) {
      if (currentPage >= pages) return;
      const newPage = currentPage < pages ? currentPage + 1 : currentPage;
      setCurrentPage(newPage);
      params.set('page', newPage.toString());
      setSearchParams(params);


      setChosenEventIndex(0);
    } else {
      // const nextEvent = chosenDateEvents[eventIdx + 1];
      setChosenEventIndex(eventIdx + 1);
    }
    setGoToEventOrder('');
  };

  useEffect(() => {
    if (chosenEventIndex !== null && chosenDateEvents && chosenDateEvents.length > 0) {
      dispatch(setChosenEventIdx(chosenDateEvents[chosenEventIndex].id));
    }
  }, [chosenEventIndex, chosenDateEvents]);
  return (
    <div className="bg-white rounded-b-md p-4 shadow-md">


      <div className="title flex items-center justify-between">
        <div className="flex items-center gap-3">
          <button onClick={clearEventIdx}>
            <img src={backArrow} alt="" />
          </button>
          <h5 className="text-lg font-semibold">Event Details-({eventId})</h5>
        </div>
        {chosenDateEvents && Array.isArray(chosenDateEvents) && chosenDateEvents.length > 1 && !disableNavigation && (
          <div>
            <Pagination
              hasNext={hasNext()}
              hasPrevious={hasPrev()}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          </div>
        )}
      </div>
      <Modal
        title="Are you sure?"
        open={showDidYouSaveModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button variant="secondary" onClick={handleCancel}>
              No
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk} disabled={false}>
              <div className="flex items-center">
                Yes
                {false && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        Do you want to save the review before navigating to the next event?
      </Modal>
    </div>
  );
};

const EventDetails = ({ refetch, showOne = false, pages, currentPage, setCurrentPage, mutate, fromViewMore }) => {
  const [api, contextHolder] = notification.useNotification();
  const { chosenEventIdx: id } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);



  // Access Control for Event Validation and Download Video
  const { hasAccess: hasEventValidationAccess, isLoading: isEventValidationLoading } = useAccessControl(ACCESS.EVENT_VALIDATION);
  const { hasAccess: hasDownloadVideoAccess, isLoading: isDownloadVideoLoading } = useAccessControl(ACCESS.DOWNLOAD_VIDEO);


  const { eventId } = useParams();
  const location = useLocation();

  // Get query params
  const queryParams = new URLSearchParams(location.search);
  const isCampaignType = queryParams.get('type') === 'campaign';

  const { data, isLoading, isError } = useQuery({
    queryKey: ['event', id, eventId],
    queryFn: () => {
      if (parseInt(eventId)) return getEventById(eventId);
      else return getEventById(id);
    }
  });

  const { data: appliedScenarioEventMetadata, isLoading: isAppliedScenarioLoading } = useQuery({
    queryKey: ['applied-scenario', data?.applied_scenarios_id],
    queryFn: () => getAppliedScenarioByAppliedScenarioId(data?.applied_scenarios_id),
    select: (data) => {
      // Create a single object combining all relevant metadata
      return data.data.zones.reduce((acc, zone) => {
        const eventName = zone.event_name.toLowerCase();

        // Get relevant metadata for this zone
        Object.entries(zone.metadata || {}).forEach(([key, value]) => {
          if (key.toLowerCase().includes(eventName)) {
            acc[key] = value;
          }
        });

        return acc;
      }, {});
    },
    enabled: !!data?.applied_scenarios_id
  });

  // useEffect(() => {
  //   if (appliedScenarioEventMetadata) {
  //     console.log('appliedScenarioEventMetadata', appliedScenarioEventMetadata);
  //   }
  // }, [appliedScenarioEventMetadata]);

  // get event details
  // const {
  //   data: eventDetails,
  //   isLoading: areEventDetailsLoading,
  //   isError: didEventDetailsError
  // } = useQuery({
  //   queryKey: ['event-details', id, eventId],
  //   queryFn: () => {
  //     if (parseInt(eventId)) return getEventDetailsById(eventId);
  //     else return getEventDetailsById(id);
  //   }
  // });

  const { data: campaigns, isLoading: areCampaignsLoading } = useQuery({
    queryKey: ['campaigns-list-for-events'],
    queryFn: async () => {
      const response = await getAllCampaigns();
      return [
        { value: '0', label: 'Please Choose a campaign' },
        ...response.data.map((campaign) => ({
          label: campaign.campaign_name,
          value: campaign.id.toString()
        }))
      ];
    }
  });

  const { mutate: addEventToCampaignApi } = useMutation({
    mutationFn: addEventToCampaign,
    onSuccess: () => {
      api.success({ message: 'Event added to campaign!' });
    },
    onError: () => {
      api.error({ message: 'Event already added to campaign!' });
    },
    onSettled: () => {
      handleSelectChange('0');
    }
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      if (data.review) {
        setReview(data.review);
      } else {
        setReview('');
      }
      if (!data.is_reviewed) {
        setIsEventAccurate(null);
      } else {
        setIsEventAccurate(data.is_accurate);
      }
      if (data.tags) {
        const eventTags = JSON.parse(data.tags);
        if (eventTags.length > 0) {
          setSelectedLists(
            eventTags.map((tag, idx) => ({ id: idx, name: tag.title, value: tag.name }))
          );
        } else {
          setSelectedLists([]);
        }
      } else {
        setSelectedLists([]);
      }
    }
  }, [data, isLoading, isError]);

  const [review, setReview] = useState('');
  const [isReviewSaved, setIsReviewSaved] = useState(true);
  const [isEventAccurate, setIsEventAccurate] = useState(null); // [true, false, null] -> [accurate, inaccurate, not reviewed]
  const [selectedLists, setSelectedLists] = useState([]);

  const [selected, setSelected] = useState('0');

  const handleSelectChange = (value) => {
    setSelected(value);
    if (value != '0') {
      addEventToCampaignApi({
        id: parseInt(value),
        payload: {
          event_id: parseInt(eventId) ? eventId : id,
          video_url: data?.inference_video_path,
          area: data?.area,
          camera_id: data?.camera_id,
          severity: data?.severity
        }
      });
    }
  };

  const handleReviewChange = useCallback((newValue) => {
    setReview(newValue);
    setIsReviewSaved(false);
  }, []);

  const [open, setOpen] = useState(false);

  const [newTag, setNewTag] = useState('');
  const [tagCreationError, setTagCreationError] = useState(false);

  const { mutate: createTagInit, isPending: isCreatingTag } = useMutation({
    mutationFn: createTag,
    onSuccess: () => {
      setOpen(false);
      setNewTag('');
      refetchTags();
    },
    onError: (e) => {
      console.log({ e });
      setTagCreationError(true);
    }
  });

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setTagCreationError(false);
    createTagInit({ name: newTag });
  };

  const handleCancel = () => {
    setOpen(false);
    setNewTag('');
    setTagCreationError(false);
  };

  const {
    data: tags,
    isLoading: areTagsLoading,
    isError: didTagsNotLoad,
    refetch: refetchTags
  } = useQuery({
    queryKey: ['tags', id, eventId],
    queryFn: getTags
  });

  const { mutate: addTagForEvent } = useMutation({
    mutationFn: async (tag) => {
      if (parseInt(eventId)) return await addTagToEvent(eventId, tag);
      return await addTagToEvent(id, tag);
    }
  });

  const { mutate: removeTagForEvent } = useMutation({
    mutationFn: async (tag) => {
      if (parseInt(eventId)) return await removeTagFromEvent(eventId, tag);
      return await removeTagFromEvent(id, tag);
    }
  });

  const { mutate: updateEventWithId, isLoading: isEventUpdating } = useMutation({
    mutationFn: ({ id, payload }) => updateEventById(id, payload),
    onSuccess: (data) => {
      setIsReviewSaved(true);
      if (data.message) {
        return api.success({
          message: data.message,
          placement: 'bottomRight'
        });
      }
      return api.success({
        message: 'Event updated successfully',
        placement: 'bottomRight'
      });
    },
    onError: () => {
      api.error({
        message: 'Failed to update event',
        placement: 'bottomRight'
      });
    }
  });

  const handleSave = async () => {
    if (review === '') {
      return api.error({
        message: 'Please add a review before saving.',
        placement: 'topRight'
      });
    }
    const payload = {
      review
    };
    const mutatePayload = {
      id: parseInt(eventId) ? eventId : id,
      payload
    };
    updateEventWithId(mutatePayload);
  };

  const markEventAsAccurate = async (isAccurate) => {
    if (isAccurate === null) {
      return api.error({
        message: 'Please select a value before saving.',
        placement: 'topRight'
      });
    }
    const payload = {
      is_reviewed: true,
      is_accurate: isAccurate
    };

    const mutatePayload = {
      id: parseInt(eventId) ? eventId : id,
      payload
    };
    updateEventWithId(mutatePayload);
  };

  const markEventSeverity = async (severity) => {
    const payload = {
      severity: severity
    };
    updateEventWithId({
      id: parseInt(eventId) ? eventId : id,
      payload
    });
  };

  return (
    <>
      {contextHolder}
      <Header
        eventId={id}
        refetch={refetch}
        disableNavigation={showOne ? true : false}
        navigateBack={eventId ? true : false}
        review={review}
        isReviewSaved={isReviewSaved}
        handleSave={handleSave}
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        mutate={mutate}
        fromViewMore={fromViewMore}
      />


      {isLoading || areCampaignsLoading ? (
        <div className="h-full w-full grid place-items-center">
          <Spinner size="large" />
        </div>
      ) : isError ? (
        <div className="flex flex-col items-center justify-center w-full gap-4">
          <img
            src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
            className="empty-state w-[250px]"
          />
          <h2 className="text-xl font-bold">Event Not Found</h2>
          <p>
            We couldn&apos;t find the event you&apos;re looking for. Please check the event ID or
            try a different search.
          </p>
        </div>
      ) : (
        <div className="px-4 py-4">
          <div className="">
            <div className="grid grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 gap-5 pt-4">
              <div className="col-span-1 xl:col-span-2">
                <Card>
                  {/* {data?.only_image_event ? ( 
                  NOTE: this is a temporary fix to show the image thumbnail for events that are only image events
                  */}
                  {data?.inference_video_path === null || data?.inference_video_path === '' ? (
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      src={data?.inference_image_path || ''}
                      alt="Event Thumbnail"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/placeholder-image.jpg'; // Fallback image
                      }}
                      loading="lazy"
                    />
                  ) : (
                    <div onContextMenu={(e) => hasDownloadVideoAccess && !isDownloadVideoLoading ? null : e.preventDefault()}>
                      <ReactPlayer
                        url={data?.inference_video_path}
                        controls
                        playing
                        light={<img src={data?.inference_image_path} alt="Thumbnail" />}
                        width="100%"
                        height="100%"
                        config={{
                          file: {
                            attributes: {
                              controlsList: 'nodownload'
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="flex items-start justify-between py-4 flex-wrap">
                    <div>
                      <Text as="p" variant="headingLg" fontWeight="semibold">
                        {data?.camera_name}
                        <Spacer height={'10px'} />
                      </Text>
                      <div className="flex items-center gap-3">
                        <img src={Calendar} alt=""
                          onDoubleClick={async () => {

                            // if (user.role_id === 2 || user.role_id === 7 || user.role_id === 8) {
                            let response = await reviewEvent(data);
                            if (response.success) {
                              api.success({
                                message: 'Event reviewed successfully',
                                placement: 'bottomRight'
                              });
                            }
                            // }
                          }}
                        />
                        <p>{dateToWordsTz(data?.event_time, user.timezone)}</p>
                      </div>
                      <Spacer height={'10px'} />
                    </div>
                    {(!isEventValidationLoading && !hasEventValidationAccess) ? null : (
                      <div className="">
                        <h4 className="text-md font-semibold">Is this Event Accurate?</h4>
                        <div className="flex items-center gap-4 justify-center mt-2">
                          <span
                            onClick={() => {
                              setIsEventAccurate(true);
                              markEventAsAccurate(true);
                            }}
                            className={`border rounded-md p-1 shadow-md cursor-pointer text-black hover:bg-green-100  transition-transform
duration-100 ease-in-out transform active:rotate-45 active:opacity-75  ${isEventAccurate === null ? '' : isEventAccurate === true ? 'bg-green-300' : ''}`}
                          >
                            <Icon source={ThumbsUpMajor} />
                          </span>
                          <span
                            onClick={() => {
                              setIsEventAccurate(false);
                              markEventAsAccurate(false);
                            }}
                            className={`border rounded-md p-1 shadow-md cursor-pointer text-black hover:bg-red-100 transition-transform 
duration-100 ease-in-out transform active:-rotate-45 active:opacity-75  ${isEventAccurate === null ? '' : isEventAccurate === false ? 'bg-red-300' : ''}`}
                          >
                            <Icon source={ThumbsDownMajor} />
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="space-y-2">
                      <div className="flex items-center gap-2 text-[14px]">
                        <p>Scenario:</p>
                        <span className="inline-flex items-center rounded-md bg-[#DBF2FF] px-2 py-1 text-sm text-[#003A5A]">
                          {data &&
                            data?.scenario_name
                              .split('-')
                              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                              .join(' ')}
                        </span>
                      </div>
                      <div className="flex items-center gap-2 text-[14px]">
                        <p>Severity:</p>

                        {(!isEventValidationLoading && !hasEventValidationAccess) ? (
                          data?.severity && <SeverityBadge severity={data?.severity} />
                        ) : (
                          <MultiSwitch
                            onSelect={markEventSeverity}
                            selectedOption={
                              data?.severity.toLowerCase() === 'low'
                                ? 0
                                : data?.severity.toLowerCase() === 'moderate'
                                  ? 1
                                  : 2
                            }
                            options={['Low', 'Moderate', 'High']}
                            bgColors={['bg-blue-300', 'bg-yellow-300', 'bg-red-300']}
                            textColors={['text-blue-800', 'text-yellow-800', 'text-red-800']}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className="text-[22px] font-semibold">Events Detected</h3>
                  <div className="flex items-center flex-wrap gap-3 pt-3">
                    {data.event_names.map((event, idx) => {
                      const eventName = event.toLowerCase();
                      const metadataValue = Object.entries(appliedScenarioEventMetadata || {})
                        .find(([key]) => key.toLowerCase().includes(eventName))?.[1].value;

                      return (
                        <span
                          key={idx}
                          className="inline-flex items-center rounded-md bg-[#8670C5] px-2 py-1 text-sm text-white"
                        >
                          {translateEvent(event.toLowerCase())
                            .split(' ')
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ')}
                          {metadataValue && ` (${metadataValue})`}
                        </span>
                      );
                    })}
                  </div>
                  {data.applied_events && data.applied_events.length > 0 && (
                    <>
                      <h3 className="text-[22px] font-semibold mt-2">Applied Events</h3>
                      <div className="flex items-center flex-wrap gap-3 pt-3">
                        {data.applied_events
                          .filter((e) => e !== data.scenario_name)
                          .map((event, idx) => {
                            const eventName = event.toLowerCase();
                            const metadataValue = Object.entries(appliedScenarioEventMetadata || {})
                              .find(([key]) => key.toLowerCase().includes(eventName))?.[1]?.value;

                            return (
                              <span
                                key={idx}
                                className="inline-flex items-center rounded-md bg-[#8670C5] px-2 py-1 text-sm text-white"
                              >
                                {translateEvent(event.toLowerCase()) 
                                  .split(' ')
                                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                  .join(' ')}
                                {metadataValue && ` (${metadataValue})`}
                              </span>
                            );
                          })}
                      </div>
                    </>
                  )}
                </Card>
              </div>
              <Layout>
                <Layout.Section>
                  <LegacyCard title="Add your review" sectioned>
                    <hr />
                    <div className="py-4">
                      <TextField
                        value={review}
                        onChange={handleReviewChange}
                        multiline={4}
                        autoComplete="off"
                        placeholder="Add your review here..."
                      />
                      <Spacer height={'10px'} />
                      {tags && !areTagsLoading && !didTagsNotLoad && (
                        <ComboBoxWithMultipleOptions
                          label="Add to List"
                          placeholder="Select List"
                          optionsList={tags}
                          selectedOptions={selectedLists}
                          setSelectedOptions={(newOptions) => {
                            setSelectedLists(newOptions);
                          }}
                          noOptionsConfig={{
                            element: (
                              <Listbox.Option value={'ADD_NEW_LIST'}>
                                <p className="px-4 py-2 cursor-pointer">
                                  <span className="flex items-center">
                                    <PlusCircle size={18} />{' '}
                                    <span className="ml-2 font-bold">Add New List</span>
                                  </span>
                                </p>
                              </Listbox.Option>
                            ),
                            command: 'ADD_NEW_LIST',
                            fn: showModal
                          }}
                          addAction={(selected) => {
                            addTagForEvent(selected.name);
                          }}
                          removeAction={(selected) => {
                            removeTagForEvent(selected.name);
                          }}
                        />
                      )}
                      {!isCampaignType && (
                        <div>
                          <Select
                            label="Add to Campaign"
                            options={campaigns}
                            onChange={handleSelectChange}
                            value={selected}
                          />{' '}
                        </div>
                      )}
                      <div className="flex justify-end items-center gap-3 mt-5">
                        <Button
                          disabled={(!isEventUpdating && !hasEventValidationAccess)}
                          variant="primary"
                          onClick={handleSave}
                        >
                          Save{' '}
                          {isEventUpdating && (
                            <span>
                              <Spinner size="small" />
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </LegacyCard>
                </Layout.Section>
              </Layout>
            </div>
          </div>
          <Modal
            title="Create New List"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: true }}
            footer={[
              <div className="inline mr-2" key="back">
                <Button variant="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>,
              <div className="inline mr-2" key="submit">
                <Button variant="primary" disabled={isCreatingTag} onClick={handleOk}>
                  <div className="flex items-center">
                    <span>Submit</span>{' '}
                    {isCreatingTag && (
                      <span className="ml-2">
                        <Spinner size="small" color="white" />
                      </span>
                    )}
                  </div>
                </Button>
              </div>
            ]}
          >
            <form>
              <TextField
                label="New List"
                value={newTag}
                onChange={(newValue) => setNewTag(newValue)}
              />
              {tagCreationError && (
                <p className="text-red-500 mt-1 text-xs">
                  * Unable to create a new list! Please reach out to{' '}
                  <a href="mailto:support@visionify.ai">support@visionify.ai</a>
                </p>
              )}
            </form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default EventDetails;
