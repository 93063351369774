import { Card, Spinner } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, Title, Legend } from 'chart.js';
import { Icons } from '../../Icons';
import { reverseTranslateEvent, translateEvent } from '../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal } from '@shopify/polaris';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

function BarChart({ isLoading, data, hideEvents, reference, hasClickHandler = false, dateRange, forCompare = false }) {
  const navigate = useNavigate();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [newParams, setNewParams] = useState('');
  const [searchParams] = useSearchParams();

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: forCompare ? [] : [
      {
        label: '',
        data: [],
        hoverOffset: 2,
        maxBarThickness: 40,
        borderRadius: 8
      }
    ]
  });

  useEffect(() => {
    if (data) {
      if (forCompare && Array.isArray(data)) {
        // Get all unique labels from all sites
        const allLabels = [...new Set(data.flatMap(site => site.data.labels))];
        
        // Create datasets for each site
        const datasets = data.map((site, index) => {
          // Create a values array that matches the allLabels array
          const values = allLabels.map(label => {
            const labelIndex = site.data.labels.indexOf(label);
            return labelIndex !== -1 ? site.data.values[labelIndex] : 0;
          });

          // Predefined colors for sites
          const colors = [
            'rgba(156, 106, 222, 0.4)',  // Purple
            'rgba(69, 166, 54, 0.4)',    // Green
            'rgba(229, 28, 0, 0.4)',     // Red
            'rgba(54, 162, 235, 0.4)',    // Blue
            'rgba(255, 159, 64, 0.4)'    // Orange
          ];

          return {
            label: `Site ${index + 1}`,
            data: values,
            hoverOffset: 2,
            maxBarThickness: 40,
            borderRadius: 8,
            backgroundColor: colors[index % colors.length]
          };
        });

        setChartData({
          labels: allLabels.map(label => translateEvent(label.toLowerCase())),
          datasets
        });
      } else {
        // Original single dataset logic
        setChartData((prevData) => ({
          ...prevData,
          labels: data?.data?.labels?.map((label) => translateEvent(label.toLowerCase())) ?? [],
          datasets: [
            {
              ...prevData.datasets[0],
              data: data?.data?.values ?? []
            }
          ]
        }));
      }
    }
  }, [data, forCompare]);

  useEffect(() => {
    if (!forCompare && reference.current) {
      const chart = reference.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(225, 202, 255, 1)');
      gradient.addColorStop(1, 'rgba(156, 106, 222, 1)');

      setChartData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: gradient
        }))
      }));
    }
  }, [reference.current, data, forCompare]);

  const barchartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: forCompare,
        position: 'top'
      },
      tooltip: {
        enabled: true,
        intersect: false
      }
    },
    onClick: (event, active) => {
      if (active.length === 0 || !hasClickHandler) return

      const { dataIndex } = active[0].element.$context
      const barLabel = event.chart.data.labels[dataIndex]

      
      const params = new URLSearchParams(searchParams);
      const reverseTranslatedLabel = reverseTranslateEvent(barLabel.toLowerCase());
    
      params.set('scenarios', reverseTranslatedLabel.includes('no') ? reverseTranslatedLabel.charAt(0).toUpperCase() + reverseTranslatedLabel.slice(1) : reverseTranslatedLabel);
      params.set('startDate', dateRange.start);
      params.set('endDate', dateRange.end);
      setNewParams(params.toString());
      setIsConfirmModalOpen(true);
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3],
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [3, 3],
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30
        }
      }
    }
  };

  return (
    <Card>
      {isLoading || !data ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div>
          {!hideEvents && (
            <>
              <Modal
                open={isConfirmModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
                title="Confirm Navigation"
                primaryAction={{
                  content: 'Go to Events',
                  onAction: () => {
                    navigate({
                      pathname: '/events',
                      search: newParams
                    });
                  }
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: () => setIsConfirmModalOpen(false)
                  }
                ]}
              >
                <Modal.Section>
                  <p>Are you sure you want to navigate to the events page?</p>
                </Modal.Section>
              </Modal>
              <div className="flex items-center justify-between">
                <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">Events Overview</h3>
              </div>
              {
                !forCompare && (
                  <>
                    <div className="flex items-center gap-10 px-2 py-3">
                      <div>
                        <h3 className="text-[18px] font-semibold">{data?.totalEvents?.value ?? 0}</h3>
                        <p className="text-[12px] text-[#616161] pt-1">Total Events</p>
                      </div>
                      <div>
                        {data && data?.totalEvents?.trend === 'POSITIVE' ? (
                          <h3 className="text-[18px] font-semibold flex gap-4">
                            {Number(data?.totalEvents?.trendValue).toFixed(2) ?? 0}%{' '}
                            <Icons.upwardArrow className="h-4" fill="#E51C00" />
                          </h3>
                        ) : (
                          <h3 className="text-[18px] font-semibold flex gap-4">
                            {Number(data?.totalEvents?.trendValue).toFixed(2) ?? 0}%{' '}
                            <Icons.downwardArrow className="h-4" fill="#45A636" />
                          </h3>
                        )}
                        <p className="text-[12px] text-[#616161] pt-1">Average Trend</p>
                      </div>
                    </div>
                  </>
                )
              }
            </>
          )}
          <div className="bar-chart-container mx-[10px] py-5 pb-0" style={{ height: '225px' }}>
            <Bar ref={reference} data={chartData} options={barchartoptions} />
          </div>
        </div>
      )}
    </Card>
  );
}

export default BarChart;
