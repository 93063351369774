import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  chosenSite: {
    name: null,
    id: null
  },
  isSiteLoading: false,
  toReload: false,
  areas: []
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthObject: (state, action) => {
      state.user = action.payload.user;
      state.chosenSite = action.payload.site;
      state.areas = action.payload.areas;
    },
    setSite: (state, action) => {
      state.chosenSite = action.payload;
    },
    setSiteLoader: (state, action) => {
      state.isSiteLoading = action.payload;
    },
    setToReload: (state, action) => {
      state.toReload = action.payload;
    },
    logout: (state) => {
      state.user = null
      state.chosenSite = {...initialState.chosenSite}
      state.isSiteLoading = initialState.isSiteLoading
      state.toReload = initialState.isSiteLoading
      state.areas = []
    }
  }
});

export const { setUser, setSite, logout, setSiteLoader, setToReload, setAuthObject, setAreas } = authSlice.actions;

export default authSlice.reducer;
