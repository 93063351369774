import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePickerPopover from '../../components/DatePicker';
import { Autocomplete, Button, Select, Tag } from '@shopify/polaris';
import { FilterMajor } from '@shopify/polaris-icons';
import { useQuery } from '@tanstack/react-query';
import { fetchEventDropdownData} from '../../api/events.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCamera,
  setSelectedDates,
  setSelectedReviewType,
  setSelectedScenarioEvents,
  setTag,
  setSelectedAreas,
  setShift
} from '../../store/slices/eventSlice';
import { fetchAreas } from '../../api/users.api';
import { store } from '../../store/store';
import { translateEvent } from '../../utils';

const AutoComplete = ({ availableOptions }) => {
  const deselectedOptions = useMemo(() => [...availableOptions], []);
  const [options] = useState(deselectedOptions);

  const dispatch = useDispatch();
  const { selectedScenarioEvents } = useSelector((state) => state.event);

  const [isAllSelected, setIsAllSelected] = useState(true);

  const updateSelection = useCallback( 
    (selected) => {
      // let selectedValue;

      // eslint-disable-next-line no-unused-vars
      // options.forEach(({ title, options }) => {
      //   if (selectedValue) {
      //     return;
      //   }

      //   const matchedOption = options.find((option) => option.value.match(selected[0]));

      //   if (matchedOption) {
      //     selectedValue = matchedOption.label;
      //     dispatch(setSelectedScenarios([...new Set([...selectedScenarios, selectedValue])]));
      //   }
      // });

      if (isAllSelected) {
        if (selected.includes('All')) {
          setIsAllSelected(false);
          const filteredSelection = selected.filter((e) => e !== 'All');
          // If filtering All leaves us with empty selection, keep All selected
          if (filteredSelection.length === 0) {
            setIsAllSelected(true);
            return dispatch(setSelectedScenarioEvents(['All']));
          }
          return dispatch(setSelectedScenarioEvents(filteredSelection));
        } else {
          setIsAllSelected(false);
          // If deselecting everything, default to All
          if (selected.length === 0) {
            setIsAllSelected(true);
            return dispatch(setSelectedScenarioEvents(['All']));
          }
          return dispatch(setSelectedScenarioEvents(selected));
        }
      } else {
        if (selected.includes('All')) {
          setIsAllSelected(true);
          return dispatch(setSelectedScenarioEvents(['All']));
        } else {
          // If deselecting everything, default to All
          if (selected.length === 0) {
            setIsAllSelected(true);
            return dispatch(setSelectedScenarioEvents(['All']));
          }
          return dispatch(setSelectedScenarioEvents(selected));
        }
      }
    },
    [options, isAllSelected]
  );

  const textField = <Autocomplete.TextField placeholder="Select Event Types" autoComplete="off" />;

  return (
    <div>
      <Autocomplete
        textField={textField}
        allowMultiple
        selected={selectedScenarioEvents}
        options={options}
        onSelect={updateSelection}
      />
      <div className="overflow-x-auto whitespace-nowrap my-2 h-10">
        {selectedScenarioEvents &&
          selectedScenarioEvents.length > 0 &&
          selectedScenarioEvents.map((selected) => {
            return (
              <span key={selected} className="inline-block mx-1 my-2">
                <Tag>
                  {translateEvent((selected)
                    .replace(/_/g, ' ').toLowerCase())
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </Tag>
              </span>
            );
          })}
      </div>
      <style>
        {`
      .overflow-x-auto::-webkit-scrollbar {
        display: none;
      }
    `}
      </style>
    </div>
  );
};

const getAreaOptions = () => {
  const { auth } = store.getState();
  return auth.user?.user?.areas.split(',').map(area => ({
    label: area.trim(),
    value: area.trim()
  })) || [];
};

const AreaFilter = () => {
  const { data: areas = [] } = useQuery({
    queryKey: ['areas'],
    queryFn: fetchAreas,
    select: (data) =>{ 
    const assignedOptions = getAreaOptions();

    if (assignedOptions.find(option => option.label === 'All')) {
      return data.data.map(area => ({
        label: area.name,
        value: area.name
      }))
    }

    return [{label: 'All', value: 'All'}, ...assignedOptions]
  }});

  const dispatch = useDispatch();
  const { selectedAreas } = useSelector((state) => state.event);
  const [isAllSelected, setIsAllSelected] = useState(true);

  // Set 'All' as default selection
  useEffect(() => {
    if ((!selectedAreas || selectedAreas.length === 0) && areas.length > 0) {
      dispatch(setSelectedAreas(['All']));
    }
  }, [areas, selectedAreas]);

  const updateSelection = useCallback(
    (selected) => {
      if (isAllSelected) {
        if (selected.includes('All')) {
          setIsAllSelected(false);
          const filteredSelection = selected.filter((e) => e !== 'All');
          if (filteredSelection.length === 0) {
            setIsAllSelected(true);
            return dispatch(setSelectedAreas(['All']));
          }
          return dispatch(setSelectedAreas(filteredSelection));
        }
      } else {
        if (selected.includes('All')) {
          setIsAllSelected(true);
          return dispatch(setSelectedAreas(['All']));
        } else {
          if (selected.length === 0) {
            setIsAllSelected(true);
            return dispatch(setSelectedAreas(['All']));
          }
          return dispatch(setSelectedAreas(selected));
        }
      }
    },
    [isAllSelected]
  );

  const textField = <Autocomplete.TextField placeholder="Select Areas" autoComplete="off" />;

  return (
    <div>
      <Autocomplete
        textField={textField}
        allowMultiple
        selected={selectedAreas || ['All']}
        options={areas}
        onSelect={updateSelection}
      />
      <div className="overflow-x-auto whitespace-nowrap my-2 h-10">
        {selectedAreas &&
          selectedAreas.length > 0 &&
          selectedAreas.map((selected) => (
            <span key={selected} className="inline-block mx-1 my-2">
              <Tag>
                {selected
                  .replace(/_/g, ' ')
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Tag>
            </span>
          ))}
      </div>
    </div>
  );
};

const EventFilter = () => {
  const dispatch = useDispatch();
  const eventsState = useSelector((state) => state.event);
  const chosenSite = useSelector((state) => state.auth.chosenSite);
  const sitesAvailable = useSelector((state) => state.company.sitesAvailable);

  const { data: eventDropdownData, isLoading: areFiltersLoading } = useQuery({
    queryKey: ['dropdown'],
    queryFn: fetchEventDropdownData
  });

  const [filter, setFilter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [reviewOptions] = useState([
    {
      label: 'All',
      value: '2'
    },
    {
      label: 'Show Reviewed Events',
      value: '1'
    },
    {
      label: 'Show Unreviewed Events',
      value: '0'
    }
  ]);

  const handleCameraChange = useCallback((value) => dispatch(setCamera(value)), []);
  const handleTagChange = useCallback((value) => dispatch(setTag(value)), []);
  const handleDateChange = useCallback((value) => dispatch(setSelectedDates(value)), []);
  const handleReviewTypeChange = useCallback((value) => dispatch(setSelectedReviewType(value)), []);

  const toggleFilter = useCallback(() => {
    if (windowWidth < 1024) {
      setFilter(!filter);
    }
  }, [windowWidth, filter]);

  useEffect(() => {
    if (windowWidth > 1024) {
      setFilter(true);
    } else setFilter(false);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  // Add shift options
  const shiftOptions = useMemo(() => {
    const site = sitesAvailable?.find(site => site.id === chosenSite?.id);
    if (!site?.shifts) return [{ label: 'All', value: 'All' }];
    try {
      const shifts = JSON.parse(site.shifts);
      return [
        { label: 'All', value: 'All' },
        ...Object.entries(shifts).map(([id, shift]) => ({
          label: shift.shiftName,
          value: id
        }))
      ];
    } catch (e) {
      return [{ label: 'All', value: 'All' }];
    }
  }, [sitesAvailable, chosenSite]);

  if (areFiltersLoading) return <div className="h-auto w-[80vw] grid place-items-center"></div>;

  return (
    <div>
      <div className="bg-white rounded-b-md p-4 shadow-md">
        <div className="title flex items-center justify-between">
          <div className="lg:hidden block">
            <Button onClick={toggleFilter} icon={FilterMajor}>
              Filter
            </Button>
          </div>
        </div>

        <div>
          {filter && (
            <div className="py-2 grid xl:grid-cols-7 lg:grid-cols-7 md:grid-cols-3 grid-cols-1 gap-5">
              <div className="space-y-1">
                <label>Scenario</label>
                <AutoComplete availableOptions={eventDropdownData?.scenarios ?? []} />
              </div>
              <div className="space-y-1">
                <label>Areas</label>
                <AreaFilter />
              </div>
              <Select
                label="Shifts"
                options={shiftOptions}
                onChange={(value) => dispatch(setShift(value))}
                value={eventsState.selectedShift}
              />
              <Select
                label="Cameras"
                options={eventDropdownData?.cameras ?? []}
                onChange={handleCameraChange}
                value={eventsState.selectedCamera}
              />
              <Select
                label="Tags"
                options={eventDropdownData?.tags ?? []}
                onChange={handleTagChange}
                value={eventsState.selectedTag}
              />
              <Select
                label="Review Status"
                options={reviewOptions}
                onChange={handleReviewTypeChange}
                value={eventsState.selectedReviewType}
              />
              <div>
                <DatePickerPopover
                  selectedDates={eventsState.selectedDates}
                  setSelectedDates={handleDateChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventFilter;
