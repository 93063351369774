/* eslint-disable */
import BarGraph from './BarChart';
import PieChart from '../../components/charts/Pie';
import Severity from './Severity';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import {
  generatePdf,
  getEventItemsBySeverity,
  getEventsByArea,
  getEventsByCamera,
  getEventsBySeverity,
  getEventsOverviewApi,
  getFiltersApi,
  getLineChartApi,
  getOverallStatsApi
} from '../../api/graphs.api';
import {
  ActionList,
  Button,
  DatePicker,
  Icon,
  Label,
  Popover,
  Select,
  Spinner,
  Tooltip
} from '@shopify/polaris';
import LineChart from '../../components/charts/LineChart';
import { downloadReport } from '../../api/reports.api';
import { Modal, notification } from 'antd';
import { Icons } from '../../Icons';
import { format } from 'date-fns';
import { CalendarMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import Sitemap from './Sitemap';
import { formatDate, formatTimeFromSeconds } from '../../utils';
import { uploadBase64Image } from '../../api/images.api';
import { Select as AntSelect } from 'antd';
import { useSearchParams } from 'react-router-dom';

export const PAGE_SIZE = 6;

/** TIGHTLY COUPLED COMPONENT */
export const StatCard = ({ title, trend, trendNumber, value, inverseTrendBenefit = false, isLoading = false }) => {
  if (isLoading) {
    return (
      <div className="bg-white border shadow-md w-full flex flex-col justify-center items-center  rounded-lg pt-6 pb-3 px-6 h-[130px]">
        <Spinner accessibilityLabel="Loading chart" size="large" />
      </div>
    );
  }
  if (title === 'PPE Compliance')
    return (
      <div className="bg-white border shadow-md w-full flex flex-col justify-start  rounded-lg pt-6 pb-3 px-6">
        <h6 className="font-semibold">{title}</h6>
        <div className="flex items-center justify-between w-full gap-2 py-5">
          <div>
            {title === 'PPE Compliance' && <span className="text-3xl lg:max-2xl:text-lg">{value}</span>}
          </div>
        </div>
      </div>
    );
  if (title === 'Time Since Last Event')
    return (
      <div className="bg-white border shadow-md w-full flex flex-col justify-start rounded-lg pt-6 pb-3 px-6">
        <h6 className="font-semibold">{title}</h6>
        <div className="flex items-center justify-between w-full gap-2 py-5">
          <div>
            {title === 'Time Since Last Event' && <span className="text-3xl lg:max-2xl:text-lg">{value}</span>}
          </div>
        </div>
      </div>
    );
  return (
    <div className="bg-white border shadow-md w-full flex flex-col justify-start rounded-lg pt-6 pb-3 px-6">
      <h6 className="font-semibold">{title}</h6>
      <div className="flex items-center justify-between w-full gap-2 py-5" >
        <div className="w-[calc(50%-3px)]" >
          <span className="text-2xl lg:max-2xl:text-base">{value}</span>
          {trend === 'POSITIVE' ? (
            <div className="flex items-center justify-start gap-1">
              <span className={`text-[${!inverseTrendBenefit ? '#45A637' : '#E51C00'}] text-sm lg:max-2xl:text-xs`}>
                +{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.upwardArrow
                className="h-3 lg:max-2xl:h-2"
                fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
              />
            </div>
          ) : trend === 'NEGATIVE' ? (
            <div className="flex items-center justify-start gap-1">
              <span className={`text-[${!inverseTrendBenefit ? '#E51C01' : '#45A637'}] text-sm lg:max-2xl:text-xs`}>
                -{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.downwardArrow
                className="h-3 lg:max-2xl:h-2"
                fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
              />
            </div>
          ) : null}
        </div>
        {trend === 'POSITIVE' ? (
          <Icons.upwardLineChart
          className="w-[50%]"
          stroke={`${!inverseTrendBenefit ? '#04CE00' : '#FF718B'} `}
        />
        ) : trend === 'NEGATIVE' ? (
          <Icons.downwardLineChart
              className="w-[50%]"
              stroke={`${!inverseTrendBenefit ? '#FF718B' : '#04CE00'} `}
            />
        ) : null}
      </div>
    </div>
  );
};

const formatTimeSince = (dateString) => {
  if (!dateString) {
    return '0d 0h 0m 0s';
  }
  const now = new Date();
  const date = new Date(dateString);
  const diff = now - date;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

const Dashboard = () => {
  const chosenSite = useSelector((state) => state.auth.chosenSite);
  const sitesAvailable = useSelector((state) => state.company.sitesAvailable);
  const areas = useSelector((state) => state.auth.areas || []);

  // Log changes to track when these values update
  useEffect(() => {
    // console.log('chosenSite changed:', chosenSite);
    // console.log('sitesAvailable changed:', sitesAvailable);
    // console.log('areas changed:', areas);
  }, [chosenSite, sitesAvailable, areas]);

  const [api, contextHolder] = notification.useNotification();
  const [incidentLogPageNumber, setIncidentLogPageNumber] = useState(1);
  const [severityTypeFilter, setSeverityTypeFilter] = useState(null);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  // refs for building screenshots
  const barChartRef = useRef(null);
  const pieChartByAreaRef = useRef(null);
  const pieChartByCameraRef = useRef(null);
  const severityBreakdownRef = useRef(null);
  const lineGraphRef = useRef(null);
  const sitemapRef = useRef(null);

  const [popoverActive, setPopoverActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const togglePopoverActive = useCallback(() => setPopoverActive((active) => !active), []);

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth() - 1,
    year: new Date().getFullYear()
  });
  const [selectedDates, setSelectedDates] = useState({
    start: (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date;
    })(),
    end: new Date()
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleOk = () => {
    refetchStats();
    refetchBarChart();
    refetchEventsByArea();
    refetchEventsByCamera();
    refetchEventsBySeverity();
    refetchEventItemsBySeverity();
    refetchLineChartData();
    setIsDateModalOpen(false);
  };

  const handleCancel = () => {
    setIsDateModalOpen(false);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    scenario: searchParams.get('scenario') || 'All',
    timeRange: searchParams.get('timeRange') || 'LAST_24_HOURS',
    camera: searchParams.get('camera') || 'All',
    area: searchParams.get('area')?.split(',') || ['All'],
    shift: searchParams.get('shift') || 'All'
  });

  const [timeSinceLastIncident, setTimeSinceLastIncident] = useState(
    formatTimeSince(new Date().toISOString())
  );

  const [timeBetweenIncidents, setTimeBetweenIncidents] = useState(formatTimeFromSeconds(0));

  const {
    data: filters,
    isLoading: areFiltersLoading,
    isError: isFilterError,
    error: filterError
  } = useQuery({
    queryKey: ['dashboard-filters'],
    queryFn: () => getFiltersApi(),
  });

  useEffect(() => {
    if (filters) {
      setSelectedFilters( (value) => ({
        scenario: value.scenario ? value.scenario : filters.scenarios[0]?.value || 'All',
        timeRange: value.timeRange ? value.timeRange : filters.timeRange[0]?.value || 'LAST_24_HOURS',
        camera: value.camera ? value.camera : filters.cameras[0]?.value || 'All',
        area: value.area ? value.area : ['All'], // Select all areas by default
        shift: value.shift ? value.shift : 'All'
      }));
    }
  }, [filters]);

  // Add shift options using useMemo
  const shiftOptions = useMemo(() => {
    const site = sitesAvailable?.find(site => site.id === chosenSite?.id);
    if (!site?.shifts) return [{ label: 'All', value: 'All' }];
    
    try {
      const shifts = JSON.parse(site.shifts);
      return [
        { label: 'All', value: 'All' },
        ...Object.entries(shifts).map(([id, shift]) => ({
          label: shift.shiftName,
          value: id
        }))
      ];
    } catch (e) {
      return [{ label: 'All', value: 'All' }];
    }
  }, [sitesAvailable, chosenSite]);

  // reset page number to 1 when filters change
  useEffect(() => {
    setIncidentLogPageNumber(1);
  }, [selectedFilters, severityTypeFilter]);

  const { data: statData, refetch: refetchStats, isLoading: isStatLoading, isRefetching: isStatRefetching } = useQuery({
    queryKey: ['dashboard-data', selectedFilters, selectedDates],
    queryFn: () =>
      getOverallStatsApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area.length > 0 ? selectedFilters.area : areas.map(area => area.value),
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  useEffect(() => {
    if (statData) {
      const intervalId = setInterval(() => {
        setTimeSinceLastIncident(formatTimeSince(statData.timeSinceLastIncident.value));
      }, 1000);
      if (statData.averageTimeBetweenEvents) {
        setTimeBetweenIncidents(formatTimeFromSeconds(statData.averageTimeBetweenEvents.value));
      }
      return () => clearInterval(intervalId);
    }
  }, [statData]);

  const {
    data: barChartData,
    isLoading: isBarChartLoading,
    refetch: refetchBarChart
  } = useQuery({
    queryKey: ['events-overview', selectedFilters, selectedDates],
    queryFn: () =>
      getEventsOverviewApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const {
    data: eventsDataByArea,
    isLoading: areEventsByAreaLoading,
    refetch: refetchEventsByArea
  } = useQuery({
    queryKey: ['events-by-area', selectedFilters, selectedDates],
    queryFn: () =>
      getEventsByArea({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const {
    data: eventsDataByCamera,
    isLoading: areEventsByCameraLoading,
    refetch: refetchEventsByCamera
  } = useQuery({
    queryKey: ['events-by-camera', selectedFilters, selectedDates],
    queryFn: () =>
      getEventsByCamera({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const {
    data: eventsBySeverity,
    isLoading: areEventsBySeverityLoading,
    refetch: refetchEventsBySeverity
  } = useQuery({
    queryKey: ['severity', selectedFilters, selectedDates],
    queryFn: () =>
      getEventsBySeverity({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const {
    data: eventsBySeverityItems,
    isLoading: areEventItemsBySeverityLoading,
    isError: didEventItemsBySeverityError,
    refetch: refetchEventItemsBySeverity
  } = useQuery({
    queryKey: ['severity-items', selectedFilters, selectedDates, incidentLogPageNumber, severityTypeFilter],
    queryFn: () =>
      getEventItemsBySeverity({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        pageSize: PAGE_SIZE,
        page: incidentLogPageNumber,
        type: severityTypeFilter,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const {
    data: lineChartData,
    isLoading: isLineChartDataLoading,
    refetch: refetchLineChartData
  } = useQuery({
    queryKey: ['line-chart', selectedFilters, selectedDates],
    queryFn: () =>
      getLineChartApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE',
        shift: selectedFilters.shift
      })
  });

  const { mutate: generateReport, isPending: generatingReport } = useMutation({
    mutationFn: downloadReport,
    onSuccess: () => {
      api.success({
        message: 'Report downloaded successfully',
        duration: 2,
        placement: 'bottomRight'
      });
    },
    onError: (err) => {
      api.error({
        message: 'Selected filters have no data to generate a report.',
        duration: 2,
        placement: 'bottomRight'
      });
    }
  });

  const { mutate: generatePdfApi, isPending: generatingPdf } = useMutation({
    mutationFn: async () => {
      const payload = {
        statistics: { ...statData }
      };
      let response;
      if (barChartRef.current) {
        response = await uploadBase64Image(barChartRef.current.toBase64Image('image/png', 0.3));
        payload.eventOverview = {
          image: {
            url: response.secure_url,
            public_id: response.public_id
          }
        };
      }
      if (pieChartByAreaRef.current) {
        response = await uploadBase64Image(pieChartByAreaRef.current.toBase64Image());
        payload.eventsByArea = {
          title: selectedFilters.area,
          image: {
            url: response.secure_url,
            public_id: response.public_id
          },
          legend: pieChartByAreaRef.current.getLegendData()
        };
      }
      if (pieChartByCameraRef.current) {
        response = await uploadBase64Image(pieChartByCameraRef.current.toBase64Image());
        payload.eventsByCamera = {
          title: selectedFilters.camera,
          image: {
            url: response.secure_url,
            public_id: response.public_id
          },
          legend: pieChartByCameraRef.current.getLegendData()
        };
      }
      if (severityBreakdownRef.current) {
        response = await uploadBase64Image(severityBreakdownRef.current.toBase64Image());
        payload.severityBreakdown = {
          image: {
            url: response.secure_url,
            public_id: response.public_id
          }
        };
      }
      if (lineGraphRef.current) {
        response = await uploadBase64Image(lineGraphRef.current.toBase64Image('image/png', 0.4));
        payload.lineGraph = {
          image: {
            url: response.secure_url,
            public_id: response.public_id
          }
        };
      }
      if (sitemapRef.current) {
        response = await uploadBase64Image(
          sitemapRef.current.toDataURL({
            pixelRatio: 0.4
          })
        );
        payload.sitemap = {
          image: {
            url: response.secure_url,
            public_id: response.public_id
          }
        };
      }
      return generatePdf(payload);
    },
    onSuccess: (data) => {
      // Create a blob URL from the response data
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'pdf-report.pdf');
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      api.success({
        message: 'PDF Generated successfully',
        duration: 2,
        placement: 'bottomRight'
      });
    },
    onError: (err) => {
      api.error({
        message: err.message ?? 'Unable to generate PDF Report',
        duration: 2,
        placement: 'bottomRight'
      });
    }
  });

  const handleGenerateReport = useCallback(async () => {
    if (selectedFilters.timeRange === 'CUSTOM_DATE_RANGE') {
      generateReport({
        scenarioType: selectedFilters.scenario,
        timePeriod: selectedFilters.timeRange,
        cameraName: selectedFilters.camera === 'All' ? null : selectedFilters.camera,
        area: selectedFilters.area === 'All' ? null : selectedFilters.area,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        shift: selectedFilters.shift
      });
    } else {
      generateReport({
        scenarioType: selectedFilters.scenario,
        timePeriod: selectedFilters.timeRange,
        cameraName: selectedFilters.camera === 'All' ? null : selectedFilters.camera,
        area: selectedFilters.area === 'All' ? null : selectedFilters.area,
        shift: selectedFilters.shift
      });
    }
    setPopoverActive(false);
  }, [selectedFilters, selectedDates]);

  const handleGeneratePDF = useCallback(() => {
    generatePdfApi();
    setPopoverActive(false);
  }, []);

  const activator = (
    <div className='pdf_button'>
    <Button onClick={togglePopoverActive} style={{ width: '100%' }} disclosure disabled={generatingReport || generatingPdf}>
      {generatingReport || generatingPdf ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Spinner size="small" />
          {generatingReport ? 'Generating Report...' : 'Generating PDF...'}
        </div>
      ) : (
        'Generates Report'
      )}
    </Button>
    </div>
  );

  const actionItems = [
    {
      content: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          {generatingReport && <Spinner size="small" />}
          Generate Report
        </div>
      ),
      onAction: handleGenerateReport,
      disabled: generatingReport || generatingPdf
    },
    // {
    //   content: (
    //     <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
    //       {generatingPdf && <Spinner size="small" />}
    //       Generate PDF
    //     </div>
    //   ),
    //   onAction: handleGeneratePDF,
    //   disabled: generatingReport || generatingPdf
    // }
  ];

  // Initialize filters from URL params
  useEffect(() => {
    const camera = searchParams.get('camera');
    const area = searchParams.get('area')?.split(',');
    const scenario = searchParams.get('scenario');
    const timeRange = searchParams.get('timeRange');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const shift = searchParams.get('shift');

    if (camera || area || scenario || timeRange || (startDate && endDate) || shift) {
      setSelectedFilters(prev => ({
        ...prev,
        camera: camera || 'All',
        area: area || ['All'],
        scenario: scenario || 'All',
        timeRange: timeRange || 'LAST_24_HOURS',
        shift: shift || 'All'
      }));
    }

    if (startDate && endDate) {
      setSelectedDates({
        start: new Date(startDate),
        end: new Date(endDate)
      });
    }
  }, []);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (selectedFilters.camera && selectedFilters.camera !== 'All') {
      params.set('camera', selectedFilters.camera);
    } else {
      params.delete('camera');
    }

    if (selectedFilters.area?.length && !selectedFilters.area.includes('All')) {
      params.set('area', selectedFilters.area.join(','));
    } else {
      params.delete('area');
    }

    if (selectedFilters.scenario && selectedFilters.scenario !== 'All') {
      params.set('scenario', selectedFilters.scenario);
    } else {
      params.delete('scenario');
    }

    if (selectedFilters.timeRange && selectedFilters.timeRange !== 'LAST_24_HOURS') {
      params.set('timeRange', selectedFilters.timeRange);
    } else {
      params.delete('timeRange');
    }

    if (selectedFilters.timeRange === 'CUSTOM_DATE_RANGE' && selectedDates?.start && selectedDates?.end) {
      params.set('startDate', selectedDates.start.toISOString());
      params.set('endDate', selectedDates.end.toISOString());
    } else {
      params.delete('startDate');
      params.delete('endDate');
    }

    if (selectedFilters.shift) {
      params.set('shift', selectedFilters.shift);
    } else {
      params.delete('shift');
    }

    setSearchParams(params);
  }, [selectedFilters, selectedDates]);

  if (isFilterError) {
    return <div>{filterError.message}</div>;
  }

  return (
    <div>
      {contextHolder}
      <Modal
        key={selectedFilters.timeRange}
        width={800}
        title="Choose Date Range"
        open={isDateModalOpen}
        footer={[
          <span className="mr-1">
            <Button variant="secondary" className="mx-2" onClick={handleCancel}>
              Cancel
            </Button>
          </span>,
          <span className="ml-1">
            <Button onClick={handleOk} variant="primary">
              Submit
            </Button>
          </span>
        ]}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
          disableDatesAfter={new Date()}
        />
      </Modal>
      <div className="mx-2 mt-2 grid grid-cols-1 md:grid-cols-6 gap-2">
        <Select
          label="Scenarios"
          disabled={areFiltersLoading}
          options={filters?.scenarios}
          value={selectedFilters.scenario}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, scenario: value });
          }}
        />
        <div className="flex items-center justify-between w-full">
          <span className="grow">
            <Select
              label="Time Period"
              disabled={areFiltersLoading}
              options={filters?.timeRange}
              value={selectedFilters.timeRange}
              onChange={(value) => {
                if (value === 'CUSTOM_DATE_RANGE') {
                  // open date picker
                  setIsDateModalOpen(true);
                }
                setSelectedFilters({ ...selectedFilters, timeRange: value });
              }}
            />
          </span>
          <span className="ml-2">
            <Tooltip dismissOnMouseOut content="Select Custom Date Range">
              <Button
                variant="primary"
                onClick={() => {
                  setIsDateModalOpen(true);
                  setSelectedFilters({ ...selectedFilters, timeRange: 'CUSTOM_DATE_RANGE' });
                }}
              >
                <Icon source={CalendarMinor} />
              </Button>
            </Tooltip>
          </span>
        </div>
        <Select
          label="Cameras"
          disabled={areFiltersLoading}
          options={filters?.cameras}
          value={selectedFilters.camera}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, camera: value });
          }}
        />
        <Select
          label="Shifts"
          options={shiftOptions}
          value={selectedFilters.shift}
          onChange={(value) => {
            setSelectedFilters(prev => ({ ...prev, shift: value }));
          }}
        />
        <div>
          <Label>Areas</Label>
          <AntSelect
            mode="multiple"
            // remove next line if there is any render issue happening.
            maxTagCount={"responsive"}
            className="ant-select-polaris w-full h-[36px]"
            placeholder="Select areas"
            disabled={areFiltersLoading}
            options={filters?.areas}
            value={selectedFilters.area}
            onChange={(values) => {
              const lastSelected = values[values.length - 1];
              
              if (lastSelected === 'All') {
                // If 'All' was just selected
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: ['All']
                });
              } else if (values.includes('All')) {
                // If 'All' is already selected and we're selecting something else
                const filteredValues = values.filter(v => v !== 'All');
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: filteredValues
                });
              } else if (values.length === 0) {
                // If deselecting everything, default to 'All'
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: ['All']
                });
              } else {
                // Normal selection of specific areas
                setSelectedFilters({ 
                  ...selectedFilters, 
                  area: values 
                });
              }
            }}
          />
        </div>

        {/* Add this CSS to match Polaris styling */}
        <style>
          {`
            .ant-select-polaris .ant-select-selector {
              border-radius: 4px !important;
              border: 1px solid #8c9196 !important;
              height: 36px !important;
              align-items: center !important;
            }
            
            .ant-select-polaris:hover .ant-select-selector {
              border-color: #2c6ecb !important;
            }

            .ant-select-polaris.ant-select-focused .ant-select-selector {
              border-color: #2c6ecb !important;
              box-shadow: 0 0 0 1px #2c6ecb !important;
            }
            .Polaris-Button {
              max-height: 20px !important;
              margin-top: 1.5rem;
            }
            .pdf_button .Polaris-Button {
              width: 100% !important;
            }
          `}
        </style>
        <Popover
          active={popoverActive && !generatingReport && !generatingPdf}
          activator={activator}
          autofocusTarget="first-node"
          onClose={togglePopoverActive}
        >
          <ActionList actionRole="menuitem" items={actionItems} />
        </Popover>
      </div>
      <div className="m-2">
        {selectedFilters.timeRange === 'CUSTOM_DATE_RANGE' && (
          <p className="text-sm mb-2">
            {`Selected Date Range: ${format(new Date(selectedDates.start), 'dd MMMM, yyyy')} to ${format(new Date(selectedDates.end), 'dd MMMM, yyyy')}`}
          </p>
        )}
        <div
          className={`grid grid-cols-1 md:grid-cols-2 ${statData && 'ppeCompliance' in statData && statData.ppeCompliance.value !== null ? 'lg:grid-cols-5' : 'lg:grid-cols-4'} gap-2 mb-4`}
        >
          <StatCard
            title={'Time Since Last Event'}
            trend={'NEUTRAL'}
            trendNumber="0"
            value={timeSinceLastIncident}
            isLoading={isStatRefetching || isStatLoading}
          />
          <StatCard
            title={'Time Between Events'}
            trend={statData?.averageTimeBetweenEvents?.trend ?? 'POSITIVE'}
            value={timeBetweenIncidents}
            trendNumber={statData?.averageTimeBetweenEvents?.trendValue ?? 0}
            isLoading={isStatRefetching || isStatLoading}
          />
          <StatCard
            title="Total Events"
            trend={statData?.totalIncidents?.trend ?? 'POSITIVE'}
            value={statData?.totalIncidents?.value ?? 0}
            trendNumber={statData?.totalIncidents?.trendValue ?? 0}
            inverseTrendBenefit={true}
            isLoading={isStatRefetching || isStatLoading}
          />
          <StatCard
            title="Reviewed Events"
            trend={statData?.reviewedIncidents?.trend ?? 'POSITIVE'}
            value={
              `${statData?.reviewedIncidents?.value ?? 0} (${(statData?.reviewedIncidentsPercentage?.value ?? 0).toFixed(2)}%)` ??
              '0 (0%)'
            }
            trendNumber={statData?.reviewedIncidents?.trendValue ?? 0}
            isLoading={isStatRefetching || isStatLoading}
          />
          {statData && 'ppeCompliance' in statData && statData.ppeCompliance.value !== null && (
            <StatCard
              title={'PPE Compliance'}
              trend={'NEUTRAL'}
              trendNumber="0"
              value={`${statData.ppeCompliance.value}%`}
              isLoading={isStatRefetching || isStatLoading}
            />
          )}
        </div>
        <BarGraph reference={barChartRef} isLoading={isBarChartLoading} data={barChartData} timeRange={selectedFilters.timeRange} startDate={selectedDates.start} endDate={selectedDates.end} />
        <div className="flex flex-col lg:flex-row justify-between gap-2 mb-4">
          <div className="w-full lg:w-1/2">
            <PieChart
              reference={pieChartByAreaRef}
              isLoading={areEventsByAreaLoading}
              data={eventsDataByArea}
              name={'Events By Area'}
            />
          </div>
          <div className="w-full lg:w-1/2">
            <PieChart
              isLoading={areEventsByCameraLoading}
              data={eventsDataByCamera}
              name={'Events By Camera'}
              reference={pieChartByCameraRef}
            />
          </div>
        </div>
        <div className="mb-4">
          <Severity
            reference={severityBreakdownRef}
            title={selectedFilters.scenario}
            isLoading={areEventsBySeverityLoading}
            data={eventsBySeverity}
            events={eventsBySeverityItems}
            areEventsLoading={areEventItemsBySeverityLoading}
            incidentLogPageNumber={incidentLogPageNumber}
            setIncidentLogPageNumber={setIncidentLogPageNumber}
            severityTypeFilter={severityTypeFilter}
            setSeverityTypeFilter={setSeverityTypeFilter}
          />
        </div>
        <div className="mb-4">
          <LineChart
            reference={lineGraphRef}
            data={lineChartData}
            isLoading={isLineChartDataLoading}
            name={selectedFilters.scenario}
          />
        </div>
        {sitesAvailable &&
          sitesAvailable.length &&
          sitesAvailable
            .filter((ele) => ele.id === chosenSite.id)
            .filter((e) => e.sitemap_url !== null)
            .map((site) => {
              return (
                <Sitemap
                  selectedFilters={selectedFilters}
                  selectedDates={selectedDates}
                  site={site}
                  reference={sitemapRef}
                />
              );
            })}
      </div>
    </div>
  );
};

export default Dashboard;
