import { Button, Spinner, TextField, Select } from '@shopify/polaris';
import { Modal, Select as AntSelect } from 'antd';

const AddUserModal = ({
  open,
  handleOk,
  handleCancel,
  addUserForm,
  addUserErrors,
  handleAddUserFieldChange,
  isPending,
  timezones,
  roles,
  sites,
  countryCodes,
  areas
}) => {
  return (
    <Modal
      title="Add User"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      maskClosable={false}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          <Button variant="primary" onClick={handleOk}>
            <div className="flex items-center">
              Submit
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}>
      <form>
        <div className="w-full">
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Full Name<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={addUserForm.name}
                onChange={(value) => handleAddUserFieldChange(value, 'name')}
                labelHidden
                placeholder="Benjamin Bookcase"
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Username<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={addUserForm.username}
                onChange={(value) => handleAddUserFieldChange(value, 'username')}
                labelHidden
                placeholder="bbookcase"
              />
              {addUserErrors.existingUsername && (
                <span className="text-red-500 text-xs mt-2">Username already exists!</span>
              )}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Email Address<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                value={addUserForm.email}
                onChange={(value) => handleAddUserFieldChange(value, 'email')}
                labelHidden
                placeholder="bbookcase@visionify.ai"
              />
              {addUserErrors.existingEmail && (
                <span className="text-red-500 text-xs mt-2">Email already exists!</span>
              )}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Timezone<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleAddUserFieldChange(value, 'timezone')}
                value={addUserForm.timezone}
                labelHidden
                options={timezones}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Role<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleAddUserFieldChange(value, 'role')}
                value={addUserForm.role}
                labelHidden
                options={roles}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Site <span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <Select
                onChange={(value) => handleAddUserFieldChange(value, 'site')}
                value={addUserForm.site}
                labelHidden
                options={sites}
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Phone<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <TextField
                type="tel"
                value={addUserForm.phone}
                onChange={(value) => {
                  if (isNaN(value)) return;
                  handleAddUserFieldChange(value, 'phone');
                }}
                labelHidden
                prefix={
                  <div className="flex">
                    <span className="mr-[8px] text-black">
                      {countryCodes[addUserForm.timezone]}
                    </span>
                    <span className="border-r border-black opacity-30" />
                  </div>
                }
                placeholder="8581234567"
              />
            </span>
          </div>
          <div className="flex items-center mb-2">
            <span className="flex-1">
              Areas<span className="text-red-500">*</span>
            </span>

            <span className="w-1/2 lg:w-3/4">
              <AntSelect
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(value) => handleAddUserFieldChange(value, 'areas')}
                value={addUserForm.areas}
                options={areas}
                onSelect={(value) => {
                  if (value === 'All') {
                    handleAddUserFieldChange(['All'], 'areas');
                  } else {
                    const newAreas = addUserForm.areas.filter(area => area !== 'All');
                    if (!newAreas.includes(value)) {
                      handleAddUserFieldChange([...newAreas, value], 'areas');
                    }
                  }
                }}
              />
            </span>
          </div>
        </div>
        {addUserErrors.invalidEmailFormat && (
          <span className="text-red-500 text-sm mt-2">Please enter a valid email!</span>
        )}
        {addUserErrors.invalidFieldsError && (
          <span className="text-red-500 text-sm mt-2">Please fill all the required fields.</span>
        )}
        {addUserErrors.failed && (
          <span className="text-red-500 text-sm mt-2">
            * Unable to add User. Please contact{' '}
            <a href="mailto:support@visionify.ai">support@visionify.ai</a>.
          </span>
        )}
      </form>
    </Modal>
  );
};

export default AddUserModal;
