import { Button, Card, DataTable, Spinner } from '@shopify/polaris';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCameraView, getAllCameraViews } from '../../api/views.api';
import noCameraView from '../../assets/no-camera-view.svg';
import { DeleteMajor, EditMajor, PlayCircleMajor, PlusMinor } from '@shopify/polaris-icons';
import { notification } from 'antd';

const CameraView = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  // Fetching all camera views
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['allCameraViews', currentPage],
    queryFn: () => getAllCameraViews(currentPage, pageSize)
  });

  // Handle pagination
  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handlePlay = (id) => {
    navigate(`/views/play/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/views/edit/${id}`);
  };

  const refetchNotifications = () => {
    queryClient.invalidateQueries(['allCameraViews', currentPage, pageSize]);
  };

  const { mutate: deleteMutation } = useMutation({
    mutationFn: ({ id }) => {
      return deleteCameraView(id);
    },
    onSuccess: () => {
      api.success({
        message: 'CameraView deleted successfully.',
        placement: 'topRight'
      });
      refetchNotifications();
    },
    onError: () => {
      api.error({
        message: 'Error deleting CameraView.',
        placement: 'topRight'
      });
    }
  });

  const handleDelete = (id) => {
    deleteMutation({ id });
  };

  if (isLoading) {
    return (
      <div className="h-[calc(100vh-100px)] w-full grid place-items-center">
        <div className="flex justify-center items-center min-h-screen">
          <Spinner accessibilityLabel="Loading" size="large" />
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error loading camera views: {error.message}</div>;
  }

  const cameraViews = data?.data || [];
  const totalViews = data?.total || 0;

  // Display the "No Views Found" message if there are no camera views
  if (cameraViews.length === 0) {
    return (
      <div className="px-4 pb-4 w-full text-center">
        <img className="w-[300px] mx-auto mt-10" src={noCameraView} alt="No Camera View" />
        <h3 className="pt-5 font-semibold text-lg">No Views Found</h3>
        <p className="pt-3 text-base text-[#616161] pb-5">
          This section is currently empty. You can add View
        </p>
        <div>
          <Button
            largesize="large"
            variant="primary"
            icon={PlusMinor}
            onClick={() => navigate('/views/create')}>
            Add View
          </Button>
        </div>
      </div>
    );
  }

  // Define columns and rows for DataTable
  const columns = ['Name', 'Camera Names', 'Area Names', 'Event Names', 'Updated By', 'Actions'];

  const formatCSV = (csv) => {
    return csv
      ? csv
          .split(',')
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(', ')
      : 'All';
  };

  const rows = cameraViews.map((view) => [
    view.name,
    <div
      key={view.id}
      className={`overflow-x-auto whitespace-nowrap ${
        view.area_names?.length > 20 ? 'h-10' : 'h-6'
      } mt-2`}>
      {formatCSV(view.area_names)}
    </div>,
    <div
      key={view.id}
      className={`overflow-x-auto whitespace-nowrap ${
        view.camera_names?.length > 20 ? 'h-10' : 'h-6'
      } mt-2`}>
      {formatCSV(view.camera_names)}
    </div>,
    <div
      key={view.id}
      className={`overflow-x-auto whitespace-nowrap ${
        view.event_names?.length > 20 ? 'h-10' : 'h-6'
      } mt-2 max-w-40`}>
      {formatCSV(view.event_names)}
    </div>,
    view.user.name,
    <div key={view.id} className="flex">
      <span className="pr-2">
        <Button onClick={() => handlePlay(view.id)} plain icon={PlayCircleMajor} />
      </span>
      <span className="pr-2">
        <Button onClick={() => handleEdit(view.id)} plain icon={EditMajor} />
      </span>
      <span className="pr-2">
        <Button onClick={() => handleDelete(view.id)} plain icon={DeleteMajor} />
      </span>
    </div>
  ]);

  return (
    <>
      <div className="radiousnone main-title">
        <Card roundedAbove="xs">
          <div className="title flex items-center justify-between smallbtn">
            <div className="flex items-center gap-3">
              <div className="title">
                <h5 className="text-lg font-semibold">Views</h5>
              </div>
            </div>
            <Button icon={PlusMinor} onClick={() => navigate('/views/create')}>
              Add View
            </Button>
          </div>
        </Card>
      </div>

      <div className="cameralist">
        <div className="px-4 py-4">
          {contextHolder}
          <Card>
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
              hasZebraStripingOnData
              headings={columns}
              rows={rows}
              verticalAlign="middle"
              pagination={{
                hasNext: totalViews > currentPage * pageSize,
                hasPrevious: currentPage > 1,
                onNext: handleNext,
                onPrevious: handlePrevious,
                accessibilityLabel: 'Pagination Navigation',
                label: `Page ${currentPage} of ${Math.ceil(totalViews / pageSize)}`,
                type: 'table'
              }}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default CameraView;
