import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getScenarioByName } from '../api/scenarios.api';
import ScenarioHeader from '../components/ScenarioHeader';
import { Button, DataTable, Divider, Page, VideoThumbnail } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import useVideoThumbnailWDuration from '../hooks/useVideoThumbnail';
import { convertKeyToTitle, translateEvent } from '../utils';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import useAccessControl from '../hooks/useAccessControl';
import { ACCESS } from '../data/userRoles';

const fieldsToRender = ['overview', 'gallery', 'events'];

const Overview = ({ scenario }) => {
  // eslint-disable-next-line no-unused-vars
  const [overviewBlocks, setOverviewBlock] = useState(scenario.overview.split('\n'));
  return (
    <div>
      <h3 className="text-lg font-semibold">Overview</h3>
      <div className="mt-4">
        {overviewBlocks.map((block, idx) => (
          <p key={idx} className={`text-md ${idx + 1 !== overviewBlocks.length && 'mb-2'}`}>
            {block}
          </p>
        ))}
      </div>
    </div>
  );
};

const Gallery = ({ scenario }) => {
  const [isPlayerVisible, setPlayerVisible] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  const thumbnailsAndDurations = useVideoThumbnailWDuration(scenario.video_clips);

  const showVideoPlayer = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setPlayerVisible(true);
  };

  const hideVideoPlayer = (e) => {
    if (e.target === e.currentTarget) setPlayerVisible(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setPlayerVisible(false);
      }
    };

    if (isPlayerVisible) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPlayerVisible]);

  return (
    <div>
      <h3 className="text-lg font-semibold">Gallery</h3>
      <div className="mt-4 flex overflow-x-auto overflow-y-hidden gap-4">
        {thumbnailsAndDurations &&
          thumbnailsAndDurations.length !== 0 &&
          scenario.video_clips.map((clip, idx) => (
            <div
              key={idx}
              className="h-[152px] w-[280px] max-h-[152px] min-h-[152px] max-w-[280px] min-w-[280px] rounded-md"
              onClick={() => showVideoPlayer(clip)}
            >
              <VideoThumbnail
                videoLength={thumbnailsAndDurations[idx]?.duration?.toFixed(0) ?? 12}
                thumbnailUrl={thumbnailsAndDurations[idx]?.thumbnailUrl ?? ''}
              />
            </div>
          ))}
      </div>
      {isPlayerVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={hideVideoPlayer}>
          <div
            className="fixed inset-0 m-auto bg-white rounded-lg overflow-hidden h-[60vh] w-[60vw]"
            onClick={(e) => e.stopPropagation()}
          >
            <ReactPlayer url={selectedVideoUrl} controls playing width="100%" height="100%" />
          </div>
        </div>
      )}
    </div>
  );
};

const TableHeading = ({ className = 'font-semibold text-sm w-full', text }) => (
  <span className={className}>{text}</span>
);

const EventsTable = ({ scenario, constructRows }) => {
  return (
    <div>
      <h3 className="text-lg font-semibold">Events</h3>
      <div className="mt-4 border rounded-md">
        <DataTable
          columnContentTypes={['text', 'text', 'text']}
          headings={[
            <TableHeading key={1} text="Event" />,
            <TableHeading key={2} text="Schedule" />,
            <TableHeading key={3} text="Description" />
          ]}
          hasZebraStripingOnData
          rows={constructRows(scenario)}
        />
      </div>
    </div>
  );
};

const RenderWithSpacing = ({ children }) => <div className="mb-6">{children}</div>;

const ScenarioDetails = () => {
  const { id, name } = useParams();
  const navigateTo = useNavigate();
  const { data: scenario, isLoading } = useQuery({
    queryKey: [id],
    queryFn: () => {
      return getScenarioByName(name);
    }
  });

  // Access Control for Scenario Details
  const { hasAccess: hasApplyScenarioAccess, isLoading: isApplyScenarioLoading } = useAccessControl(ACCESS.APPLY_SCENARIO);

  const allowedScenarios = useSelector((state) => state.company.subscribedScenarios);

  const constructRows = useCallback(
    (scenario) => {
      if (scenario)
        return Object.keys(scenario.events).map((event) => {
          return [
            convertKeyToTitle(translateEvent(scenario.events[event].label.toLowerCase()).split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '), '-'),
            convertKeyToTitle(scenario.events[event].schedule),
            convertKeyToTitle(scenario.events[event].description)
          ];
        });
      return [];
    },
    [scenario]
  );

  if (isLoading) return <div>Loading...</div>;

  if (!scenario && !isLoading) return <div>Scenario not found</div>;

  return (
    <div className="bg-white min-h-screen h-full">
      <ScenarioHeader scenario={scenario} sticky={false} />
      <div className="my-6">
        <Page title={scenario.title} titleHidden>
          <div className="flex items-center mb-6">
            <div className="flex-1 basis-1/3 flex justify-center">
              <img src={scenario.icon} className="h-44" alt="Scenario Icon" />
            </div>
            <div className="flex-1 basis-2/3">
              <h3 className="text-lg font-semibold">{scenario.title}</h3>
              <p className="text-md">{scenario.tagline}</p>
              <div className="flex flex-col md:flex-row mt-2 md:items-center justify-between">
                <Button
                  variant="primary"
                  size="large"
                  disabled={!allowedScenarios.includes(scenario.name) || (!isApplyScenarioLoading && !hasApplyScenarioAccess)}
                  onClick={() => navigateTo(`/scenarios/apply/${scenario.id}/${scenario.name}`)}
                >
                  Apply
                </Button>
                {!allowedScenarios.includes(scenario.name) && (
                  <p className="text-red-500 text-wrap">
                    *This scenario is not available for your company. Please contact{' '}
                    <a className="underline text-blue-800" href="mailto:support@visionify.ai">
                      support@visionify.ai
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
          <Divider borderColor="border" />
          <div className="my-6">
            {fieldsToRender.map((field) => {
              if (field === 'overview' && scenario.overview && scenario.overview !== '')
                return (
                  <RenderWithSpacing key="">
                    <Overview scenario={scenario} />
                  </RenderWithSpacing>
                );
              if (field === 'gallery' && scenario.video_clips && scenario.video_clips.length > 0)
                return (
                  <RenderWithSpacing key="">
                    <Gallery scenario={scenario} />
                  </RenderWithSpacing>
                );
              if (field === 'events' && scenario.events && Object.keys(scenario.events).length > 0)
                return (
                  <RenderWithSpacing key="">
                    <EventsTable scenario={scenario} constructRows={constructRows} />
                  </RenderWithSpacing>
                );
            })}
          </div>
        </Page>
      </div>
    </div>
  );
};

export default ScenarioDetails;
