export const EVENT_HOT_TRANSLATION = {
  'no safety boots': 'no safety shoes',
  'no boots': 'no safety shoes',
  'no gloves': 'no safety gloves', 
  'no mask': 'no safety nose mask',
  'no goggles': 'no safety goggles',
  'no helmet': 'no safety helmet',
  'no vest': 'no reflective jacket',
  'safety boots': 'safety shoes',
  'boots': 'safety shoes',
  'gloves': 'safety gloves',
  'mask': 'safety nose mask',
  'goggles': 'safety goggles',
  'helmet': 'safety helmet',
  'vest': 'reflective jacket'
};

export const EVENT_HOT_TRANSLATION_REVERSE = Object.entries(EVENT_HOT_TRANSLATION).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});



