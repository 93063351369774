import { Spinner } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, Title, Legend);

function BarChart({ reference, isLoading, data, setSeverityTypeFilter, forCompare = false }) {
  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(225, 202, 255, 1)');
    gradient.addColorStop(1, 'rgba(156, 106, 222, 1)');
    return gradient;
  };

  const barchartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: forCompare,
        position: 'top'
      },
      tooltip: {
        enabled: true,
        intersect: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3],
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [3, 3],
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30
        }
      }
    }
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: forCompare ? [] : [
      {
        label: '',
        data: [],
        hoverOffset: 2,
        maxBarThickness: 40,
        borderRadius: 8,
        backgroundColor: ''
      }
    ]
  });

  
  useEffect(() => {
    console.log({data})
  }, [data])

  useEffect(() => {
    console.log({chartData})
  }, [chartData])

  useEffect(() => {
    if (reference.current && data) {
      if (forCompare && Array.isArray(data)) {
        // Get all unique labels from all sites
        const allLabels = [...new Set(data.flatMap(site => site.labels))];
        
        // Create datasets for each site
        const datasets = data.map((site, index) => {
          // Create a values array that matches the allLabels array
          const values = allLabels.map(label => {
            const labelIndex = site.labels.indexOf(label);
            return labelIndex !== -1 ? site.values[labelIndex] : 0;
          });

          // Predefined colors for sites
          const colors = [
            'rgba(156, 106, 222, 0.4)',  // Purple
            'rgba(69, 166, 54, 0.4)',    // Green
            'rgba(229, 28, 0, 0.4)',     // Red
            'rgba(54, 162, 235, 0.4)',    // Blue
            'rgba(255, 159, 64, 0.4)'    // Orange
          ];

          return {
            label: `Site ${index + 1}`,
            data: values,
            hoverOffset: 2,
            maxBarThickness: 40,
            borderRadius: 8,
            backgroundColor: colors[index % colors.length]
          };
        });

        setChartData({
          labels: allLabels,
          datasets
        });
      } else {
        // Original single dataset logic
        const chart = reference.current;
        const ctx = chart.ctx;
        const gradient = createGradient(ctx);

        setChartData({
          labels: data.labels ?? [],
          datasets: [
            {
              label: '',
              data: data?.values ?? [],
              hoverOffset: 2,
              maxBarThickness: 40,
              borderRadius: 8,
              backgroundColor: gradient
            }
          ]
        });
      }
    }
  }, [reference, data, forCompare]);

  const onClick = (event) => {
    if (!forCompare) {  // Only enable clicking in non-compare mode
      const element = getElementAtEvent(reference.current, event);
      if (!element.length) {
        setSeverityTypeFilter(null);
        return;
      }
      if (element[0]?.index === 0) {
        setSeverityTypeFilter('Low');
      } else if (element[0]?.index === 1) {
        setSeverityTypeFilter('Moderate');
      } else if (element[0]?.index === 2) {
        setSeverityTypeFilter('High');
      }
    }
  };

  return (
    <div className="border rounded-md p-2 h-full">
      {isLoading ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">Severity Breakdown</h3>
          </div>
          <div className="bar-chart-container mx-[10px] py-5 pb-0" style={{ height: '255px' }}>
            <Bar ref={reference} onClick={onClick} data={chartData} options={barchartoptions} />
          </div>
        </div>
      )}
    </div>
  );
}

export default BarChart;
