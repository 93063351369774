export const ACCESS = {
    SETTINGS_EDIT: 'settings_edit',
    SETTINGS_VIEW: 'settings_view',
    SITE_EDIT: 'site_edit',
    SITE_VIEW: 'site_view',
    SITE_TOGGLE: 'site_toggle',
    CAMERA_EDIT: 'camera_edit',
    CAMERA_ADD: 'camera_add',
    APPLY_SCENARIO: 'apply_scenario',
    EDIT_SCENARIO: 'edit_scenario',
    TOGGLE_SCENARIO: 'toggle_scenario',
    ADD_EDIT_MS_TEAMS_WEBHOOK: 'add_edit_ms_teams_webhook',
    ADD_EDIT_INTEGRATION: 'add_edit_integration',
    EVENT_VALIDATION: 'event_validation',
    DOWNLOAD_VIDEO: 'download_video',
    CAMERA_SEVERITY_TOGGLE: 'camera_severity_toggle',
    CAMERA_SEVERITY_EDIT: 'camera_severity_edit',
    CAMERA_DISABLE: 'camera_disable',
    SITE_COMPARISON: 'site_comparison'
}

export const ROLES = {
    SITE_ADMIN: 'site-admin',
    SITE_USER: 'site-user',
    GLOBAL_ADMIN: 'global-admin',
    GLOBAL_USER: 'global-user',
    VISIONIFY_ADMIN: 'visionify-admin',
}

// UPDATE THE ROLES AS PER THE REQUIREMENTS
export const ROLES_ACCESS = {
  [ROLES.SITE_ADMIN]: [
    ACCESS.APPLY_SCENARIO,
    ACCESS.EDIT_SCENARIO,
    ACCESS.SETTINGS_VIEW,
    ACCESS.SITE_VIEW,
    ACCESS.CAMERA_EDIT,
    ACCESS.CAMERA_ADD,
    ACCESS.TOGGLE_SCENARIO,
    ACCESS.ADD_EDIT_MS_TEAMS_WEBHOOK,
    ACCESS.ADD_EDIT_INTEGRATION,
    ACCESS.EVENT_VALIDATION,
  ],
  [ROLES.SITE_USER]: [
    ACCESS.SETTINGS_VIEW,
    ACCESS.EVENT_VALIDATION,
  ],
  [ROLES.GLOBAL_ADMIN]: [
    ACCESS.SETTINGS_EDIT,
    ACCESS.SETTINGS_VIEW,
    ACCESS.SITE_EDIT,
    ACCESS.SITE_VIEW,
    ACCESS.CAMERA_EDIT,
    ACCESS.CAMERA_ADD,
    ACCESS.APPLY_SCENARIO,
    ACCESS.EDIT_SCENARIO,
    ACCESS.TOGGLE_SCENARIO,
    ACCESS.SITE_TOGGLE,
    ACCESS.ADD_EDIT_MS_TEAMS_WEBHOOK,
    ACCESS.ADD_EDIT_INTEGRATION,
    ACCESS.EVENT_VALIDATION,
    ACCESS.SITE_COMPARISON
  ],
  [ROLES.GLOBAL_USER]: [
    ACCESS.SETTINGS_VIEW,
    ACCESS.SITE_VIEW,
    ACCESS.SITE_TOGGLE,
    ACCESS.EVENT_VALIDATION,
  ],
  [ROLES.VISIONIFY_ADMIN]: [
    ACCESS.SETTINGS_EDIT,
    ACCESS.SETTINGS_VIEW,
    ACCESS.SITE_EDIT,
    ACCESS.SITE_VIEW,
    ACCESS.CAMERA_EDIT,
    ACCESS.CAMERA_ADD,
    ACCESS.APPLY_SCENARIO,
    ACCESS.EDIT_SCENARIO,
    ACCESS.TOGGLE_SCENARIO,
    ACCESS.SITE_TOGGLE,
    ACCESS.ADD_EDIT_MS_TEAMS_WEBHOOK,
    ACCESS.ADD_EDIT_INTEGRATION,
    ACCESS.EVENT_VALIDATION,
    ACCESS.DOWNLOAD_VIDEO,
    ACCESS.CAMERA_SEVERITY_TOGGLE,
    ACCESS.CAMERA_SEVERITY_EDIT,
    ACCESS.CAMERA_DISABLE,
    ACCESS.SITE_COMPARISON
  ]
};

