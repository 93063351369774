/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import EventFilter from './EventFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventsByDate, getAllEvents } from '../../api/events.api';
import { formatDate } from '../../utils';
import { useEffect, useState, useMemo } from 'react';
import { Pagination, Spinner } from '@shopify/polaris';
import EventsPerDate from './EventsPerDate';
import EventOverlay from '../../components/EventOverlay';
import {
  resetEventSlice,
  setCamera,
  setChosenDateEvents,
  setChosenDateIdx,
  setChosenEventIdx,
  setDoesDateHaveMore,
  setEvents,
  setSelectedAreas,
  setSelectedDates,
  setSelectedReviewType,
  setSelectedScenarioEvents,
  setTag,
  setTimezone,
  setShift,
  setChosenDate
} from '../../store/slices/eventSlice';
import { notification } from 'antd';
import { store } from '../../store/store';
import { useSearchParams } from 'react-router-dom';

const processEventsObject = (eventsObj) => {
  const result = [];
  Object.keys(eventsObj).forEach((date) => {
    eventsObj[date].forEach((event) => {
      result.push(event);
    });
  });

  return result ?? [];
};

const Events = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedCamera,
    selectedTag,
    selectedScenarios,
    selectedDates,
    chosenEventIdx,
    selectedScenarioEvents,
    chosenDateIdx,
    chosenDate,
    selectedReviewType,
    selectedAreas,
    selectedShift
  } = useSelector((state) => state.event);
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  // for pagination of EventsPerDate
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // for navigation in EventOverlay
  const [fromViewMore, setFromViewMore] = useState(false);
  const getAreaOptions = () => {
    const { auth } = store.getState();
    return auth.user?.user?.areas.split(',').map(area => ({
      label: area.trim(),
      value: area.trim()
    })) || [];
  };

  const assignedAreas = useMemo(() => {
    const assignedOptions = getAreaOptions();
    const hasAllAccess = assignedOptions.find(option => option.label === 'All');

    if (selectedAreas.includes('All')) {
      if (hasAllAccess) {
        return undefined;
      }
      return assignedOptions.map(option => option.value);
    }

    return selectedAreas.length > 0 ? selectedAreas : undefined;
  }, [selectedAreas]);

  const {
    data: eventData,
    isLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: [
      'events',
      selectedCamera,
      selectedTag,
      selectedScenarios,
      selectedDates,
      selectedShift,
      selectedScenarioEvents,
      selectedReviewType,
      assignedAreas,
      page
    ],
    queryFn: () =>
      fetchEventsByDate({
        reviewType: selectedReviewType,
        scenarios: selectedScenarios,
        eventNames: selectedScenarioEvents,
        camera: selectedCamera,
        tag: selectedTag,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        areas: assignedAreas,
        shift: selectedShift,
        page
      })
  });

  const { mutate } = useMutation({
    mutationFn: () =>
      fetchEventsByDate({
        eventNames: selectedScenarioEvents,
        camera: selectedCamera,
        tag: selectedTag,
        startDate: formatDate(selectedDates.start),
        endDate: formatDate(selectedDates.end),
        specificDate: chosenDate,
        reviewType: selectedReviewType,
        areas: assignedAreas,
        shift: selectedShift,
        page: currentPage
      }),
    onSuccess: (data) => {
      setPages(data.pages);

      if (data.pages == 0) {
        const params = new URLSearchParams(searchParams);
        params.delete('date');
        params.delete('dateidx');
        dispatch(setChosenDateIdx(null));
        dispatch(setChosenDate(null));
        dispatch(setChosenDateEvents([]));
        setSearchParams(params);
      }

      if (currentPage > 1 && data.pages < currentPage) {
        setCurrentPage(data.pages);
      }
      // console.log(data, "hey sumanth")

      if (Object.keys(data.data).length === 0) {
        dispatch(setChosenDateEvents([]));
      } else dispatch(setChosenDateEvents(data.data));

      // return api.success({ message: 'Events fetched successfully. Please scroll down!' });
    },
    onError: () => {
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDateEvents([]));
      return api.error({ message: 'Unable to fetch events. Please try again later!' });
    }
  });

  useEffect(() => {
    if (chosenDateIdx || chosenEventIdx) {
      mutate();
    } else if (chosenDate) {
      mutate();
    }
  }, [
    mutate,
    currentPage,
    selectedCamera,
    selectedTag,
    selectedScenarioEvents,
    selectedDates,
    selectedReviewType,
    assignedAreas,
    chosenEventIdx,
    chosenDate,
    selectedShift
  ]);

  useEffect(() => {
    if (!isLoading && !isError && eventData) {
      dispatch(setTimezone(eventData.timezone));
      const eventsArr = processEventsObject(eventData.data);
      dispatch(setEvents(eventsArr));
      dispatch(setDoesDateHaveMore(eventData.dateViewMoreBoolean));
    }
  }, [eventData, assignedAreas]);

  useEffect(() => {
    return () => {
      dispatch(resetEventSlice());
    };
  }, [dispatch]);

  // Initialize filters from URL params
  useEffect(() => {
    const idParam = searchParams.get('id');
    const camera = searchParams.get('camera');
    const tag = searchParams.get('tag');
    const reviewType = searchParams.get('reviewType');
    const scenarios = searchParams.get('scenarios')?.split(',');
    const areas = searchParams.get('areas')?.split(',');
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    const shift = searchParams.get('shift');

    if (idParam) dispatch(setChosenEventIdx(idParam));
    if (camera) dispatch(setCamera(camera));
    if (tag) dispatch(setTag(tag));
    if (reviewType) dispatch(setSelectedReviewType(reviewType));
    if (scenarios) dispatch(setSelectedScenarioEvents(scenarios));
    if (areas) dispatch(setSelectedAreas(areas));
    if (startDate && endDate) {
      dispatch(setSelectedDates({ 
        start: new Date(startDate), 
        end: new Date(endDate) 
      }
    ));
    }
    if (shift) {
      dispatch(setShift(shift));
    }
  }, []);
  
  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (selectedShift) {
      params.set('shift', selectedShift);
    } else {
      params.delete('shift');
    }

    if (chosenEventIdx) {
      params.set('id', chosenEventIdx);
    } else {
      params.delete('id');
    }

    if (selectedCamera) {
      params.set('camera', selectedCamera);
    } else {
      params.delete('camera');
    }

    if (selectedTag) {
      params.set('tag', selectedTag);
    } else {
      params.delete('tag');
    }

    if (selectedReviewType) {
      params.set('reviewType', selectedReviewType);
    } else {
      params.delete('reviewType');
    }

    if (selectedScenarioEvents?.length) {
      params.set('scenarios', selectedScenarioEvents.join(','));
    } else {
      params.delete('scenarios');
    }

    if (selectedAreas?.length) {
      params.set('areas', selectedAreas.join(','));
    } else {
      params.delete('areas');
    }

    if (selectedDates?.start && selectedDates?.end) {
      params.set('startDate', selectedDates.start);
      params.set('endDate', selectedDates.end);
    } else {
      params.delete('startDate');
      params.delete('endDate');
    }

    setSearchParams(params);
  }, [
    selectedCamera,
    selectedTag,
    selectedReviewType,
    selectedScenarioEvents,
    selectedAreas,
    selectedDates,
    chosenEventIdx,
    selectedShift
  ]);

  return (
    <>
      {chosenEventIdx ? (
        <EventOverlay refetch={refetch} pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} mutate={mutate} fromViewMore={fromViewMore} />
      ) : (
        <>
          <EventFilter />
          {isLoading ? (
            <div className="h-screen grid place-items-center">
              <Spinner size="large" />
            </div>
          ) : isError ? (
            <div className="h-full grid place-items-center text-center px-4">
              <img
                src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                className="empty-state w-[250px]"
              />
              <h3 className="font-semibold text-lg pb-4">Unable to fetch events</h3>
              <p>
                We were unable to fetch events. Please try again later or contact support if issue
                persists.
              </p>
            </div>
          ) : (
            <div className="px-4 py-4">
              {eventData?.data && Object.keys(eventData.data).length > 0 ? (
                <div>
                  {Object.keys(eventData.data).map((date, idx) => {
                    return (
                      <EventsPerDate
                        key={idx}
                        date={date}
                        events={eventData.data[date]}
                        currentIdx={idx}
                        data={eventData}
                        pages={pages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        contextHolder={contextHolder}
                        mutate={mutate}
                        fromViewMore={fromViewMore}
                        setFromViewMore={setFromViewMore}
                        assignedAreas={assignedAreas}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                      />
                    );
                  })}

                  {chosenDateIdx === null && (
                    <div className="flex justify-center bg-white rounded-md shadow-xl py-3">
                      <Pagination
                        onPrevious={() => {
                          setPage((prev) => prev - 1);
                        }}
                        onNext={() => {
                          setPage((prev) => prev + 1);
                        }}
                        hasNext={eventData.hasMore}
                        hasPrevious={page > 1}
                        label={`Page ${page}`}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="h-full grid place-items-center text-center px-4">
                  <img
                    src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                    className="empty-state w-[250px]"
                  />
                  <h3 className="font-semibold text-lg pb-4">Unable to fetch events</h3>
                  <p>
                    We were unable to fetch events. Please try again later or contact support if
                    issue persists.
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Events;
