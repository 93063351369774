import { AlertTriangle, Edit, Trash2 } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteCamera, updateCamera } from '../api/cameras.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification } from 'antd';
import Switch from '../components/Switch';
import { Button, Spinner, TextField } from '@shopify/polaris';
import { getAppliedScenariosByCameraId, getScenarioByName, getScenarios } from '../api/scenarios.api';
import { useSelector } from 'react-redux';
import { translateEvent } from '../utils';
import useAccessControl from '../hooks/useAccessControl';
import { ACCESS } from '../data/userRoles';

const EditCameraModal = ({
  camera,
  editCameraError,
  invalidFieldsError,
  handleOk,
  handleCancel,
  isPending,
  open,
  isSynologyIntegrationEnabled
}) => {
  const { chosenSite } = useSelector((state) => state.auth);

  const { hasAccess: hasCameraSeverityEditAccess, isLoading: isCameraSeverityEditAccessLoading } = useAccessControl(ACCESS.CAMERA_SEVERITY_EDIT);
  const { hasAccess: canDisableCamera, isLoading: isDisableCameraAccessLoading } = useAccessControl(ACCESS.CAMERA_DISABLE);

  const [scenarioMap, setScenarioMap] = useState({});
  const [editCameraForm, setEditCameraForm] = useState({
    cameraName: camera?.name ?? '',
    area: camera?.area ?? '',
    description: camera?.description ?? '',
    cameraSource: camera?.rtsp ?? '',
    token: camera?.token ?? null,
    thumbnailUrl: camera.thumbnail_url ?? null,
    disable: camera.disable ?? false,
    specific_severity_metadata: camera.specific_severity_metadata ?? {
      critical: [],
      moderate: [],
      low: []
    }
  });

  const validateAndSetEditCameraForm = (newCameraForm) => {
    // add character limit of 100 to cameraName, area, description
    if (
      newCameraForm.cameraName.length > 100 ||
      newCameraForm.area.length > 100 ||
      newCameraForm.description.length > 255
    ) {
      return;
    }

    setEditCameraForm(newCameraForm);
  };

  const isEventSelectedInOtherSeverity = (event, currentSeverity) => {
    const severities = ['critical', 'moderate', 'low'];
    return severities
      .filter(sev => sev !== currentSeverity)
      .some(sev => editCameraForm.specific_severity_metadata[sev].includes(event));
  };


  
  const {
    data: availableScenarios
  } = useQuery({
    queryKey: ['allScenarios'],
    queryFn: getScenarios,
    enabled: open
  });

  useEffect(() => {
    // console.log(availableScenarios);
    if (availableScenarios?.scenarios) {
      const buildScenarioMap = async () => {
        const scenarioMap = {};
        for (const scenario of availableScenarios.scenarios) {
          if (!scenarioMap[scenario.name]) {
            const scenarioDetails = await getScenarioByName(scenario.name);
            // console.log(`Events for ${scenario.name}:`, Object.values(scenarioDetails?.events).map((event) => event.label));
            scenarioMap[scenario.name] = scenario.name === 'worker-health-and-safety' ? Object.values(scenarioDetails?.events).map((event) => `No ${event.label}`) : Object.values(scenarioDetails?.events).map((event) => event.label);
          }
        }
        // console.log('Scenario map:', scenarioMap);
        setScenarioMap(scenarioMap);
      };
      
      buildScenarioMap();
    }
  }, [availableScenarios]);

  return (
    <Modal
      title="Edit Camera"
      open={open}
      maskClosable={false}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      centered={!!chosenSite.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading }
      width={chosenSite.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading  ? 900 : 600}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          <Button
            variant="primary"
            onClick={() => {
              handleOk({ name: camera.name, payload: editCameraForm });
            }}
          >
            <div className="flex items-center">
              Submit
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}
    >
      <form>
        <div className="my-4">
          <TextField
            disabled
            value={editCameraForm.cameraName}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, cameraName: e })}
            label={
              <span>
                Camera Name <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Visionify-Camera-1"
          />
        </div>
        <div className="my-4">
          <TextField
            value={editCameraForm.area}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, area: e })}
            label={
              <span>
                Area <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Laundry Entrance"
          />
        </div>
        <div className="my-4">
          <TextField
            value={editCameraForm.description}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, description: e })}
            label={
              <span>
                Description <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Laundry Entrance Camera"
          />
        </div>
        <div className="my-4">
          <TextField
            disabled
            value={editCameraForm.cameraSource}
            label={
              <span>
                Camera Source <span className="text-red-500">*</span>
              </span>
            }
            placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
          />
        </div>
        {canDisableCamera && !isDisableCameraAccessLoading && (
          <div className="my-4 flex items-center justify-between border-t border-gray-200 pt-4 mt-4">
            <div className="flex items-center">
              <span className="font-medium">Disable Camera</span>
              <p className="text-sm text-gray-500 ml-2">
                Toggle to enable/disable camera feed
              </p>
            </div>
            <Switch
              id={camera.name}
              onColor="green"
              isOn={editCameraForm.disable}
              handleToggle={() => {
                validateAndSetEditCameraForm({
                  ...editCameraForm,
                  disable: !editCameraForm.disable
                });
              }}
            />
          </div>
        )}
        {isSynologyIntegrationEnabled && (
          <div className="my-4">
            <TextField
              value={editCameraForm.token}
              onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, token: e })}
              label={<span>Synology Token</span>}
              placeholder="Token from Synology Webhook Action Rule"
            />
          </div>
        )}
        {/* <div className="my-4">
          <TextField
            value={editCameraForm.thumbnailUrl}
            onChange={(e) => setEditCameraForm({ ...editCameraForm, thumbnailUrl: e })}
            label={
              <span>
                Camera Thumbnail URL<span className="text-red-500">*</span>
              </span>
            }
            placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
          />
        </div> */}
        {invalidFieldsError && (
          <span className="text-red-500 text-sm mt-2">* Please fill all the required fields.</span>
        )}
        {editCameraError && (
          <span className="text-red-500 text-sm mt-2">
            * Unable to add camera. Please try again later.
          </span>
        )}

        {chosenSite?.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading && (
          <div className="border-t border-gray-200 pt-4 mt-4">
            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-900">Event Specific Severity</h3>
              <p className="mt-1 text-sm text-gray-500">
                Configure severity levels for specific events
              </p>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div className="bg-red-50 p-4 rounded-lg border border-red-100">
                <h4 className="font-medium text-red-600 mb-2">Critical</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`critical-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'critical') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={editCameraForm.specific_severity_metadata.critical.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'critical')}
                          onChange={(e) => {
                            const updatedCritical = e.target.checked
                              ? [...editCameraForm.specific_severity_metadata.critical, event]
                              : editCameraForm.specific_severity_metadata.critical.filter(e => e !== event);
                            
                            setEditCameraForm({
                              ...editCameraForm,
                              specific_severity_metadata: {
                                ...editCameraForm.specific_severity_metadata,
                                critical: updatedCritical,
                                moderate: editCameraForm.specific_severity_metadata.moderate.filter(e => e !== event),
                                low: editCameraForm.specific_severity_metadata.low.filter(e => e !== event)
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-red-600 focus:ring-red-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>

              <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-100">
                <h4 className="font-medium text-yellow-600 mb-2">Moderate</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`moderate-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'moderate') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={editCameraForm.specific_severity_metadata.moderate.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'moderate')}
                          onChange={(e) => {
                            const updatedModerate = e.target.checked
                              ? [...editCameraForm.specific_severity_metadata.moderate, event]
                              : editCameraForm.specific_severity_metadata.moderate.filter(e => e !== event);
                            
                            setEditCameraForm({
                              ...editCameraForm,
                              specific_severity_metadata: {
                                ...editCameraForm.specific_severity_metadata,
                                critical: editCameraForm.specific_severity_metadata.critical.filter(e => e !== event),
                                moderate: updatedModerate,
                                low: editCameraForm.specific_severity_metadata.low.filter(e => e !== event)
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>

              <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                <h4 className="font-medium text-green-600 mb-2">Low</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`low-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'low') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={editCameraForm.specific_severity_metadata.low.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'low')}
                          onChange={(e) => {
                            const updatedLow = e.target.checked
                              ? [...editCameraForm.specific_severity_metadata.low, event]
                              : editCameraForm.specific_severity_metadata.low.filter(e => e !== event);
                            
                            setEditCameraForm({
                              ...editCameraForm,
                              specific_severity_metadata: {
                                ...editCameraForm.specific_severity_metadata,
                                critical: editCameraForm.specific_severity_metadata.critical.filter(e => e !== event),
                                moderate: editCameraForm.specific_severity_metadata.moderate.filter(e => e !== event),
                                low: updatedLow
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-green-600 focus:ring-green-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

const EditCamera = (props) => {
  return (
    <div {...props}>
      <Edit size={20} color="#000" />
    </div>
  );
};

const DeleteCamera = (props) => {
  return (
    <div {...props}>
      <Trash2 size={20} color="red" />
    </div>
  );
};

const CameraHLS = ({ camera, refetch, isSynologyIntegrationEnabled, hasCameraEditAccess }) => {
  const [api, contextHolder] = notification.useNotification();
  const [editCameraError, setEditCameraError] = useState(false);
  const [invalidFieldsError, setInvalidFieldsError] = useState(false);

  const { search } = useLocation();

  const [showEditModal, setShowEditModal] = useState(false);
  const [refetchLoader, setRefetchLoader] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    mutate: initiateDelete,
    isPending: isDeletionPending,
    isError: isDeleteError
  } = useMutation({
    mutationFn: () => deleteCamera(camera.name),
    onSuccess: () => {
      setShowDeleteConfirmation(false);
      refetch();
    }
  });

  const { mutate: initiateEdit, isPending: isEditPending } = useMutation({
    mutationFn: (camera) => {
      return updateCamera(camera.name, camera.payload);
    },
    onSuccess: async () => {
      setShowEditModal(false);
      setRefetchLoader(true);
      refetch();
      setRefetchLoader(false);
    },
    onError: () => {
      setEditCameraError(true);
    }
  });

  const handleDelete = useCallback(async () => {
    initiateDelete();
  }, [camera]);

  const navigateTo = useNavigate();

  const { mutate: doesCameraHaveAppliedScenarios } = useMutation({
    mutationFn: getAppliedScenariosByCameraId,
    onSuccess: async (resp) => {
      const searchObject = Object.fromEntries(new URLSearchParams(search));
      if (resp.data.length > 0) {
        const found = resp.data.find((sc) => {
          if (`${sc.scenario_name}` === searchObject.scenario) {
            return true;
          }
          return false;
        });

        if (found) {
          return navigateTo(
            `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}&appliedScenarioId=${found.id}`
          );
        }

        return navigateTo(
          `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}`
        );
      } else {
        return navigateTo(
          `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}`
        );
      }
    },
    onError: () => {
      return api.error({
        message: 'Unable to fetch applied scenarios. Please try again later.',
        placement: 'topRight'
      });
    }
  });

  const handleNavigation = useCallback(() => {
    const searchObject = Object.fromEntries(new URLSearchParams(search));
    if (Object.keys(searchObject).length > 0) {
      return doesCameraHaveAppliedScenarios(camera.id);
    }
    return navigateTo(`/cameras/${camera.id}`, { state: { camera } });
  }, []);

  const handleEdit = useCallback(
    async (camera) => {
      const { payload } = camera;
      if (
        !payload.area ||
        !payload.description ||
        (isSynologyIntegrationEnabled && !payload.token)
      ) {
        setInvalidFieldsError(true);
        return;
      }
      setInvalidFieldsError(false);
      setEditCameraError(false);
      initiateEdit(camera);
    },
    [camera]
  );

  return (
    <div className="relative h-auto w-fit rounded-t-md shadow-sm border">
      {contextHolder}
      {refetchLoader ? (
        <div onClick={handleNavigation}>
          <div className="h-[214px] w-[320px] bg-black rounded-t-md hover:opacity-50 cursor-pointer" />
          <div
            className="absolute top-1/2 left-1/2 transform text-white"
            style={{
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Spinner size="large" />
          </div>
        </div>
      ) : camera?.thumbnail_url ? (
        <div
          className="h-[214px] w-[320px] hover:opacity-50 cursor-pointer"
          onClick={handleNavigation}
        >
          <img src={camera.thumbnail_url} alt="camera" className="w-full h-full object-cover" />
        </div>
      ) : (
        <div onClick={handleNavigation}>
          <div className="h-[214px] w-[320px] bg-black rounded-t-md hover:opacity-50 cursor-pointer" />
          <div
            className="absolute top-1/2 left-1/2 transform text-white"
            style={{
              transform: 'translate(-50%, -50%)'
            }}
          >
            No Preview Available
          </div>
        </div>
      )}
      <div className="flex justify-between text-[#242424] text-sm font-semibold bg-white px-2 py-2 w-full h-auto rounded-b-md shadow-md">
        <span className="hover:underline cursor-pointer" onClick={handleNavigation}>
          {camera.name.length > 20 ? camera.name.substring(0, 20) + '...' : camera.name}
        </span>
        <div className="flex">
          <EditCamera
            className={`mr-2 cursor-pointer ${hasCameraEditAccess ? '' : 'hidden'}`}
            onClick={() => {
              setShowEditModal(true);
            }}
          />
          <DeleteCamera
            className={`cursor-pointer ${hasCameraEditAccess ? '' : 'hidden'}`}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        show={showDeleteConfirmation}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        handleDelete={handleDelete}
        prompt={
          <div className="flex items-center">
            <AlertTriangle size={24} color="red" />
            <span className="text-md ml-2">Are you sure you want to delete {camera.name}?</span>
          </div>
        }
        isDeletionPending={isDeletionPending}
        isDeleteError={isDeleteError}
        errorMessage="* Unable to delete camera. Please try again."
      />
      <EditCameraModal
        camera={camera}
        editCameraError={editCameraError}
        invalidFieldsError={invalidFieldsError}
        handleOk={handleEdit}
        handleCancel={() => setShowEditModal(false)}
        open={showEditModal}
        isPending={isEditPending}
        isSynologyIntegrationEnabled={isSynologyIntegrationEnabled}
      />
    </div>
  );
};
export default CameraHLS;
