import { io } from "socket.io-client";
let baseUrl = new URL(window.location.href).origin;

let BASE_URL = process.env.REACT_APP_BASE_URL || baseUrl;


export const socketConnection = () => {
     

    const socket_client = io(BASE_URL, {
        path: '/api/socket.io',  // Explicit socket.io path
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 500,
        maxReconnectionAttempts: Infinity,
        transports: ['websocket', 'polling'],
        withCredentials: true,
        extraHeaders: {
            "Access-Control-Allow-Origin": "*"
        }
    });

    // Connection event handlers
    socket_client.on('connect', () => {
        // console.log('Socket Connected ✅', {
        //     id: socket_client.id,
        //     connected: socket_client.connected
        // });
        console.log('Socket Connected ✅')
    });

    socket_client.on('disconnect', (reason) => {
        console.log('Socket Disconnected ❌', { reason });
    });

    socket_client.on('connect_error', (error) => {
        console.error('Connection Error 🔴', {
            message: error.message,
            description: error.description,
            url: BASE_URL,
            path: '/api/socket.io',
            readyState: socket_client.readyState,
            transport: socket_client.io.engine.transport.name
        });
    });

    // Check current connection
    if (socket_client.connected) {
        console.log('Socket already connected');
    } else {
        console.log('Socket attempting to connect...');
    }

    return socket_client
}