import { Card } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import BarChart from '../../components/charts/Bar';

const BarGraph = ({ isLoading, data, reference, timeRange, startDate, endDate }) => {

  const [dateRange, setDateRange] = useState({
    start: null,
    end: null
  });

  useEffect(() => {
    const computeDateRange = () => {
      if (timeRange === 'CUSTOM_DATE_RANGE') {
        return {
          start: startDate,
          end: endDate
        };
      }
  
      const end = new Date();
      const start = new Date();
  
      switch (timeRange) {
        case 'LAST_24_HOURS':
          start.setHours(start.getHours() - 24);
          break;
        case 'LAST_7_DAYS':
          start.setDate(start.getDate() - 7);
          break;
        case 'LAST_30_DAYS':
          start.setDate(start.getDate() - 30);
          break;
        case 'LAST_60_DAYS':
          start.setDate(start.getDate() - 60);
          break;
        case 'LAST_YEAR':
          start.setFullYear(start.getFullYear() - 1);
          break;
        default:
          start.setHours(start.getHours() - 24);
      }
  
      return {
        start,
        end
      };
    };
  
    const dateRange = computeDateRange();
    setDateRange(dateRange);
  }, [timeRange, startDate, endDate]);

  
  return (
    <>
      <div className="mb-4">
        <Card>
          <div className="statistics pt-4 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-4 gap-5">
            <div className="xl:col-span-4">
              <div>
                <BarChart reference={reference} isLoading={isLoading} data={data} hasClickHandler={true} dateRange={dateRange} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BarGraph;
