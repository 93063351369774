/* eslint-disable no-unused-vars */
import { Card, Text, VideoThumbnail } from '@shopify/polaris';
import Spacer from '../../components/Spacer';
import { formatTime } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setChosenDate, setChosenDateIdx, setChosenEventIdx } from '../../store/slices/eventSlice';

const CardBadge = ({ event }) => {
  if (!event.is_reviewed) {
    return (
      <>
        <div className="notreviewd w-2 h-2 rounded-full bg-[#8A8A8A]"></div>
        <p>Not Reviewed</p>
      </>
    );
  }
  if (event.is_reviewed && (event.is_accurate === true || event.is_accurate === false)) {
    return (
      <>
        <div className={`w-2 h-2 rounded-full bg-[${event.is_accurate === true ? '#45A636' : '#E51C00'}]`}></div>
        <p>{event.is_accurate === true ? 'Accurate' : 'Inaccurate'}</p>
      </>
    );
  }

  return (
    <>
      <div className="Inaccurate w-2 h-2 rounded-full bg-[#3b82f6]"></div>
      <p>Reviewed</p>
    </>
  );
};

const EventCard = ({ event, currentIdx, date, setFromViewMore }) => {
  const {
    user: { timezone },
  } = useSelector((state) => state.auth);
  const {
    chosenDate,
  } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const setEventIdx = () => {
    if(setFromViewMore) setFromViewMore(!!chosenDate);
    dispatch(setChosenDateIdx(currentIdx));
    dispatch(setChosenDate(date));
    dispatch(setChosenEventIdx(event.id));
    return;
  };

  return (
    <div className="cursor-pointer hover:opacity-75" onClick={setEventIdx}>
      <Card>
        <VideoThumbnail thumbnailUrl={event.thumbnail_path} />
        <div className="absolute top-5 right-5 bg-white rounded-lg p-2 text-[#303030] flex items-center gap-2 text-[14px]">
          <CardBadge event={event} />
        </div>
        <div className="flex items-center justify-between py-4 px-4">
          <div>
            <Text as="p" variant="headingMd" fontWeight="semibold">
              {event.camera_name}
            </Text>
            <Spacer height={'5px'} />
            <div className="flex gap-2 flex-wrap">
              <span className="inline-flex items-center rounded-md bg-[#DBF2FF] px-2 py-1 text-xs text-[#003A5A]">
                {event.scenario_name
                  .split('-')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </span>
            </div>
          </div>
          <p className="text-[14px]">{formatTime(event.created_at, timezone)}</p>
        </div>
      </Card>
    </div>
  );
};

export default EventCard;
