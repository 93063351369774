import { useEffect, useState } from 'react';
import MultiSwitch from '../../components/MultiSwitch';
import { getCameras, addCamera } from '../../api/cameras.api';
import CameraHLS from '../../components/Camera-HLS';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Spinner, Button, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, notification } from 'antd';
import { isProtocolValid } from '../../utils';
import {
  resetScenarioFormData,
  resetScenarioPerCamFormData
} from '../../store/slices/scenarioSlice';
import CameraListCard from '../../components/CameraListCard';
import { getIntegrations } from '../../api/integrations.api';
import useAccessControl from '../../hooks/useAccessControl';
import { ACCESS } from '../../data/userRoles';
import { getScenarioByName, getScenarios } from '../../api/scenarios.api';
import { translateEvent } from '../../utils';

const Cameras = () => {
  const dispatch = useDispatch();
  const [viewIdx, setViewIdx] = useState(0);
  const [scenarioMap, setScenarioMap] = useState({});

  const { chosenSite } = useSelector((state) => state.auth);

  const [api, contextHolder] = notification.useNotification();

  // Access Control for Cameras
  const { hasAccess: hasCameraAddAccess, isLoading: isCameraAddAccessLoading } = useAccessControl(ACCESS.CAMERA_ADD);
  const { hasAccess: hasCameraEditAccess, isLoading: isCameraEditAccessLoading } = useAccessControl(ACCESS.CAMERA_EDIT);
  const { hasAccess: hasCameraSeverityEditAccess, isLoading: isCameraSeverityEditAccessLoading } = useAccessControl(ACCESS.CAMERA_SEVERITY_EDIT);

  useEffect(() => {
    dispatch(resetScenarioFormData());
    dispatch(resetScenarioPerCamFormData());
  }, []);

  const changeView = (view) => {
    if (view === 'Grid') {
      setViewIdx(0);
    } else {
      setViewIdx(1);
    }
  };

  const [createCameraForm, setCreateCameraForm] = useState({
    cameraName: '',
    area: '',
    description: '',
    cameraSource: '',
    token: null,
    thumbnailUrl: null,
    specific_severity_metadata: {
      critical: [],
      moderate: [],
      low: []
    }
  });

  const validateAndSetCreateCameraForm = (newCameraForm) => {
    // add character limit of 100 to cameraName, area, description
    if (
      newCameraForm.cameraName.length > 100 ||
      newCameraForm.area.length > 100 ||
      newCameraForm.description.length > 255
    ) {
      return;
    }

    const validPattern = /^[a-zA-Z0-9-]*$/;
    if (!validPattern.test(newCameraForm.cameraName)) {
      setInvalidCameraName(true);
      return;
    }

    setInvalidCameraName(false);
    setCreateCameraForm(newCameraForm);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: addCamera,
    onSuccess: () => {
      api.success({ message: 'Camera added successfully' });
      setOpen(false);
      resetForm();
      refetch();
    },
    onError: (error) => {
      setAddCameraError(true);
      const errorMessage = error.message || 'An unexpected error occurred.';
      api.error({ message: errorMessage });
    },
    retryDelay: 1000
  });

  const [open, setOpen] = useState(false);
  const [addCameraError, setAddCameraError] = useState(false);
  const [invalidFieldsError, setInvalidFieldsError] = useState(false);
  const [invalidSourceError, setInvalidSourceError] = useState(false);
  const [invalidCameraName, setInvalidCameraName] = useState(false);

  const resetForm = () => {
    setCreateCameraForm({
      cameraName: '',
      area: '',
      description: '',
      cameraSource: '',
      token: null,
      thumbnailUrl: null,
      specific_severity_metadata: {
        critical: [],
        moderate: [],
        low: []
      }
    });
    setAddCameraError(false);
    setInvalidCameraName(false);
    setInvalidFieldsError(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (
      !createCameraForm.cameraName ||
      !createCameraForm.area ||
      !createCameraForm.description ||
      !createCameraForm.cameraSource ||
      (isSynologyIntegrationEnabled && !createCameraForm.token)
    ) {
      setInvalidCameraName(false);
      setInvalidSourceError(false);
      return setInvalidFieldsError(true);
    }
    if (!isProtocolValid(createCameraForm.cameraSource)) {
      setInvalidCameraName(false);
      setInvalidFieldsError(false);
      return setInvalidSourceError(true);
    }
    setInvalidFieldsError(false);
    setInvalidSourceError(false);
    // console.log(createCameraForm);
    mutate(createCameraForm);
  };

  const handleCancel = () => {
    setOpen(false);
    resetForm();
  };

  const {
    data: cameraData,
    isLoading: cameraLoading,
    refetch
  } = useQuery({
    queryKey: ['cameras'],
    queryFn: getCameras
  });

  const { data: integrationsData, isLoading: integrationsLoading } = useQuery({
    queryKey: ['integrations'],
    queryFn: getIntegrations
  });

  const {
    data: availableScenarios
  } = useQuery({
    queryKey: ['allScenarios'],
    queryFn: getScenarios
  });

  useEffect(() => {
    // console.log(availableScenarios);
    if (availableScenarios?.scenarios) {
      const buildScenarioMap = async () => {
        const scenarioMap = {};
        for (const scenario of availableScenarios.scenarios) {
          if (!scenarioMap[scenario.name]) {
            const scenarioDetails = await getScenarioByName(scenario.name);
            // console.log(`Events for ${scenario.name}:`, Object.values(scenarioDetails?.events).map((event) => event.label));
            scenarioMap[scenario.name] = scenario.name === 'worker-health-and-safety' ? Object.values(scenarioDetails?.events).map((event) => `No ${event.label}`) : Object.values(scenarioDetails?.events).map((event) => event.label);
          }
        }
        // console.log('Scenario map:', scenarioMap);
        setScenarioMap(scenarioMap);
      };
      
      buildScenarioMap();
    }
  }, [availableScenarios]);

  const isSynologyIntegrationEnabled = integrationsData?.some(
    (integration) => integration.name === 'Synology' && integration.status
  );

  // Helper function to check if event is selected in any other severity
  const isEventSelectedInOtherSeverity = (event, currentSeverity) => {
    const severities = ['critical', 'moderate', 'low'];
    return severities
      .filter(sev => sev !== currentSeverity)
      .some(sev => createCameraForm.specific_severity_metadata[sev].includes(event));
  };

  if (cameraLoading || integrationsLoading || !scenarioMap) {
    return (
      <div className="grid place-items-center h-[calc(100vh-100px)] w-full">
        <Spinner size="large" color="teal" accessibilityLabel="Loading Cameras" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {contextHolder}
      <div className="flex items-center justify-between px-3 py-2 bg-white shadow-md mb-2">
        <div className="title smallbtn flex items-center justify-start gap-3">
          <h3 className="text-black text-lg font-semibold">Cameras</h3>
        </div>
        <span className="flex items-center justify-between gap-3 px-2">
          {(!isCameraAddAccessLoading && hasCameraAddAccess) && (<button className='bg-white border border-[#cecfd3] text-[#303030] rounded-sm px-2 py-1 transition duration-300 ease-in-out hover:bg-[#e5e5e5]' aria-label="Add Camera"
            onClick={showModal}>
            + Add Camera
          </button>)}
          <MultiSwitch
            options={['Grid', 'List']}
            selectedOption={viewIdx}
            bgColors={['bg-black/10', 'bg-black/10']}
            textColors={['text-black', 'text-black']}
            onSelect={changeView}
            fullWidth={false}
          />
        </span>
      </div>
      <div>
        {viewIdx === 0 ? (
          cameraData && cameraData.data && cameraData.data.length === 0 ? (
            <div className="grid place-items-center h-[60vh]">
              <p>No Cameras Found</p>
            </div>
          ) : (
            <div className="mx-10">
              <div className="flex flex-wrap gap-x-4 gap-y-8 justify-center">
                {cameraData.data.map((camera) => (
                  <CameraHLS
                    key={camera.id}
                    camera={camera}
                    refetch={refetch}
                    isSynologyIntegrationEnabled={isSynologyIntegrationEnabled}
                    hasCameraEditAccess={(!isCameraEditAccessLoading && hasCameraEditAccess)}
                  />
                ))}
              </div>
            </div>
          )
        ) : cameraLoading ? (
          <div className="grid place-items-center h-[calc(100vh-100px)] w-full">
            <Spinner size="large" color="teal" accessibilityLabel="Loading Cameras" />
          </div>
        ) : cameraData && cameraData.data && cameraData.data.length === 0 ? (
          <div className="grid place-items-center h-[60vh]">
            <p>No Cameras Found</p>
          </div>
        ) : (
          <div className="mx-10">
            {/** 3-column layout where each camera occupies one row */}
            {cameraData.data.map((camera) => (
              <div className="my-8" key={camera.id}>
                <CameraListCard camera={camera} />
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal
        title="Add Camera"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        centered={!!chosenSite.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading }
        width={chosenSite.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading ? 900 : 600}
        maskClosable={false}
        footer={[
          <div className="inline mr-2" key="back">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk}>
              <div className="flex items-center">
                Submit
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        <form>
          <div className="my-4">
            <TextField
              value={createCameraForm.cameraName}
              onChange={(e) => {
                validateAndSetCreateCameraForm({ ...createCameraForm, cameraName: e });
              }}
              label={
                <span>
                  Camera Name <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Visionify-Camera-1"
            />
          </div>
          {invalidCameraName && (
            <span className="text-red-500 text-xs mt-2">
              {' '}
              * Name cannot have any special characters.
            </span>
          )}
          <div className="my-4">
            <TextField
              value={createCameraForm.area}
              onChange={(e) => validateAndSetCreateCameraForm({ ...createCameraForm, area: e })}
              label={
                <span>
                  Area <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Laundry Entrance"
            />
          </div>
          <div className="my-4">
            <TextField
              value={createCameraForm.description}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, description: e })
              }
              label={
                <span>
                  Description <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Laundry Entrance Camera"
            />
          </div>
          <div className="my-4">
            <TextField
              value={createCameraForm.cameraSource}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, cameraSource: e.trim() })
              }
              label={
                <span>
                  Camera Source <span className="text-red-500">*</span>
                </span>
              }
              placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
            />
          </div>
          {isSynologyIntegrationEnabled && (
            <div className="my-4">
              <TextField
                value={createCameraForm.token}
                onChange={(e) => validateAndSetCreateCameraForm({ ...createCameraForm, token: e })}
                label={<span>Synology Token</span>}
                placeholder="User Defined Token"
              />
            </div>
          )}
          {/* <div className="my-4">
            <TextField
              value={createCameraForm.thumbnailUrl}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, thumbnailUrl: e })
              }
              label={
                <span>
                  Camera Thumbnail URL<span className="text-red-500">*</span>
                </span>
              }
              placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
            />
          </div> */}
          {invalidFieldsError && (
            <span className="text-red-500 text-sm mt-2">Please fill all the required fields.</span>
          )}
          {invalidSourceError && (
            <span className="text-red-500 text-sm mt-2">
              Please check the camera source URL. We only accept http, https, rtsp, rtmp, and rtmp
              protocols.
            </span>
          )}
          {addCameraError && (
            <span className="text-red-500 text-sm mt-2">
              * Unable to add camera. Please try again later.
            </span>
          )}

          {
            chosenSite.camera_specific_severity && hasCameraSeverityEditAccess && !isCameraSeverityEditAccessLoading && (
              <div className="border-t border-gray-200 pt-4 mt-4">
                <div className="mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Event Specific Severity</h3>
              <p className="mt-1 text-sm text-gray-500">
                Configure severity levels for specific events
              </p>
            </div>

            <div className="grid grid-cols-3 gap-4">
              {/* Critical Column */}
              <div className="bg-red-50 p-4 rounded-lg border border-red-100">
                <h4 className="font-medium text-red-600 mb-2">Critical</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`critical-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'critical') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={createCameraForm.specific_severity_metadata.critical.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'critical')}
                          onChange={(e) => {
                            const updatedCritical = e.target.checked
                              ? [...createCameraForm.specific_severity_metadata.critical, event]
                              : createCameraForm.specific_severity_metadata.critical.filter(e => e !== event);
                            
                            setCreateCameraForm({
                              ...createCameraForm,
                              specific_severity_metadata: {
                                ...createCameraForm.specific_severity_metadata,
                                critical: updatedCritical,
                                moderate: createCameraForm.specific_severity_metadata.moderate.filter(e => e !== event),
                                low: createCameraForm.specific_severity_metadata.low.filter(e => e !== event)
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-red-600 focus:ring-red-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>

              {/* Moderate Column */}
              <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-100">
                <h4 className="font-medium text-yellow-600 mb-2">Moderate</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`moderate-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'moderate') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={createCameraForm.specific_severity_metadata.moderate.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'moderate')}
                          onChange={(e) => {
                            const updatedModerate = e.target.checked
                              ? [...createCameraForm.specific_severity_metadata.moderate, event]
                              : createCameraForm.specific_severity_metadata.moderate.filter(e => e !== event);
                            
                            setCreateCameraForm({
                              ...createCameraForm,
                              specific_severity_metadata: {
                                ...createCameraForm.specific_severity_metadata,
                                critical: createCameraForm.specific_severity_metadata.critical.filter(e => e !== event),
                                moderate: updatedModerate,
                                low: createCameraForm.specific_severity_metadata.low.filter(e => e !== event)
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-yellow-600 focus:ring-yellow-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>

              {/* Low Column */}
              <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                <h4 className="font-medium text-green-600 mb-2">Low</h4>
                <div className="space-y-2 max-h-[300px] overflow-y-auto pr-2">
                  {Object.entries(scenarioMap).map(([scenario, events]) => (
                    events.map(event => (
                      <label 
                        key={`low-${event}-${scenario}`} 
                        className={`flex items-center space-x-2 p-1 ${
                          isEventSelectedInOtherSeverity(event, 'low') ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={createCameraForm.specific_severity_metadata.low.includes(event)}
                          disabled={isEventSelectedInOtherSeverity(event, 'low')}
                          onChange={(e) => {
                            const updatedLow = e.target.checked
                              ? [...createCameraForm.specific_severity_metadata.low, event]
                              : createCameraForm.specific_severity_metadata.low.filter(e => e !== event);
                            
                            setCreateCameraForm({
                              ...createCameraForm,
                              specific_severity_metadata: {
                                ...createCameraForm.specific_severity_metadata,
                                critical: createCameraForm.specific_severity_metadata.critical.filter(e => e !== event),
                                moderate: createCameraForm.specific_severity_metadata.moderate.filter(e => e !== event),
                                low: updatedLow
                              }
                            });
                          }}
                          className="rounded border-gray-300 text-green-600 focus:ring-green-500 disabled:bg-gray-200"
                        />
                        <span className="text-sm text-gray-700">{translateEvent(event.toLowerCase()).charAt(0).toUpperCase() + translateEvent(event.toLowerCase()).slice(1)}</span>
                      </label>
                    ))
                  ))}
                </div>
              </div>
                </div>
              </div>
            )
          }
        </form>
      </Modal>
    </div>
  );
};

export default Cameras;
