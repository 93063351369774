import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ROLES_ACCESS } from '../data/userRoles';
/**
 * Custom hook to check and manage user role-based access control
 * @param {string} access - The access required for the user
 * @returns {Object} Object containing access control state and helper functions
 */
const useAccessControl = (access) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const { roles } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    const checkUserRole = async () => {
      try {
        // Get user role from your auth system
        // This is a placeholder - replace with actual role fetching logic
        if(user && roles) {
            const currentUserRole = roles.find((role) => role.id === user.role_id)?.name;
            setUserRole(currentUserRole);
            const hasAccess = ROLES_ACCESS[currentUserRole]?.includes(access);
            setHasAccess(hasAccess);
        }
      } catch (error) {
        // Debug Log
        console.error('Error checking user role:', error);
        setHasAccess(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserRole();
  }, [user, roles]);

  return {
    hasAccess,
    isLoading,
    userRole,
    checkPermission: (permission) => hasAccess && userRole === permission
  };
};

export default useAccessControl;
