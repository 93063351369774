/* eslint-disable */
import { Button, Divider, Icon } from '@shopify/polaris';
import EventCard from './EventCard';
import Spacer from '../../components/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetEventSlice,
  setChosenDate,
  setChosenDateEvents,
  setChosenDateIdx
} from '../../store/slices/eventSlice';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { Pagination, Spinner } from '@shopify/polaris';

// Define filter conditions as an array for better extensibility
// const FILTER_CONDITIONS = [
//   // Hide recent events with hil_review = false
//   {
//     id: 'hideRecentEvents',
//     enabled: true,
//     timeWindow: 60, // minutes
//     field: 'hil_review',
//     value: false
//   },
//   // Add more filter conditions here as needed
//   // Example:
//   // {
//   //   id: 'hideLowSeverityEvents',
//   //   enabled: false,
//   //   field: 'severity',
//   //   value: 'low',
//   //   description: 'Hide events with low severity'
//   // }
// ];

const EventsPerDate = ({ events, date, currentIdx, data: eventData, pages, currentPage, setCurrentPage, contextHolder, mutate, fromViewMore, setFromViewMore, assignedAreas, searchParams, setSearchParams }) => {
  const dispatch = useDispatch();
  const {
    chosenDateIdx,
    doesDateHaveMore,
    chosenDateEvents,
    chosenDate,
  } = useSelector((state) => state.event);

  const onViewMore = () => {
    const params = new URLSearchParams(searchParams);
    
    params.set('page', '1');
    if (chosenDateIdx !== null) {
      params.delete('date');
      params.delete('dateidx');
      dispatch(setChosenDateIdx(null));
      dispatch(setChosenDate(null));
      dispatch(setChosenDateEvents([]));
      // dispatch(resetEventSlice());
    } else {  
      params.set('date', date);
      params.set('dateidx', currentIdx);
      dispatch(setChosenDateIdx(currentIdx));
      dispatch(setChosenDate(date));
      mutate();
    }

    setSearchParams(params);
  };

  const updatePagination = (newPage) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', newPage.toString());
    setSearchParams(params);
    setCurrentPage(newPage);
    mutate();
  };

  useEffect(() => {
    if (searchParams.get('date') && searchParams.get('dateidx')) {
      dispatch(setChosenDate(searchParams.get('date')));
      dispatch(setChosenDateIdx(searchParams.get('dateidx')));
    }

    if (searchParams.get('page')) {
      setCurrentPage(parseInt(searchParams.get('page')));
    }
  }, [searchParams])

  // Update the filter function to work with the array of conditions  ---- HAVE TO RECONFIGURE THE API RESPONSE.
  // const filterEvents = (eventsToFilter) => {
  //   // If there are no events, return the original array
  //   if (!eventsToFilter || !Array.isArray(eventsToFilter)) {
  //     return eventsToFilter;
  //   }
    
  //   // Apply the filters
  //   return eventsToFilter.filter(event => {
  //     // Check each enabled filter condition
  //     for (const condition of FILTER_CONDITIONS) {
  //       if (!condition.enabled) continue;
        
  //       // Handle time-based filters
  //       if (condition.id === 'hideRecentEvents') {
  //         const { timeWindow, field, value } = condition;
          
  //         // If the event matches the field value that should be filtered
  //         if (event[field] === value) {
  //           // Check if the event is within the time window
  //           const cutoffTime = new Date();
  //           cutoffTime.setMinutes(cutoffTime.getMinutes() - timeWindow);

  //           const eventTime = new Date(event.created_at);
  //           // Return false to filter out recent events
  //           if (eventTime >= cutoffTime) {
  //             return false;
  //           }
  //         }
  //       }
        
  //       // Add more condition types here as needed
  //     }
      
  //     // If all conditions pass, include the event
  //     return true;
  //   });
  // };

  // if (filterEvents(events).length === 0) {
  //   return null;
  // }

  return (
    <div
      // className={`my-4 bg-white rounded-md p-4 shadow-md ${chosenDateIdx !== null && currentIdx !== chosenDateIdx && 'hide'}`}
      className={`my-4 bg-white rounded-md p-4 shadow-md ${chosenDateIdx !== null && chosenDate !== date && 'hide'}`}
    >
      {contextHolder}
      <div className="flex items-center justify-between">
        <div className="flex">
          {chosenDateIdx !== null && (
            <span className="mr-4">
              <Button onClick={onViewMore} variant="plain">
                <div className="flex items-center">
                  <Icon source={ChevronLeftMinor} />
                  <span className="ml-1">Go Back to All Events</span>
                </div>
              </Button>
            </span>
          )}
          <h3 className="font-semibold">{date}</h3>
        </div>

        {doesDateHaveMore && doesDateHaveMore[date] && chosenDateIdx === null && (
          <Button onClick={onViewMore} variant="secondary">
            View More
          </Button>
        )}
      </div>
      <Spacer height={'10px'} />
      <Divider />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 pt-4">
        {(chosenDate !== null && Array.isArray(chosenDateEvents))
          ? chosenDateEvents?.map((event, idx) => {
            return <EventCard key={idx} event={event} currentIdx={currentIdx} date={date} setFromViewMore={setFromViewMore}/>;
          })
          : events?.map((event, idx) => {
            return <EventCard key={idx} event={event} currentIdx={currentIdx} date={date} setFromViewMore={setFromViewMore}/>;
          })}



      </div>
      {chosenDateIdx !== null && (
        <div className="flex justify-center bg-white rounded-md shadow-xl py-3">
          <Pagination
            onPrevious={() => {
              updatePagination(currentPage - 1);
            }}
            onNext={() => {
              updatePagination(currentPage + 1);
            }}
            hasNext={currentPage < pages}
            hasPrevious={currentPage > 1}
            label={`Page ${currentPage} of ${pages}`}
          />
        </div>
      )}
    </div>
  );
};

export default EventsPerDate;
