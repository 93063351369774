/* eslint-disable */
import { Badge, Button, DataTable, Icon } from '@shopify/polaris';
import TableHeading from '../../components/TableHeading';
import { Card, notification } from 'antd';
import { SettingsFilledMinor } from '@shopify/polaris-icons';
import { getCountryCodeAndTimezone } from '../../data/timezones';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { toggleUser } from '../../api/users.api';
import useAccessControl from '../../hooks/useAccessControl';
import { ACCESS } from '../../data/userRoles';

const constructRows = (data, showModal, userData, toggleUserStatus) => {

  //Access Control for User Management
  const { hasAccess: hasSettingsEditAccess, isLoading: isAccessLoading } = useAccessControl(ACCESS.SETTINGS_EDIT);

  if (!data) return [];
  const fetchUserBadge = (user) => {
    if (!user.status)
      return (
        <Badge key={user.id} tone="critical">
          Inactive
        </Badge>
      );
    if (user.is_verified) {
      return (
        <Badge key={user.id} tone="success">
          Active
        </Badge>
      );
    }
    return (
      <Badge key={user.id} tone="attention">
        Invited
      </Badge>
    );
  };
  const rows = data.map((user) => {
    return [
      user.name,
      user.username,
      user.email.toLowerCase(),
      user.phone,
      fetchUserBadge(user),
      <div key={`button-${user.id}`} className="flex">
        <span>
          <Button
            disabled={!isAccessLoading && !hasSettingsEditAccess}
            onClick={() => {
              showModal(user);
            }}
          >
            <Icon source={SettingsFilledMinor} />
          </Button>
        </span>
        <span className="ml-1">
          <Button
            variant="primary"
            tone={user.status ? 'critical' : ''}
            disabled={!isAccessLoading && !hasSettingsEditAccess}
            onClick={() => {
              toggleUserStatus({ id: user.id, status: !user.status });
            }}
          >
            {user.status ? 'Disable' : 'Enable'}
          </Button>
        </span>
      </div>
    ];
  });
  return rows ?? [];
};

const UserManagement = ({ data, setEditUserForm, setOpenEdit, error, refetchUsers }) => {
  const [api, contextHolder] = notification.useNotification();
  const { roles } = useSelector((state) => state.app);
  const { sitesAvailable } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.auth);

  const { mutate: toggleUserStatus } = useMutation({
    mutationFn: toggleUser,
    onSuccess: () => {
      refetchUsers();
    },
    onError: () => {
      api.error({ message: 'Unable to perform action' });
    }
  });

  if (error) {
    return (
      <Card>
        Something went wrong! Please try again later or contact{' '}
        <a
          className="text-blue-500 hover:text-blue-900 cursor-pointer underline underline-offset-2"
          href="mailto:support@visionify.ai"
        >
          support@visionify.ai
        </a>
      </Card>
    );
  }
  return (
    <Card sectioned>
      {contextHolder}
      <DataTable
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
        headings={[
          <TableHeading key={1} text="Name" />,
          <TableHeading key={2} text="Username" />,
          <TableHeading key={3} text="Email Address" />,
          <TableHeading key={4} text="Phone Number" />,
          <TableHeading key={5} text="Status" />,
          <TableHeading key={6} text={<span className="mx-6">Actions</span>} />
        ]}
        hasZebraStripingOnData
        rows={constructRows(
          data,
          (userInfo) => {
            console.log('Original user info:', userInfo); // Debug log
            
            // Convert areas string to array and handle 'All' case
            let formattedAreas;
            if (userInfo.areas) {
              const areasArray = userInfo.areas.split(',').map(area => area.trim());
              // If 'All' is in the array, only keep 'All'
              if (areasArray.includes('All')) {
                formattedAreas = ['All'];
              } else {
                formattedAreas = areasArray;
              }
            } else {
              formattedAreas = ['All']; // Default to 'All' if no areas
            }
            
            console.log('Formatted areas:', formattedAreas); // Debug log
            
            setEditUserForm({
              user_id: userInfo.id,
              name: userInfo.name,
              username: userInfo.username,
              timezone: getCountryCodeAndTimezone(userInfo.phone, userInfo.country_code, userInfo.timezone).timezone,
              email: userInfo.email,
              phone: getCountryCodeAndTimezone(userInfo.phone, userInfo.country_code, userInfo.timezone).localNumber,
              isVerified: userInfo.is_verified,
              role: roles.find((role) => role.id === userInfo.role_id).name.split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' '),
              site: sitesAvailable.find((site) => site.id === (userInfo.site_id || 1)).name,
              areas: formattedAreas // Use the formatted areas array
            });
            setOpenEdit(true);
          },
          user,
          toggleUserStatus
        )}
      />
    </Card>
  );
};

export default UserManagement;
