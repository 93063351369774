/* eslint-disable */
import React, { useMemo, useRef, useState, useEffect } from 'react';
import PolygonAnnotation from './PolygonAnnotation';
import { Stage, Layer, Image } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { setImageDimensionsForCamera, setScenarioFormData } from '../../store/slices/scenarioSlice';

const Canvas = ({
  camera,
  imageSrc,
  polygons,
  setPolygons,
  isPolygonCompleteArr,
  setIsPolygonCompleteArr,
  currentPolygonIdx,
  setDisableSubmit,
  forParent,
  setIsCanvasReady,
  readOnly = false
}) => {
  const randomColors = [
    'rgba(255, 87, 51, 0.5)',
    'rgba(51, 255, 87, 0.5)',
    'rgba(51, 87, 255, 0.5)',
    'rgba(255, 51, 161, 0.5)',
    'rgba(161, 51, 255, 0.5)',
    'rgba(51, 255, 245, 0.5)',
    'rgba(245, 255, 51, 0.5)',
    'rgba(255, 140, 51, 0.5)',
    'rgba(51, 255, 140, 0.5)',
    'rgba(140, 51, 255, 0.5)'
  ];

  const darkenColor = (color, amount) => {
    let usePound = false;

    if (color[0] === '#') {
      color = color.slice(1);
      usePound = true;
    }

    const num = parseInt(color, 16);
    let r = (num >> 16) - amount;
    let g = ((num >> 8) & 0x00ff) - amount;
    let b = (num & 0x0000ff) - amount;

    if (r < 0) r = 0;
    if (g < 0) g = 0;
    if (b < 0) b = 0;

    return (usePound ? '#' : '') + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
  };
  const [image, setImage] = useState();
  const imageRef = useRef(null);
  const [size, setSize] = useState({ width: 650, height: 500 });
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [stage, setStage] = useState();
  const lastKnownSizeRef = useRef(size);
  const dispatch = useDispatch();
  const { scenarioFormData: formData, scenarioPerCamFormData: formDataPerCam } = useSelector(
    (state) => state.scenario
  );
  const setCameraDimensions = ({ height, width }) => {
    dispatch(
      setImageDimensionsForCamera({
        [camera.name]: { height: Number(height).toFixed(2), width: Number(width).toFixed(2) }
      })
    );

    dispatch(
      setScenarioFormData({
        ...formData,
        imageDimensionsPerCamera: {
          ...formData.imageDimensionsPerCamera,
          [camera.name]: { height: Number(height).toFixed(2), width: Number(width).toFixed(2) }
        }
      })
    );
  };

  const adjustSizeBasedOnViewportOnLoad = (width, height) => {
    // Adjust size based on the viewport, maintaining aspect ratio
    let maxWidth, maxHeight;
    if (forParent) {
      const parentElement = document.querySelector('.canvas-parent');
      maxWidth = parentElement.clientWidth;
      maxHeight = parentElement.clientHeight;
      const aspectRatio = width / height;

      let newWidth = width;
      let newHeight = height;

      if (newWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      }
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }

      return { width: newWidth, height: newHeight };
    } else {
      maxWidth = window.innerWidth * 0.8; // 80% of viewport width
      maxHeight = window.innerHeight * 0.8; // 80% of viewport height

      const aspectRatio = width / height;

      let newWidth = width;
      let newHeight = height;

      if (width > maxWidth) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      }
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }

      return { width: newWidth, height: newHeight };
    }
  };

  const adjustSizeBasedOnViewport = (width, height) => {
    // Adjust size based on the viewport, maintaining aspect ratio
    let maxWidth, maxHeight;
    if (forParent) {
      const parentElement = document.querySelector('.canvas-parent');
      maxWidth = parentElement.clientWidth;
      maxHeight = parentElement.clientHeight;
      const aspectRatio = width / height;

      let newWidth = width;
      let newHeight = height;

      if (readOnly) {
        newHeight = maxWidth / aspectRatio;
        newWidth = maxHeight * aspectRatio;
      } else {
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
          newHeight = maxWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
          newHeight = maxHeight;
          newWidth = maxHeight * aspectRatio;
        }
      }

      return { width: newWidth, height: newHeight };
    } else {
      maxWidth = window.innerWidth * 0.8; // 80% of viewport width
      maxHeight = window.innerHeight * 0.8; // 80% of viewport height

      const aspectRatio = width / height;

      let newWidth = width;
      let newHeight = height;

      if (width > maxWidth) {
        newWidth = maxWidth;
        newHeight = maxWidth / aspectRatio;
      }
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }

      return { width: newWidth, height: newHeight };
    }
  };

  // For group drag cursor change when mouse enters the group
  const handleGroupMouseOver = (e, idx) => {
    if (!isPolygonCompleteArr[idx]) return;
    // e.target.getStage().container().style.cursor = 'move';
    setStage(e.target.getStage());
  };

  // for group drag cursor change when mouse leaves the group
  const handleGroupMouseOut = (e) => {
    e.target.getStage().container().style.cursor = 'default';
  };

  useEffect(() => {
    const videoElement = new window.Image();
    // videoElement.src = `data:image/jpeg;base64,${imageSrc}`;
    videoElement.src = `${imageSrc}`;

    const onload = () => {
      const adjustedSize = adjustSizeBasedOnViewportOnLoad(videoElement.width, videoElement.height);
      setSize(adjustedSize);
      setImage(videoElement);
      setCameraDimensions(adjustedSize);
      imageRef.current = videoElement;
      lastKnownSizeRef.current = adjustedSize;

      if (!formDataPerCam.imageDimensions || !(camera.name in formDataPerCam.imageDimensions))
        return;

      const originalWidth = formDataPerCam.imageDimensions[camera.name].width;
      const originalHeight = formDataPerCam.imageDimensions[camera.name].height;

      // Calculate scale factors based on the original dimensions
      const scaleX = adjustedSize.width / originalWidth;
      const scaleY = adjustedSize.height / originalHeight;

      // Scale the polygons
      const scaledPolygons = polygons.map((polygon) =>
        polygon.map(([x, y]) => [x * scaleX, y * scaleY])
      );

      // Update the polygons state with scaled coordinates
      // scale zones in redux for scaling readonly polygons
      // setPolygons(scaledPolygons);
    };

    videoElement.addEventListener('load', () => {onload(); setIsCanvasReady(true);});

    // Cleanup
    return () => videoElement.removeEventListener('load', onload);
  }, [imageSrc, forParent]);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current && lastKnownSizeRef.current) {
        const originalWidth = lastKnownSizeRef.current.width;
        const originalHeight = lastKnownSizeRef.current.height;

        // Adjust the size based on the viewport
        const adjustedSize = adjustSizeBasedOnViewport(
          imageRef.current.width,
          imageRef.current.height
        );
        setSize(adjustedSize);

        // Calculate scale factors based on the last known size
        const scaleX = adjustedSize.width / originalWidth;
        const scaleY = adjustedSize.height / originalHeight;

        // console.log('BEFORE SCALING', { scaleX, scaleY });

        // Scale the polygons
        const scaledPolygons = polygons.map((polygon) =>
          polygon.map(([x, y]) => [x * scaleX, y * scaleY])
        );

        // Update the polygons state with scaled coordinates
        setPolygons(scaledPolygons);

        // Update the last known size
        lastKnownSizeRef.current = adjustedSize;
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [polygons, forParent]);

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  //drawing begins when mousedown event fires.
  // start drawing
  const handleMouseDown = (e, idx) => {
    if (readOnly) return;
    if (isPolygonCompleteArr[idx]) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && polygons[idx].length >= 3) {
      setIsPolygonCompleteArr([
        ...isPolygonCompleteArr.slice(0, idx),
        true,
        ...isPolygonCompleteArr.slice(idx + 1)
      ]);
      setDisableSubmit(false);
    } else {
      setPolygons([
        ...polygons.slice(0, idx),
        [...polygons[idx], mousePos],
        ...polygons.slice(idx + 1)
      ]);
      setDisableSubmit(true);
    }
  };

  // TO ENABLE NEW POLYGON WITHOUT DRAWING
  // useEffect(() => {
  //   if(isPolygonCompleteArr[currentPolygonIdx]){
  //     setPolygons([...polygons, []]);
  //     setIsPolygonCompleteArr([...isPolygonCompleteArr, false]);
  //     setCurrentPolygonIdx(polygons.length);
  //     // setCurrentPolygonIdx((prev) => prev + 1);
  //   }

  // },[isPolygonCompleteArr, currentPolygonIdx])
  // to handle mouse move event (for drawing)
  const handleMouseMove = (e) => {
    if (readOnly) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };
  // to close polygon (to finish drawing)
  const handleMouseOverStartPoint = (e, idx) => {
    if (readOnly) return;
    if (isPolygonCompleteArr[idx] || polygons[idx].length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // to handle mouse out event (for start point)
  const handleMouseOutStartPoint = (e) => {
    if (readOnly) return;
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };

  // to handle point drag move event
  const handlePointDragMove = (e, idx) => {
    if (readOnly) return;
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPolygons([
      ...polygons.slice(0, idx),
      [...polygons[idx].slice(0, index), pos, ...polygons[idx].slice(index + 1)],
      ...polygons.slice(idx + 1)
    ]);
  };

  const flattenedPolygons = useMemo(() => {
    return polygons.map((polygon, idx) => {
      return polygon
        .concat(readOnly || isPolygonCompleteArr[idx] ? [] : position)
        .reduce((a, b) => a.concat(b), []);
    });
  }, [polygons, position, isPolygonCompleteArr, readOnly]);

  const handleGroupDragEnd = (e, idx) => {
    if (e.target.name() === 'polygon') {
      const newPolygons = [];
      for (let i = 0; i < polygons.length; i++) {
        if (i !== idx) {
          newPolygons.push(polygons[i]);
        } else {
          let newPoints = [];
          let oldPoints = [...polygons[i]];
          oldPoints.map((point) =>
            newPoints.push([point[0] + e.target.x(), point[1] + e.target.y()])
          );
          newPolygons.push(newPoints);
        }
      }
      setPolygons(newPolygons);
    }
  };

  return (
    <div className="flex flex-row gap-2 justify-center">
      <div>
        <Stage
          width={size.width || 650}
          height={size.height || 500}
          onMouseMove={handleMouseMove}
          onMouseDown={(e) => handleMouseDown(e, currentPolygonIdx)}
        >
          <Layer>
            <Image
              ref={imageRef}
              image={image}
              x={0}
              y={0}
              width={size.width}
              height={size.height}
            />

            {polygons.map((polygon, idx) => {
              return (
                <PolygonAnnotation
                  readOnly={readOnly}
                  key={idx}
                  points={polygons[idx]}
                  stage={stage}
                  color={randomColors[idx % randomColors.length]}
                  borderColor={darkenColor(randomColors[idx % randomColors.length], 20)}
                  handleGroupMouseOut={readOnly ? () => {} : handleGroupMouseOut}
                  handleGroupMouseOver={readOnly ? () => {} : (e) => handleGroupMouseOver(e, idx)}
                  flattenedPoints={flattenedPolygons[idx]}
                  handlePointDragMove={readOnly ? () => {} : (e) => handlePointDragMove(e, idx)}
                  handleGroupDragEnd={readOnly ? () => {} : (e) => handleGroupDragEnd(e, idx)}
                  handleMouseOverStartPoint={
                    readOnly ? () => {} : (e) => handleMouseOverStartPoint(e, idx)
                  }
                  handleMouseOutStartPoint={readOnly ? () => {} : handleMouseOutStartPoint}
                  isFinished={readOnly ? true : isPolygonCompleteArr[idx]}
                />
              );
            })}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default Canvas;
