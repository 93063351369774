import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Disable console in production build
if (process.env.NODE_ENV === 'production' || process.env.npm_lifecycle_event === 'prod-build') {
    const noop = () => {};
    ['log', 'warn', 'error', 'info', 'debug'].forEach((method) => {
        console[method] = noop;
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// Only run reportWebVitals in development
if (process.env.NODE_ENV !== 'production' && process.env.npm_lifecycle_event !== 'prod-build') {
    reportWebVitals(console.log)
}
