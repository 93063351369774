import { Button, Spinner } from '@shopify/polaris';
import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import SiteForm from './SiteForm';
import { useMutation } from '@tanstack/react-query';
import { updateSite } from '../../api/sites.api';

const EditSiteModal = ({
  isOpen,
  handleCancel = () => {},
  isPending = false,
  chosenSiteData,
  setIsOpen,
  siteCallback
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    addressLn1: '',
    addressLn2: '',
    state: '',
    zipcode: '',
    faceBlur: false,
    fullBodyBlur: false,
    stickFigure: false,
    webappNotifications: false,
    announcementNotifications: false,
    sitemapImage: null,
    webAppNotificationCategories: [],
    announcementNotificationCategories: [],
    shiftsEnabled: false,
    shifts: {
      1: {
        startTime: '',
        endTime: '',
        shiftName: 'Shift 1'
      }
    },
    cameraSeverityEnabled: false,
  });

  const onDrop = (files, rejections) => {
    setFormData({ ...formData, sitemapImage: files[0] });
    if (rejections.length) {
      console.warn(rejections);
    }
  };

  useEffect(() => {
    if (chosenSiteData) {
      console.log(chosenSiteData);
      setFormData({
        name: chosenSiteData.name,
        email: chosenSiteData.email,
        addressLn1: chosenSiteData.address_line_1,
        addressLn2: chosenSiteData.address_line_2,
        state: chosenSiteData.state,
        zipcode: chosenSiteData.zipcode,
        faceBlur: chosenSiteData.face_blur ? true : false,
        fullBodyBlur: chosenSiteData.full_body_blur ? true : false,
        stickFigure: chosenSiteData.stick_figure ? true : false,
        sitemapImage: chosenSiteData.sitemap_url,
        webappNotifications: (chosenSiteData.web_app_notifications && chosenSiteData.web_app_notifications.length > 0) ? true : false,
        announcementNotifications: (chosenSiteData.announcement_notifications && chosenSiteData.announcement_notifications.length > 0) ? true : false,
        webAppNotificationCategories: chosenSiteData.web_app_notifications,
        announcementNotificationCategories: chosenSiteData.announcement_notifications,
        shiftsEnabled: chosenSiteData.shifts && Object.keys(JSON.parse(chosenSiteData.shifts)).length > 0,
        shifts: chosenSiteData.shifts && Object.keys(JSON.parse(chosenSiteData.shifts)).length > 0 ? JSON.parse(chosenSiteData.shifts) : null,
        cameraSeverityEnabled: chosenSiteData.camera_specific_severity || false,
      });
    }
  }, [chosenSiteData]);

  const { mutate } = useMutation({
    mutationFn: updateSite,
    onSuccess: () => {
      setIsOpen(false);
      siteCallback.refetch();
      api.success({ message: 'Site updated!' });
    },
    onError: () => {
      api.error({ message: 'Something went wrong. Please try again later.' });
    }
  });

  const handleOk = () => {
    if (
      !formData.addressLn1 ||
      !formData.state ||
      !formData.zipcode ||
      !formData.name ||
      !formData.email ||
      !isEmailValid
    ) {
      return api.warning({
        message: 'Make sure to fill all required fields'
      });
    }

    // Add shift validation
    if (formData.shiftsEnabled) {
      const hasEmptyShiftFields = Object.values(formData.shifts).some(
        shift => !shift.shiftName || !shift.startTime || !shift.endTime
      );

      if (hasEmptyShiftFields) {
        return api.warning({
          message: 'Please fill in all shift details (name, start time, and end time)'
        });
      }

      // Optional: Validate time format and logic
      const hasInvalidTimes = Object.values(formData.shifts).some(shift => {
        const start = new Date(`2000/01/01 ${shift.startTime}`);
        const end = new Date(`2000/01/01 ${shift.endTime}`);
        return isNaN(start.getTime()) || isNaN(end.getTime());
      });

      if (hasInvalidTimes) {
        return api.warning({
          message: 'Please enter valid times for all shifts'
        });
      }
    }

    mutate({
      id: chosenSiteData.id,
      data: {
        ...formData,
        status: 1,
        address_line_1: formData.addressLn1,
        address_line_2: formData.addressLn2,
        face_blur: formData.faceBlur,
        full_body_blur: formData.fullBodyBlur,
        stick_figure: formData.stickFigure,
        webapp_notifications: formData.webappNotifications,
        announcement_notifications: formData.announcementNotifications,
        web_app_notification_categories: formData.webAppNotificationCategories,
        announcement_notification_categories: formData.announcementNotificationCategories,
        shifts: formData.shiftsEnabled ? JSON.stringify(formData.shifts) : "{}",
        camera_specific_severity: formData.cameraSeverityEnabled,
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Edit Site"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        centered
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel{' '}
            </Button>
          </div>,
          <div className="inline" key="submit">
            <Button variant="primary" onClick={handleOk}>
              <div className="flex items-center">
                Save
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        <form>
          <SiteForm
            formData={formData}
            setFormData={setFormData}
            isEmailValid={isEmailValid}
            setIsEmailValid={setIsEmailValid}
            onDrop={onDrop}
            modalId={'edit-site-modal'}
          />
        </form>
      </Modal>
    </>
  );
};

export default EditSiteModal;
